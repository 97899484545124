// import React from 'react'

// // Material
// import { Button, IconButton } from '@mui/material'
// import { PersonAddAlt as PersonAddAltIcon } from '@mui/icons-material'

// const AddButton = ({ children, icon = <PersonAddAltIcon sx={{color: 'white'}} /> , onClick }) => {
//   return (
//     <>
//       <IconButton
//         variant="contained"
//         size="large"
//         className="px-4 h-full rounded-full bg-orangepkt1 hover:bg-orange-500 lg:hidden"
//         onClick={onClick}
//       >
//         {icon}
//       </IconButton>

//       <Button
//         variant="contained"
//         size="large"
//         startIcon={icon}
//         className="h-full hidden rounded-full bg-orangepkt1 hover:bg-orange-500 lg:inline"
//         onClick={onClick}
//       >
//         {children}
//       </Button>
//     </>
//   )
// }

// export default AddButton

// Material
import { Button, Fab, useMediaQuery, useTheme } from '@mui/material'
import { PersonAddAlt as PersonAddAltIcon } from '@mui/icons-material'

const AddButton = ({ children, icon = <PersonAddAltIcon sx={{ color: 'white' }} />, onClick }) => {

  const theme = useTheme();
  const queryMatch = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {
        queryMatch
          ? <Button
            variant="contained"
            size="large"
            color="orangepkt1"
            className="h-full rounded-full"
            startIcon={icon}
            onClick={onClick}
          >
            {children}
          </Button>

          : <Fab
            size="medium"
            color="orangepkt1"
            className="px-7 h-full rounded-full"
            sx={{ boxShadow: 2 }}
            onClick={onClick}
          >
            {icon}
          </Fab>
      }
    </>
  )
}

export default AddButton
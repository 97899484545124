import { Add } from '@mui/icons-material';
import { Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { encodeBase64, fileToBase64 } from '../../../utils/base64.utils';

const ImageField = ({ id, value, buttonLabel = 'Cargar archivo', placeholder = 'Selecciona un archivo', icon = <Add />, onChange, error = false, onBlur, helperText = 'La carga del archivo es requerida' }) => {

  const inpFileRef = useRef(null)

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileBase64 = await fileToBase64(file);
    // console.log(fileBase64);

    const fileObj = {
      name: file.name,
      type: file.type,
      size: (file.size / 1024).toFixed(2),
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate.toLocaleString(),
      urlFile: fileBase64
    }

    onChange(id, fileObj);
  }

  const handleClickInput = () => {
    inpFileRef.current.click();
  }

  return (
    <>
      <Stack direction="row" >
        <Button
          color="bluepkt1"
          startIcon={icon}
          component="label"
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          ref={inpFileRef}
        >
          {buttonLabel}
          <input type="file" onChange={handleChangeFile} hidden />
        </Button>
        <TextField
          id={id}
          value={value ? `${value.name}, ${value.size}KB` : ''}
          size="small"
          fullWidth
          placeholder={placeholder}
          InputProps={{ sx: {color: 'grey.600', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, readOnly: true }}
          onClick={handleClickInput}
          error={error}
          onBlur={onBlur}
        />
      </Stack>
      {
        error && <Typography variant="caption" sx={{ pl: 2, pt: 0.5, color: 'error.main' }}>{helperText}</Typography>
      }
    </>
  )
}

export default ImageField
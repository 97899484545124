import api from './api';
import TokenService from "./token.service";
// import BDTest from './bd-test';

export const getSections = () => {

    return api.get("api/Seccion", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            pagina: 1,
            PaginaTam: 100
        }
    });
};

export const getSectionSearch = ({ sectionId, warehouseId }) => {

    return api.get("search", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            id_seccion: sectionId,
            id_almacen: warehouseId
        }
    });
};

export const getSectionById = (sectionId) => {

    return api.get("api/search/" + sectionId, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
};

export const postSection = (sectionData) => {

    const res = api.post('api/Seccion', sectionData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const postSectionMultiple = (sectionData) => {

    const res = api.post('api/Seccion/multiple', sectionData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putSection = (sectionData) => {

    // let sections = BDTest.getSections;

    // sections = sections.map(section => {
    //     if (section.id_seccion === sectionData.id_seccion && section.id_almacen === sectionData.id_almacen) {
    //         section = sectionData;
    //     };

    //     return section;
    // });

    // BDTest.setSections = [...sections];

    const res = api.put('api/Seccion/', sectionData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const deleteSection = (sectionData) => {
    const body = {
        id_almacen: sectionData.id_almacen,
        id_seccion: sectionData.id_seccion
    };

    const res = api.delete('api/Seccion/', {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        body
    });

    return res;

}
// React and Hooks
import { createContext, useEffect, useState } from "react";

// Services
import { getWarehouses, getWarehouseByName } from "@services/warehouse.service";

// Utils
import { getWarehousesColumns, getWarehousesRows } from "../utils/table.utils";
import { getCubbiesByWarehouse } from "../../../services/cubbie.service";

const WarehousesPageContext = createContext();

export const WarehousesPageProvider = ({children}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [showWarehouseForm, setShowWarehouseForm] = useState(false);
  const [showWarehouseFormArea, setShowWarehouseFormArea] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  

  const handleSearch = async (warehouse = '') => {
    setIsLoading(true);

    let warehouseResp;

    try {
      
      
      if (warehouse == '') {
        warehouseResp = await getWarehouses();
      } else {
        warehouseResp = await getWarehouseByName(warehouse);
      }

      warehouseResp = await Promise.all(
        warehouseResp.data.map( async (wareResp) => ({
          ...wareResp,
          cubbie_disponible: await  availablesCubbiesWarehouse(wareResp.id_almacen)
        }))
      )
      
      setWarehouses(warehouseResp);

    } catch (error) {
      setWarehouses([]);
    }

    setIsLoading(false);
    
  }

  const handleSelectedRow = (idRowWarehouse) => {
    
    const selectedWarehouseRow = warehouses.filter( warehouse => warehouse.id_almacen === idRowWarehouse )[0];
    
    setSelectedWarehouse(selectedWarehouseRow);
    
    if(!showWarehouseFormArea){
      setShowWarehouseFormArea(true);
    }

    setShowWarehouseForm(false);
    setTimeout(() => {
      setShowWarehouseForm(true);
    }, 100);
  }

  const handleClickNewWarehouse = () => {
    if(!showWarehouseFormArea){
      setShowWarehouseFormArea(true);
    }
    
    setShowWarehouseForm(false);
    setTimeout(() => {
      setShowWarehouseForm(true);
    }, 100);
  }

  const availablesCubbiesWarehouse = async (warehouseId) => {
    try {
      
      const cubbiesResp = await getCubbiesByWarehouse(warehouseId);
      
      if (cubbiesResp.data.length == 0) return false;
  
      return cubbiesResp.some(cubbResp => cubbResp.estatus == 'A');
  
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <WarehousesPageContext.Provider value={{
      isLoading,
      warehouses,
      handleSearch,
      getWarehousesColumns, getWarehousesRows,
      handleSelectedRow,
      showWarehouseForm, setShowWarehouseForm,
      showWarehouseFormArea, setShowWarehouseFormArea,
      selectedWarehouse, setSelectedWarehouse,
      handleClickNewWarehouse
    }}>
      {children}
    </WarehousesPageContext.Provider>
  )
}

export default WarehousesPageContext;
// Material
import { Grid, Typography } from '@mui/material';

import {
  Email as EmailIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
} from '@mui/icons-material';

// Components
import CustomAvatar from '@components/CustomAvatar'
import CustomArrowForwardIcon from '@components/CustomArrowForwardIcon';


export const getSellersColumns = (showSellerFormArea) => {
  // showSellerFormArea sirve para ocultar columnas si se esta mostrando el formulario
  const enabledCols = {
    display: showSellerFormArea ? { lg: 'none' } : { xxs: 'none', sm: 'revert' }
  }

  return [
    { id: 'id', style: { display: 'none' } },
    { id: 'name', label: 'Nombre' },
    { id: 'email', label: 'Correo', style: enabledCols },
    { id: 'status', label: 'Estado', style: { minWidth: 60, textAlign: 'center', ...enabledCols } },
    { id: 'actions', style: { textAlign: 'center' } },
  ];

}

export const getSellersRows = (sellers, onClick) => sellers.map(seller => {

  const { direccion: address } = seller;
  const fullName = `${seller.nombre} ${seller.apellidos}`;
  const fullAddress = `${address.ciudad}, ${address.estado}, ${address.pais}`;

  // Cada renglon de la tabla debe tener su campo id (el cual estará oculto al usuario)
  // para buscar por id y llenar el formulario (OBLIGATORIO)
  return {
    id: seller.id_vendedor,
    name: <Grid container columnSpacing={3} wrap="nowrap">

      <Grid item><CustomAvatar name={fullName} /></Grid>

      <Grid container item direction="column" justifyContent="center" >
        <Grid item>
          <Typography variant="subtitle1">{fullName}</Typography>
        </Grid>
        <Grid item >
          <Typography variant="body2" noWrap >{fullAddress}</Typography>
        </Grid>
      </Grid>

    </Grid>,
    email: <Grid container>
      <EmailIcon fontSize="small" />
      <Typography variant="body2" ml={0.5}>{seller.email}</Typography>
    </Grid>,
    status: seller.estatus == 'A'
      ? <CheckCircleOutlinedIcon color="success" />
      : <RemoveCircleOutlineOutlinedIcon color="error" />,
    actions: <CustomArrowForwardIcon />
  };
})
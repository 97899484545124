import * as Yup from 'yup';

export const getNewUserValidator = (validDate, onSubmit) => {
  return {
    initialValues: {
      firstName: '',
      lastName: '',
      birthDate: validDate,
      identification: '',
      street: '',
      postalCode: '',
      city: '',
      county: '',
      region: '',
      country: '',
      userName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      rol: 1,
      active: true
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('El nombre es obligatorio'),
      lastName: Yup.string()
        .required('Los apellidos del usuario son obligatorios'),
      birthDate: Yup.date(),
      identification: Yup.string().required('Selecciona un método de identificación'),
      street: Yup.string()
        .required('La calle es requerida'),
      postalCode: Yup.string()
        .required('El Código Postal es requerido'),
      city: Yup.string()
        .required('La Ciudad es requerido'),
      county: Yup.string()
        .required('El Municipio es requerido'),
      region: Yup.string()
        .required('El Estado es requerido'),
      country: Yup.string()
        .required('El País es requerido'),
      userName: Yup.string()
        .required('El Nombre de Usuario es obligatorio'),
      email: Yup.string()
        .email('El email no es válido')
        .required('Debe ingresar un correo electrónico'),
      password: Yup.string()
        .required('Por favor, introduzca su contraseña')
        .min(8, 'La contraseña debe ser de 8 caracteres')
        .max(8, 'El password debe ser de 8 caracteres')
        .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
        .matches(/[a-z]/, 'La contraseña debe tener al menos una minúscula')
        .matches(/[A-Z]/, 'La contraseña debe tener al menos una mayúscula')
        .matches(/[^\w]/, 'La contraseña debe tener al menos un símbolo'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        .required('Es obligatorio confirmar la contraseña'),
      rol: Yup.number().required('Selecciona un rol'),
      active: Yup.bool()
    }),
    onSubmit: onSubmit
  };
}

export const getEditUserValidator = (user, onSubmit) => {

  const { usuario_detalle: details } = user; 
  const { direccion: address } = details;
  
  return {
    initialValues: {
      firstName: details.nombre,
      lastName: details.apellidos,
      birthDate: new Date(details.fecha_nacimiento),
      identification: details.identificacion,
      street: address.calle,
      postalCode: address.cp,
      city: address.ciudad,
      county: address.municipio,
      region: address.estado,
      country: address.pais,
      userName: user.usuario,
      email: user.email,
      rol: user.id_rol,
      active: details.estatus == 'A' ? true : false
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('El nombre es obligatorio'),
      lastName: Yup.string()
        .required('Los apellidos del usuario son obligatorios'),
      birthDate: Yup.date(),
      identification: Yup.string().required('Selecciona un método de identificación'),
      street: Yup.string()
        .required('La calle es requerida'),
      postalCode: Yup.string()
        .required('El Código Postal es requerido'),
      city: Yup.string()
        .required('La Ciudad es requerido'),
      county: Yup.string()
        .required('El Municipio es requerido'),
      region: Yup.string()
        .required('El Estado es requerido'),
      country: Yup.string()
        .required('El País es requerido'),
      userName: Yup.string()
        .required('El Nombre de Usuario es obligatorio'),
      email: Yup.string()
        .email('El email no es válido')
        .required('Debe ingresar un correo electrónico'),
      rol: Yup.number().required('Selecciona un rol'),
      active: Yup.bool()
    }),
    onSubmit: onSubmit
  };
}


// React and Hooks
import React, { useContext } from 'react'
import PropTypes from 'prop-types';

// Material
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

// Charts
import { AreaChart, Area, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Context / Providers
import AnalyticContext from '../context/AnalyticContext';


const CardPackagesState = ({ title, chartColor="#009688", state = 1 }) => {

  const { getShipmentsByState } = useContext(AnalyticContext);

  const shipmentsByState = getShipmentsByState(state);

  const idGradient = title.toLowerCase().replace(" ", "-") + shipmentsByState.length;

  const totalShipments = shipmentsByState.map( ship => ship.envios ).reduce( (prev, curr) => prev + curr )

  return (
    <Card className="md:w-auto rounded-2xl shadow-xl">

      <CardContent>
        <div className="flex justify-between items-center">
          <Typography variant="h6">
            {title}
          </Typography>
          <Typography className="px-2 bg-gray-300 rounded-2xl font-xs">
            {shipmentsByState.length} días
          </Typography>
        </div>
        <Typography variant="h3">
          { totalShipments }
        </Typography>
      </CardContent>

      <CardMedia>
        <ResponsiveContainer height={100}>
          <AreaChart
            data={shipmentsByState}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id={idGradient} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartColor} stopOpacity={0} />
              </linearGradient>
            </defs>
            
            <CartesianGrid strokeDasharray="1" vertical={false} horizontal={false} />
            <Tooltip />
            <Area type="monotone" dataKey="envios" stroke={chartColor} strokeWidth="2" fill={`url(#${idGradient})`} />
          </AreaChart>
        </ResponsiveContainer>
      </CardMedia>

    </Card>
  )
}

CardPackagesState.propTypes = {
  title: PropTypes.string.isRequired,
  state: PropTypes.number,
  chartColor: PropTypes.string
}

export default CardPackagesState
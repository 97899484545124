import { TextField } from "@mui/material"

const NumberField = ({ label, value, onChange, isInteger = false, ...rest }) => {

  const adornments = {
    inputProps: { min: isInteger ? 1 : 0.5, step: isInteger ? 1 : 0.1 }
  }


  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      type="number"
      size="small"
      InputProps={adornments}
      {...rest}
    />
  )
}

export default NumberField;
// React and Hooks
import React, { useState } from 'react';
import { useFormik } from 'formik';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Typography,
  useTheme,
  Fade,
  Alert,
  Stack,
  CircularProgress,
  Collapse,
} from '@mui/material';

// Services
import { patchChangePassword, postVerifyPassword } from '@services/user.service';

// Utils
import SwalAlert from "@utils/alerts";

// Yup
import * as Yup from 'yup';


const ChangePassword = ({ open, onClose, user }) => {

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyed, setIsVerifyed] = useState(false);
  const [verifyPass, setVerifyPass] = useState('');
  const [showMsgError, setShowMsgError] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Por favor, introduzca su contraseña')
        .min(8, 'La contraseña debe ser de 8 caracteres')
        .max(8, 'El password debe ser de 8 caracteres')
        .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
        .matches(/[a-z]/, 'La contraseña debe tener al menos una minúscula')
        .matches(/[A-Z]/, 'La contraseña debe tener al menos una mayúscula')
        .matches(/[^\w]/, 'La contraseña debe tener al menos un símbolo'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        .required('Es obligatorio confirmar la contraseña'),
    }),
    onSubmit: async (values) => {

      setIsLoading(true);

      try {
        const newPassword = {
          usuario: user.usuario,
          contrasena_antigua: verifyPass,
          contrasena_nueva: values.password
        }

        await patchChangePassword(newPassword);

        handleModalClose();
        SwalAlert.success('Contraseña cambiada correctamente');

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);

    }
  })

  const handleClickVerify = async () => {
    setIsLoading(true);

    try {

      const passData = {
        usuario: user.usuario,
        contrasena: verifyPass
      }
      await postVerifyPassword(passData);

      setIsVerifyed(true);

    } catch (error) {
      setShowMsgError(true);

      setTimeout(() => {
        setShowMsgError(false);
      }, 4000);
    }

    setIsLoading(false);
  }

  const handleChangeVerifyPass = (e) => {
    setVerifyPass(e.target.value)
  }

  const handleModalClose = () => {
    onClose();
    setIsVerifyed(false);
    setVerifyPass('');
    formik.resetForm();
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 20 }
        }}
      >
        <DialogTitle id="alert-dialog-title" color={theme.palette.bluepkt1.main}>
          {"Cambiar contraseña"}
        </DialogTitle>

        <DialogContent sx={{ mt: 2, mx: 3 }}>

          {
            !isVerifyed
              ? <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}>

                <Typography variant="body1" color={theme.palette.bluepkt1.main}>Ingresa la contraseña actual</Typography>
                <TextField
                  id="password"
                  label="Contraseña actual"
                  variant="outlined"
                  type="password"
                  autoFocus={true}
                  fullWidth
                  onChange={handleChangeVerifyPass}
                />

                <Fade in={showMsgError} unmountOnExit><Alert variant="outlined" severity="error">Contraseña incorrecta</Alert></Fade>

                {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress color="orangepkt1" />
                </Box>}

                <Button onClick={handleClickVerify} variant="outlined" color="orangepkt1" sx={{ mt: 3 }}>Verificar</Button>
              </Box>
              : <Fade in={isVerifyed}>
                <form onSubmit={formik.handleSubmit} >
                  <Stack spacing={3}>
                    <Typography variant="body1" color={theme.palette.bluepkt1.main}>Ingresa la nueva contraseña</Typography>
                    <TextField
                      id="password"
                      label="Contraseña"
                      placeholder="Pass-123"
                      variant="outlined"
                      type="password"
                      fullWidth
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.password && formik.errors.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                    />

                    <TextField
                      id="passwordConfirm"
                      label="Confirmar contraseña"
                      variant="outlined"
                      type="password"
                      fullWidth
                      value={formik.values.passwordConfirm}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                      error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                    />
                    {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress color="bluepkt1" />
                    </Box>}
                    <Button type="submit" variant="outlined" color="bluepkt1" pt={2}>Cambiar contraseña</Button>
                  </Stack>
                </form>
              </Fade>
          }

        </DialogContent>

        <DialogActions>
          <Button onClick={handleModalClose} color="error">Calcelar</Button>
        </DialogActions>
      </Dialog>
    </>

  );
}

export default ChangePassword;
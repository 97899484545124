import { createContext, useEffect, useState } from "react";
import Alert from "../../utils/alerts";
import { getUsers, getUserByName } from "../../services/user.service";

const TestsContext = createContext();

export const TestsProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [openAssignCubbiesModal, setOpenAssignCubbiesModal] = useState(true);

  const handleOpenAssignCubbiesModal = () => setOpenAssignCubbiesModal(true);
  const handleCloseAssignCubbiesModal = () => setOpenAssignCubbiesModal(false);

  // Para la barra de busqueda
  const handleSearch = async (user) => {

    let userResp;

    try {
      if (user == '') {
        userResp = await getUsers();
        userResp = userResp.data;
      } else {
        userResp = await getUserByName(user);

        //Lo convierto a array de objetos para que tenga el mismo trato
        userResp = [userResp.data];
      }

      setUsers(userResp);

    } catch (error) {
      console.log(error.response.data)
    }

  }

  // Se usó para pruebas en un botón
  const getUserByNameTest = async (userName) => {

    setIsLoading(true);

    try {
      const user = await getUserByName('jorge');
      const resp = await Alert.save();

      if (!resp) return;

      Alert.success('Usuario guardado');
      console.log(resp);
      setUsers(user.data);

    } catch (error) {
      console.log('Error' + error);

      Alert.error(`Usuario no encontrado`);
    }

    setIsLoading(true);

  };

  // useEffect(() => {
  //   handleSearch('');
  // }, []);

  return (
    <TestsContext.Provider value={{
      handleSearch,
      users,
      openAssignCubbiesModal,
      handleCloseAssignCubbiesModal,
      handleOpenAssignCubbiesModal
    }}>
      {children}
    </TestsContext.Provider>
  )
}

export default TestsContext;
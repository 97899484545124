import * as Yup from 'yup';

export const getNewProductValidator = (onSubmit) => {
  const FILE_SIZE = 5 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
  ];

  return {
    initialValues: {
      productName: '',
      description: '',
      length: 5,
      width: 5,
      height: 5,
      weight: 2,
      quantity: 1,
      sku: '',
      minStock: 5,
      image: undefined,
    },
    validationSchema: Yup.object({
      productName: Yup.string().required('El nombre del producto es obligatorio'),
      description: Yup.string().required('La descripción es obligatoria'),
      length: Yup.number(),
      width: Yup.number(),
      height: Yup.number(),
      weight: Yup.number(),
      quantity: Yup.number(),
      sku: Yup.string(),
      minStock: Yup.number()
        .min(5, 'El stock mínimo debe ser mayor a cinco'),
      image: Yup.mixed()
        .required('La imagen es obligatoria').
        test('fileFormat', 'Solo se permiten imagenes.',
          value => value && SUPPORTED_FORMATS.includes(value.type))
        .test('fileSize', 'El tamaño máximo de la imagen debe ser de 5MB',
          value => value && value.size <= FILE_SIZE)

    }),
    onSubmit: onSubmit
  };
}


// React and Hooks
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Stack, TextField, Slide, Collapse, Grow, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useContext, useState } from "react";
import ProgressIndicator from "../../../components/ProgressIndicator";
import { postSection, postSectionMultiple, putSection } from "../../../services/section.service";
import SwalAlert from "../../../utils/alerts";
import { getSectionsArea } from "../utils/dimensions.utils";
import CubbiesManagmentContext from "../context/CubbiesManagmentContext";
import CustomDimensionField from "./CustomDimensionField";

const NewSection = ({ open, section = {} }) => {

  const isNewSection = Object.entries(section).length == 0;

  const [currentSection, setCurrentSection] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const {
    warehouse,
    setOpenNewSectionModal,
    sections,
    aisles,
    dimensions,
    setSnackbarProps,
    initGetSections
  } = useContext(CubbiesManagmentContext);

  const handleClose = () => {
    setInitialValues();
    setOpenNewSectionModal(false);
  }

  const handleSave = async () => {

    setIsLoading(true);

    try {

      const wareArea = (dimensions.length - aisles) * (dimensions.width - aisles); // Le resto 2 pasillos
      const currSectionArea = (currentSection.length + aisles) * (currentSection.width + aisles); // Tomo en cuenta un pasillo de a lado y de abajo
      let allSectionsAreas = getSectionsArea(sections, aisles);

      if (!isNewSection) {

        const sectionsWithUpdate = sections.filter(sect => sect.id_seccion != section.id_seccion);
        allSectionsAreas = getSectionsArea(sectionsWithUpdate, aisles);

      }

      if ((currSectionArea + allSectionsAreas) > wareArea) throw 'No hay espacio suficiente en el almacén para esta sección';

      if (!isNewSection) {


        const sectionData = {
          id_seccion: section.id_seccion,
          id_almacen: warehouse.id_almacen,
          largo: currentSection.length,
          ancho: currentSection.width,
          alto: currentSection.height,
          descripcion: currentSection.description,
          volumen: currSectionArea * currentSection.height
        }

        await putSection(sectionData);

      } else {

        // Crea una copia de currentSection sin quantity y description
        const { quantity, description } = currentSection;

        let generatedSections = [];

        // Se genera un arreglo de secciones en base a la cantidad del input
        for (let i = 1; i <= quantity; i++) {

          let sectionData = {
            id_almacen: warehouse.id_almacen,
            largo: currentSection.length,
            ancho: currentSection.width,
            alto: currentSection.height,
          }

          sectionData.descripcion = description.length == 0
            ? `Sección ${sections.length + i}`
            : description

          generatedSections.push({ ...sectionData });
        }

        // Aquí va el post de Cubbies
        generatedSections.length == 1
          ? await postSection(generatedSections[0])
          : await postSectionMultiple(generatedSections);
      }

      setIsLoading(false);
      handleClose();

      setSnackbarProps({ open: true, message: `Sección ${isNewSection ? 'agregada' : 'modificada'} correctamente`, type: 'success' });
      initGetSections(); // Se hace un get de Secciones a la api para actualizar la lista

    } catch (error) {
      setIsLoading(false);
      SwalAlert.errorList(error);
    }

  }

  const handleChangeSection = (e) => {
    const id = e.target.id;
    let value = e.target.type == 'text' ? e.target.value : Number(e.target.value);

    setCurrentSection({
      ...currentSection,
      [id]: value
    })
  }

  const setInitialValues = () => {

    const initValues = {
      description: isNewSection ? '' : section.descripcion,
      quantity: 1,
      length: isNewSection ? 1 : section.largo,
      height: dimensions.height,
      width: isNewSection ? 1 : section.ancho
    };

    setCurrentSection(initValues);
  }

  // Actualizar los valores iniciales en cada re-render porque se mantiene el estado
  useEffect(() => {
    setInitialValues();
  }, [section])

  const quantityAdornments = { inputProps: { min: 1, max: 20 } };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <DialogTitle fontSize={18} textAlign="center">
        {isNewSection ? 'Nueva sección' : 'Editar sección'}
      </DialogTitle>

      <DialogContent sx={{ mt: 2, mx: 3, px: 0 }}>
        <Stack spacing={2} pt={1}>

          <TextField
            id="description"
            label="Descripción"
            size="small"
            value={currentSection.description}
            onChange={handleChangeSection}
          />

          {
            isNewSection && <CustomDimensionField
              id="quantity"
              label="Cantidad"
              adornments={quantityAdornments}
              value={currentSection.quantity}
              onChange={handleChangeSection}
            />
          }

          <CustomDimensionField
            id="length"
            label="Largo"
            value={currentSection.length}
            onChange={handleChangeSection}
          />

          <CustomDimensionField
            id="width"
            label="Ancho"
            value={currentSection.width}
            onChange={handleChangeSection}
          />

          <CustomDimensionField
            id="height"
            label="Alto"
            value={dimensions.height}
            disabled
          />
          <Typography variant="body2" color="warning.main" >Las dimensiones de la sección las limita el almacén</Typography>
        </Stack>

      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="error" onClick={handleClose}>Calcelar</Button>
        <Button color="success" onClick={handleSave}>Aceptar</Button>
      </DialogActions>

      <ProgressIndicator open={isLoading} />

    </Dialog>
  );
}

export default NewSection;
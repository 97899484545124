export const WarehouseInterpret = (warehouses) => warehouses.map( ware => ({
  warehouseId: ware.id_almacen,
  uniqueKey: ware.id_almacen,
  name: ware.nombre_almacen,
  phone: ware.telefonos,
  length: ware.largo,
  width: ware.ancho,
  height: ware.alto,
  status: ware.estatus,
  address: {
    postalCode: ware.direccion.cp,
    street: ware.direccion.calle,
    city: ware.direccion.ciudad,
    municipality: ware.direccion.municipio,
    state: ware.direccion.estado,
    country: ware.direccion.pais,
    type: ware.direccion.tipo,
  },
  sections: ware.seccion ?? {}
}))
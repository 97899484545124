// React and Hooks
import React, { useContext } from 'react';

// Material
import { Box, Grid, Stack, Typography } from '@mui/material';


// Providers / Context
import ReceiptsPageContext, { ReceiptsPageProvider } from './context/ReceiptsContext';
import ProgressIndicator from '../../components/ProgressIndicator';
import Layout from '../../components/Layout';
import SearchBar from '../../components/SearchBar';
import TableData from '../../components/TableData';
import { grey } from '@mui/material/colors';
import { getReceiptsColumns, getReceiptsRows } from './utils/table-receipts.utils';
import AddButton from '../../components/AddButton';
import AssignNewReceiptDialog from './components/AssignNewReceiptDialog';
import NewProductDialog from './components/NewProductDialog';

// const ReceiptsPage = () => {
//   return (
//     <ReceiptsPageProvider>
//       <ReceiptsPageWithContext />
//     </ReceiptsPageProvider>
//   );
// }

const ReceiptsPage = () => {

  const {
    isLoading, setIsLoading,
    receipts, handleSearchReceipts,

    handleToggleNewReceipt,
  } = useContext(ReceiptsPageContext);

  return (
    <>
      <Layout>
        <Box sx={{ px: { xxs: 2, lg: 4 }, overflowY: 'hidden' }}>

          <Grid container rowSpacing={3} >

            <Grid container item xxs={12}>
              <Grid item>
                <Typography variant="h4" color={grey[900]} sx={{ mb: { xxs: 1, lg: 3 } }}>Entradas</Typography>
              </Grid>

              <Grid container item columnSpacing={2} justifyContent="space-between" wrap="nowrap">
                <Grid item xxs={10} lg={9}>
                  <SearchBar id="seller-searchbar" onSearch={handleSearchReceipts} />
                </Grid>

                <Grid item >
                  <AddButton onClick={handleToggleNewReceipt}>Nueva entrada</AddButton>
                </Grid>
              </Grid>

            </Grid>

            <Grid item xxs={12}>
              <TableData columns={getReceiptsColumns()} rows={getReceiptsRows(receipts)} onSelectedRow={() => { }} />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <AssignNewReceiptDialog />
      <ProgressIndicator open={isLoading} />
    </>
  );
}

export default ReceiptsPage;
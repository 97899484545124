import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReceiptsPageContext from '../context/ReceiptsContext'

const CancelConfirmDialog = () => {

  const navigate = useNavigate();

  const { openCancel, handleToggleCancel, handleResetFields } = useContext(ReceiptsPageContext);

  const handleClickConfirm = () => {
    handleToggleCancel();
    handleResetFields();
    navigate('/receipts');
  }

  return (
    <Dialog
      open={openCancel}
      onClose={handleToggleCancel}
      maxWidth="xs"
    >
      <DialogTitle sx={{ pt: 3, fontSize: { xxs: 18, sm: 20 } }}>¿Esta seguro que desea cancelar?</DialogTitle>
      
      <DialogContent>
        <Typography>No se guardarán los cambios efectuados.</Typography>
      </DialogContent>

      
      <DialogActions sx={{ pb: 2, justifyContent: 'center' }}>
        <Button
          color="success"
          onClick={handleClickConfirm}
        >
          Confirmar
        </Button>

        <Button
          color="customGrey"
          onClick={handleToggleCancel}
        >
          Cancelar
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default CancelConfirmDialog
// React and Hooks
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WarehouseInterpret } from "../../../models/warehouses.model";
import { getAssignCubbieBySellerId } from "../../../services/assigncubbie.service";
import { getReceipts, getReceiptSearch } from "../../../services/receipts.service";
import { getSellers } from "../../../services/seller.service";
import { getWarehouseByName, getWarehouses } from "../../../services/warehouse.service";
import SwalAlert from "../../../utils/alerts";
import { generateId, getUniqueObjectsFromArr, isEmpty } from "../../../utils/utils";
import { getProductsSeller } from "../../../services/inventory.service";

const ReceiptsPageContext = createContext();

export const ReceiptsPageProvider = ({ children }) => {

  // const productListTest = [
  //   {
  //     id: generateId(),
  //     isProduct: false,
  //     title: 'Información del Cubbie',
  //     warehouse: 'Pedro',
  //     section: 'Seccion',
  //     cubbie: 'CB-2',
  //     available: '20m / 60m',
  //     color: 'info'
  //   },
  //   {
  //     id: generateId(),
  //     isProduct: true,
  //     selectValues: [
  //       'Cartera de plastico',
  //       'Laptop'
  //     ],
  //     productName: 'Cartera de plastico',
  //     description: "Pues una cartera wey jaja",
  //     length: 5,
  //     width: 5,
  //     height: 5,
  //     weight: 2,
  //     quantity: 1,
  //     sku: "151654651654321",
  //     minStock: 5,

  //   },
  //   {
  //     id: generateId(),
  //     isProduct: true,
  //     selectValues: [
  //       'Mouse',
  //       'Teclado'
  //     ],
  //     productName: 'Mouse',
  //     description: "Esto es un mouse jeje",
  //     length: 5,
  //     width: 5,
  //     height: 5,
  //     weight: 2,
  //     quantity: 1,
  //     sku: "151654651654321",
  //     minStock: 5,

  //   },

  // ]

  const productListTest = [
    {
      id: generateId(),
      isProduct: false,
      title: 'Información del Cubbie',
      warehouse: {id: 231, name: 'Almacén Test'},
      section: {id: 531, name: 'Seccion K'},
      cubbie: {id: 785, name: 'Cubbie Pepe'},
      available: '20m / 60m',
      color: 'info',
      products: [
        {
          id: 1,
          isProduct: true,
          selectValues: [
            'Cartera de plastico',
            'Laptop'
          ],
          productName: 'Cartera de plastico',
          description: "Pues una cartera wey jaja",
          length: 5,
          width: 5,
          height: 5,
          weight: 2,
          quantity: 1,
          sku: "151654651654321",
          minStock: 5,

        },
        {
          id: 2,
          isProduct: true,
          selectValues: [
            'Cartera de plastico',
            'Laptop'
          ],
          productName: 'Cartera de plastico',
          description: "Pues una cartera wey jaja",
          length: 5,
          width: 5,
          height: 5,
          weight: 2,
          quantity: 1,
          sku: "151654651654321",
          minStock: 5,

        },
        {
          id: 3,
          isProduct: true,
          selectValues: [
            'Cartera de plastico',
            'Laptop'
          ],
          productName: 'Cartera de plastico',
          description: "Pues una cartera wey jaja",
          length: 5,
          width: 5,
          height: 5,
          weight: 2,
          quantity: 1,
          sku: "151654651654321",
          minStock: 5,

        },
      ]
    },
  ]

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isAutoAsign, setIsAutoAsign] = useState(false);


  const [receipts, setReceipts] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [warehouses, setWarehouses] = useState([]);
  const [allWarehouses, setAllWarehouses] = useState([]);
  const [sections, setSections] = useState([]);
  const [cubbies, setCubbies] = useState([]);
  const [productsCubbie, setProductsCubbie] = useState([]);
  const [productsSeller, setProductsSeller] = useState([]);
  const [productList, setProductList] = useState(productListTest);
  const [sellerCubbiesProcessed, setSellerCubbiesProcessed] = useState({});

  const [availableCubbies, setAvailableCubbies] = useState([]);
  const [lastCubbies, setLastCubbies] = useState([]);

  const [openNewReceipt, setOpenNewReceipt] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  const [indexTab, setIndexTab] = useState(0);

  const [selectedSeller, setSelectedSeller] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedCubbie, setSelectedCubbie] = useState(null);

  // Services
  const handleSearchReceipts = async (receiptSearch = '') => {
    setIsLoading(true);
    try {

      let receiptsResp = await getReceiptSearch(receiptSearch == '' ? 0 : receiptSearch);

      // console.log(receiptsResp);
      setReceipts(receiptsResp);

    } catch (error) {
      setReceipts([]);
    }

    setIsLoading(false);

  }

  const getCubbiesBySellerId = async (sellerId) => {

    if (!sellerId) return;

    try {
      const receiptsResp = await getAssignCubbieBySellerId(sellerId);

      if (receiptsResp.length == 0) {
        setWarehouses(allWarehouses);
        setSections([]);
        setCubbies([]);
        setShowInputs(false);

        setSelectedWarehouse(allWarehouses[0]);
        return;
      }

      setAvailableCubbies(receiptsResp);
      processCubbiesToDialog(receiptsResp);

      setShowInputs(true);

    } catch (error) {

    }

  }

  const getSellersQuery = async () => {

    try {

      const sellersResp = await getSellers();

      setSellers(sellersResp.data);

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const getAllWarehouses = async () => {
    try {

      const warehouseResp = await getWarehouses();

      if (warehouseResp.data.length == 0) return setAllWarehouses([]);

      setAllWarehouses(WarehouseInterpret(warehouseResp.data));

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const getProductsSellerQuery = async () => {
    try {

      const productsResp = await getProductsSeller(selectedSeller.id_vendedor, selectedWarehouse.warehouseId);

      if (isEmpty(productsResp)) return setProductsSeller([]);

      setProductsSeller(productsResp);

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const getProductsCubbieQuery = async () => {
    try {

      const warehouseResp = await getWarehouses();

      if (warehouseResp.data.length == 0) return setAllWarehouses([]);

      setAllWarehouses(WarehouseInterpret(warehouseResp.data));

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const updateSellerSections = () => {
    if (isEmpty(selectedWarehouse) || isEmpty(sellerCubbiesProcessed)) return;

    const sellSections = sellerCubbiesProcessed.sections.filter(sCubb => sCubb.warehouseId === selectedWarehouse.warehouseId);

    setSections(sellSections);
    setSelectedSection(sellSections[0]);
  }

  const updateSellerCubbies = () => {
    if (isEmpty(selectedWarehouse) || isEmpty(selectedSection) || isEmpty(sellerCubbiesProcessed)) return;

    const sellCubbies = sellerCubbiesProcessed.cubbies.filter(sCubb => sCubb.sectionId === selectedSection.sectionId && sCubb.warehouseId === selectedWarehouse.warehouseId);
    // console.log(sellCubbies);
    setCubbies(sellCubbies);
    setSelectedCubbie(sellCubbies[0]);

  }



  // Input Change Event
  const handleChangeSelectedSeller = (e) => {
    const seller = sellers.find(sell => sell.id_vendedor === e.target.value)
    setSelectedSeller(seller);
  };

  const handleChangeSelectedWarehouse = (e) => {
    const ware = warehouses.find(ware => ware.warehouseId === e.target.value)
    setSelectedWarehouse(ware)
  };

  const handleChangeSelectedSection = (e) => {
    const section = sections.find(sect => sect.sectionId === e.target.value);
    setSelectedSection(section);
  };

  const handleChangeSelectedCubbie = (e) => {
    const cubbie = cubbies.find(cubb => cubb.cubbieId === e.target.value);
    setSelectedCubbie(cubbie);
  };

  const handleResetFields = () => {
    setSelectedSeller(null);
    setSelectedWarehouse(null);
    setSelectedSection(null);
    setSelectedCubbie(null);
    setProductList([]);

  }

  const handleClickContinue = () => {

    setIsAutoAsign(false);
    // addCubbieInfo()
    goToNewReceipt();
  }

  const handleClickAssignAuto = () => {
    // TODO: consultar el primer cubbie disponible al servicio "Cubbie/primerCubbieDisponible"
    setIsAutoAsign(true);
    goToNewReceipt();
  }

  // Handle Toggles Dialogs
  const handleToggleNewReceipt = () => setOpenNewReceipt(!openNewReceipt);
  const handleToggleNewProduct = () => setOpenNewProduct(!openNewProduct);
  const handleToggleCancel = () => setOpenCancel(!openCancel);
  const handleToggleShowInputs = () => setShowInputs(!showInputs);

  // Getter y Setter (ProductList)
  const getProductById = (id) => productList.map( pList => pList.products.find(prod => prod.id === id) )[0];

  const updateProduct = (id, body) => {
    console.log(id);

    const updatedProductList = productList.map(product => {

      product.products = product.products.map(prod => {
        
        if (prod.id === id) {
          return {
            ...prod,
            ...body,
            id
          }
        }
        
        return prod;
      })

      return product

    })

    setProductList(updatedProductList);
  }

  // Manejo de la lista de productos agregados
  const addCubbieInfo = () => {

    const findCubbie = availableCubbies.find(aCubb => aCubb.cubbie.uniqueKey === selectedCubbie.uniqueKey);

    const ware = findCubbie.warehouse;
    const sect = findCubbie.section;
    const cubb = findCubbie.cubbie;

    let cubbInfo = {
      id: findCubbie.cubbie.uniqueKey,
      isProduct: false,
      title: 'Información del Cubbie',
      warehouse: { id: ware.id, name: ware.name },
      section: { id: sect.id, name: sect.description },
      cubbie: { id: cubb.id, name: cubb.description },
      available: `${cubb.availableVolume} m³ / ${cubb.totalVolume} m³`, //'20 m³ / 60 m³',
      color: 'info'
    };


    // if (isEmpty(availableCubbies)) {
    //   try {
    //     // get cubbie disponible
    //     // el cubbie traido por el servicio lo voy a asignar al selectedCubbie y agregar a
    //     // la lista availablesCubbies

    //   } catch (error) {
    //     console.log(error);
    //   }
    // }


    setProductList([...productList, cubbInfo]);

  }

  const addProduct = (product) => {

  }

  // Otros
  const goToNewReceipt = () => {
    handleToggleNewReceipt();
    navigate('/receipts/new');
  }

  const processCubbiesToDialog = (cubbiesList) => {
    const cubbSellerObj = {
      warehouses: getUniqueObjectsFromArr(cubbiesList.map(rec => rec.warehouse)),
      sections: getUniqueObjectsFromArr(cubbiesList.map(rec => rec.section)),
      cubbies: cubbiesList.map(rec => rec.cubbie)
    }

    setSellerCubbiesProcessed(cubbSellerObj);

    setWarehouses(cubbSellerObj.warehouses);
    setSelectedWarehouse(cubbSellerObj.warehouses[0]);
  }

  useEffect(() => {
    handleSearchReceipts('');
    getSellersQuery();
  }, []);

  useEffect(() => {
    getCubbiesBySellerId(selectedSeller?.id_vendedor);
  }, [selectedSeller])

  useEffect(() => {
    updateSellerSections()
  }, [selectedWarehouse])

  useEffect(() => {
    updateSellerCubbies()
  }, [selectedSection])


  return (
    <ReceiptsPageContext.Provider value={{
      isLoading, setIsLoading,
      receipts, handleSearchReceipts,
      openNewReceipt, handleToggleNewReceipt,
      openCancel, handleToggleCancel,
      openNewProduct, handleToggleNewProduct,
      showInputs, handleToggleShowInputs, setShowInputs,

      selectedSeller, handleChangeSelectedSeller,
      selectedWarehouse, handleChangeSelectedWarehouse,
      selectedSection, handleChangeSection: handleChangeSelectedSection,
      selectedCubbie, handleChangeSelectedCubbie,
      handleResetFields,
      isAutoAsign,
      handleClickContinue,
      handleClickAssignAuto,

      indexTab, setIndexTab,

      allWarehouses, getAllWarehouses,
      sellerCubbiesProcessed,
      availableCubbies,
      sellers, warehouses, sections, cubbies,
      productsCubbie, productsSeller,
      productList, setProductList,
      getProductById, updateProduct,
      getProductsSellerQuery, getProductsCubbieQuery
    }}>
      {children}
    </ReceiptsPageContext.Provider>
  )
}

export default ReceiptsPageContext;
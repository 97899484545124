import { SvgIcon } from '@mui/material';
import React from 'react'

const CustomWarehouseIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.75 14.25H4.2625C4.125 14.25 4.0125 14.3906 4.0125 14.5625L4.00938 16.4375C4.00938 16.6094 4.12188 16.75 4.25938 16.75H15.75C15.8875 16.75 16 16.6094 16 16.4375V14.5625C16 14.3906 15.8875 14.25 15.75 14.25ZM15.75 18H4.25313C4.11563 18 4.00313 18.1406 4.00313 18.3125L4 20.1875C4 20.3594 4.1125 20.5 4.25 20.5H15.75C15.8875 20.5 16 20.3594 16 20.1875V18.3125C16 18.1406 15.8875 18 15.75 18ZM15.75 10.5H4.26875C4.13125 10.5 4.01875 10.6406 4.01875 10.8125L4.01562 12.6875C4.01562 12.8594 4.12813 13 4.26562 13H15.75C15.8875 13 16 12.8594 16 12.6875V10.8125C16 10.6406 15.8875 10.5 15.75 10.5ZM19.0781 5.07031L10.575 0.644534C10.3922 0.54976 10.1963 0.500977 9.99844 0.500977C9.80057 0.500977 9.60464 0.54976 9.42188 0.644534L0.921875 5.07031C0.365625 5.36328 0 6.04297 0 6.80078V20.1875C0 20.3594 0.1125 20.5 0.25 20.5H2.75C2.8875 20.5 3 20.3594 3 20.1875V10.5C3 9.8125 3.45625 9.25 4.01875 9.25H15.9812C16.5438 9.25 17 9.8125 17 10.5V20.1875C17 20.3594 17.1125 20.5 17.25 20.5H19.75C19.8875 20.5 20 20.3594 20 20.1875V6.80078C20 6.04297 19.6344 5.36328 19.0781 5.07031Z" fill="white"/>
    </SvgIcon>
  )
}

export default CustomWarehouseIcon;

export const sidebarHeaderStyle = (isOpen) => ({
  pt: 2,
  pr: isOpen && 2,
  display: 'flex',
  justifyContent: isOpen ? 'right' : 'center',
})

export const drawerStyle = (isOpen) => ({
  position: "relative",
  "& .MuiBackdrop-root": {
    display: "none"
  },
  '.MuiDrawer-paper': {
    position: 'relative',
    width: isOpen ? 250 : 60,
    overflowX: 'hidden',
    transition: 'width 100ms ease-in-out'
  }
})

export const listItemButtonStyle = {
  py: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
  '&:hover': {
    bgcolor: '#ffffff18'
  }
}

export const listItemIconStyle = {
  color: 'common.white',
  justifyContent: 'center',
}

export const subheaderTextStyle = {
  fontWeight: 'bold',
  fontSize: 14,
  textTransform: 'uppercase'
}

export const subheaderStyle = (isOpen) => ({
  px: isOpen && 0,
  bgcolor: 'transparent',
  color: 'orangepkt1.main',
})

export const avatarStyle = (isOpen) => ({
  width: isOpen ? 160 : 50,
  height: isOpen ? 160 : 50,
  mb: 3
})
// React and Hooks
import React, { forwardRef, useContext } from 'react';

import {
  Button,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
// Icons
import { Close as CloseIcon } from "@mui/icons-material";

// Components
import ProgressIndicator from '@components/ProgressIndicator';
import CustomSnackbar from "@components/CustomSnackbar";
import SettingsWarehouseSidebar from './SettingsWarehouseSidebar';

// Context
import CubbiesManagmentContext, { CubbiesManagmentProvider } from '../context/CubbiesManagmentContext';
import WarehouseGrid from './WarehouseGrid';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CubbiesManagment = (props) => {
  return (
    <CubbiesManagmentProvider {...props}>
      <CubbiesManagmentWithContext />
    </CubbiesManagmentProvider>
  )
}

const CubbiesManagmentWithContext = () => {

  const {
    isLoading,
    open, handleClose,
    aisles,
    snackbarProps, handleCloseSnackBar
  } = useContext(CubbiesManagmentContext);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ style: { borderRadius: 0 } }}
    >

      <AppBar color="bluedash" position='relative' >
        <Toolbar variant="dense" >

          <Typography sx={{ flex: 1, textAlign: 'center', fontWeight: 'light' }} variant="h6" component="div">
            Administrador de almacén
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', height: '100%' }}>

        <SettingsWarehouseSidebar />

        <WarehouseGrid />

      </Box>


      <CustomSnackbar open={snackbarProps.open} message={snackbarProps.message} type={snackbarProps.type} onClose={handleCloseSnackBar} />
      <ProgressIndicator open={isLoading} />
    </Dialog>
  );
}

export default CubbiesManagment;
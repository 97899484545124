import api from './api';
import TokenService from "./token.service";

export const getSellers = () => {
  return api.get("api/Vendedor", {
      headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      }
  });
};

export const getSellerByName = (sellerName) => {
  return api.get("api/Vendedor/vendedor/"+sellerName, {
      headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      }
  });
};

export const postSeller = (sellerData) => {
  const res = api.post('api/Vendedor', sellerData, {
      headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      }
  });
  return res;
};

export const putSeller = (sellerData) => {
  const res = api.put('api/Vendedor/'+sellerData.id_vendedor, sellerData, {
      headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      }
  });
  return res;
};
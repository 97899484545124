import Swal from "sweetalert2";

const success = (message) => {
  Swal.fire({
    icon: 'success',
    title: message,
    showConfirmButton: false,
    timer: 1500
  });
}

const error = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: message,
  });
}

const errorList = (error) => {

  let errorsString = error;

  if (error.hasOwnProperty('response')) {

    const { data } = error.response;

    if (typeof data !== 'object') {
      errorsString = data;
    } else {
      
      errorsString = '<dl>'; // Se inicia una description list
      for (let err in data.errors) {
        errorsString += `<dt><b>${err}</b></dt>`;

        data.errors[err].map(errString => errorsString += `<dd>${errString}<dd>`);
        // errorsString.push(data.errors[err].map((errArray) => `${err}: ${errArray}`).toString());
      }
      
      errorsString += '</dl>';
    }
  }

  Swal.fire({
    icon: 'error',
    title: 'Error',
    html: errorsString,
  });
}

const save = () => {

  return Swal.fire({
    icon: 'warning',
    title: '¿Seguro que deseas guardar cambios?',
    showCancelButton: true,
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f44336',
  }).then((result) => result.isConfirmed)

}

const SwalAlert = {
  success,
  error,
  errorList,
  save
}


export default SwalAlert;
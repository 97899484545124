// React and Hooks
import React, { useContext } from 'react';

// Material
import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

// Components
import Layout from "@components/Layout";
import ProgressIndicator from '@components/ProgressIndicator';
import SearchBar from '@components/SearchBar';
import TableData from '@components/TableData';
import AddButton from '@components/AddButton';
import SellerForm from './components/SellerForm';


// Providers / Context
import SellersPageContext, { SellersPageProvider } from './context/SellersContext';

const SellersPage = () => {
  return (
    <SellersPageProvider>
      <SellersPageWithContext />
    </SellersPageProvider>
  );
}

const SellersPageWithContext = () => {

  const {
    isLoading,
    sellers,
    handleSearch,
    getSellersColumns,
    getSellersRows,
    handleSelectedRow,
    showSellerForm,
    showSellerFormArea,
    handleShowSellerForm,
    selectedSeller,
    handleClickNewSeller
  } = useContext(SellersPageContext)

  const stylesUsers = {
    display: {
      xxs: showSellerFormArea ? 'none' : 'initial',
      lg: 'inline-block'
    },
  }

  

  return (
    <>
      <Layout>
        <Grid container wrap="nowrap" sx={{ px: { xxs: 2, lg: 4 },  overflowY: 'hidden'  }}>
          {/* Contenido de Vendedores */}
          <Grid container item rowSpacing={3} xxs={12} lg={showSellerFormArea ? 5 : 12} sx={stylesUsers} >

            <Grid container item xxs={12} >
              <Grid item>
                <Typography variant="h4" color={grey[900]} sx={{ mb: { xxs: 1, lg: 3 } }}>Vendedores</Typography>
              </Grid>

              <Grid container item columnSpacing={2} justifyContent="space-between" wrap="nowrap">
                <Grid item xxs={showSellerFormArea ? 12 : 10} lg={showSellerFormArea ? 12 : 9}>
                  <SearchBar id="seller-searchbar" onSearch={handleSearch} />
                </Grid>

                <Grid item sx={{ display: showSellerFormArea ? 'none' : 'initial' }}>
                  <AddButton onClick={handleClickNewSeller}>Nuevo vendedor</AddButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xxs={12}>
              <TableData columns={getSellersColumns(showSellerFormArea)} rows={getSellersRows(sellers)} onSelectedRow={handleSelectedRow} />
            </Grid>

          </Grid>

          {/* Formulario */}
          {
            showSellerFormArea && <Grid item xxs={12} lg={showSellerFormArea ? 7 : 12} sx={{ maxHeight: '89%', display: showSellerFormArea ? 'initial' : 'none' }}>
              {
                showSellerForm && <SellerForm seller={selectedSeller} onShow={handleShowSellerForm} onSubmit={handleSearch} />
              }
            </Grid>
          }

        </Grid>
      </Layout>

      <ProgressIndicator open={isLoading} />
    </>
  );
}

export default SellersPage;
import React, { useState, useContext, useEffect } from 'react'

import { Box, Typography } from '@mui/material';

import CubbiesManagmentContext from '../context/CubbiesManagmentContext';

import SectionGridItem from './SectionGridItem';

const WarehouseGrid = () => {

  const [warehouseGrid, setWarehouseGrid] = useState([]);

  const {
    dimensions,
    sections,
    aisles,
    warehouse
  } = useContext(CubbiesManagmentContext);

  const { scale, length, width } = dimensions;

  const aislesWidth = aisles * scale;
  const wareScaledLength = length * scale;
  const wareScaledWidth = width * scale;

  const calculateGrid = () => {

    if (sections.length == 0) return setWarehouseGrid([]);

    if (wareScaledLength == 0 || wareScaledWidth == 0) return;

    let wareGrid = [];

    let avaliableWidth = wareScaledLength - aislesWidth;
    let avaliableHeight = wareScaledWidth - aislesWidth;

    let sectionsSort = [...sections];

    // Escalar dimensiones de cada sección
    sectionsSort = sectionsSort.map(section => {

      const { alto: sectHeight, largo: sectLength, ancho: sectWidth } = section;

      return {
        ...section,
        alto: sectHeight * scale,
        largo: sectLength * scale,
        ancho: sectWidth * scale,
        volumen: (sectHeight * scale) * (sectLength * scale) * (sectWidth * scale)
      }
    })

    // Ordenar las secciones por tamaño de mayor a menor
    sectionsSort.sort((a, b) => {

      const { id_seccion: aId, largo: aLength, ancho: aWidth, alto: aHeight, volumen: aVolume } = a;
      const { id_seccion: bId, largo: bLength, ancho: bWidth, alto: bHeight, volumen: bVolume } = b;

      if (aLength == bLength && aWidth == bWidth && aHeight == bHeight) {
        return aId - bId;
      } else {
        return bVolume - aVolume;
      }
    })

    // Acomodar cada sección y generar la matriz
    sectionsSort.map((section, idx) => {

      const { largo: sectLength, ancho: sectWidth } = section;

      if ((sectWidth + aislesWidth) <= avaliableWidth) {
        if ((sectLength + aislesWidth) <= avaliableHeight) {
          if (idx == 0) {
            wareGrid.push([]);
          }
          wareGrid[wareGrid.length - 1].push(section);
          avaliableWidth -= sectWidth + aislesWidth;
        }
      } else {

        avaliableHeight -= (sectLength + aislesWidth);

        if ((sectLength + aislesWidth) <= avaliableHeight) {
          wareGrid.push([section]);
          avaliableWidth = wareScaledLength - sectWidth - aislesWidth;
        }
      }

    });

    setWarehouseGrid(wareGrid);

  }

  useEffect(() => {
    calculateGrid();
  }, [sections, dimensions])

  const warehouseStyle = {
    width: wareScaledLength,
    height: wareScaledWidth,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    gap: `${aislesWidth}px`,
    flexWrap: 'wrap',
    overflow: 'auto',
    border: 1,
    borderRadius: 1,
    boxShadow: 2,
    borderColor: 'orangepkt1.main',
  }

  const warehouseContainerStyle = {
    my: 1,
    p: 3,
    height: '80vh',
    width: '100%',
    border: 1,
    borderRadius: 2,
    borderColor: 'bluedash.main',
    overflow: 'auto'
  }

  return (
    <Box sx={{ flexGrow: 1, pt: 3, px: 5 }} >
      <Typography variant="h5">{warehouse.nombre_almacen}</Typography>
      <Box sx={warehouseContainerStyle}>
        <Box sx={warehouseStyle}>
          {
            warehouseGrid.map((rowGrid, rowId) => <Box key={`sect-row${rowId}`} sx={{ display: 'flex', gap: `${aislesWidth}px` }}>
              {
                rowGrid.map((colGrid, secItemIdx) => <SectionGridItem key={`sect-item${secItemIdx}`} section={colGrid} />)
              }
            </Box>)
          }
        </Box>
      </Box>
    </Box>
  )
}

export default WarehouseGrid
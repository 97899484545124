import React from 'react'

import { Backdrop, Box, useMediaQuery, useTheme } from '@mui/material';

import loader from '@assets/img/preloader_luuni.gif';

const ProgressIndicator = ({ open }) => {

  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box >
        <img src={loader} alt="loading..." width={500} height={300} />
      </Box>
    </Backdrop>
  )
}

export default ProgressIndicator;
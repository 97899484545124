import { AssignCubbieInterpret } from '../models/assignCubbies.model';
import api from './api';
import TokenService from "./token.service";

// GET
export const getAssignCubbieBySellerId = async (sellerId, warehouseId = 0) => {

    try {

        const receiptResp = await api.get('api/AsignacionCubbie/vendedor', {
            headers: {
                Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
            },
            params: {
                id_almacen: warehouseId,
                id_vendedor: sellerId
            }
        });

        return AssignCubbieInterpret(receiptResp.data);

    } catch (error) {
        return error;
    }
};

// MUTATIONS
export const postAssignCubbie = (cubbieData) => {

    const res = api.post('api/AsignacionCubbie', cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};


export const postAssignCubbieMultiple = (cubbieData) => {

    const res = api.post('api/AsignacionCubbie/Multiple', cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putAssignCubbie = (assignCubbieData) => {

    const res = api.put('api/AsignacionCubbie/', assignCubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

// React and Hooks
import React, { useContext } from 'react';

// Material
import { Grid, Typography } from '@mui/material';

// Components
import Layout from "@components/Layout";
import ProgressIndicator from '@components/ProgressIndicator';
import SearchBar from '@components/SearchBar';
import TableData from '@components/TableData';
import AddButton from '@components/AddButton';
import CustomAddWarehouseIcon from '@components/CustomAddWarehouseIcon';
import WarehouseForm from './components/WarehouseForm';


// Providers / Context
import WarehousesPageContext, { WarehousesPageProvider } from './context/WarehousesContext';

const WarehousesPage = () => {
  return (
    <WarehousesPageProvider>
      <WarehousesPageWithContext />
    </WarehousesPageProvider>
  );
}

const WarehousesPageWithContext = () => {

  const {
    isLoading,
    warehouses,
    handleSearch,
    getWarehousesColumns, getWarehousesRows,
    handleSelectedRow,
    showWarehouseForm, setShowWarehouseForm,
    showWarehouseFormArea, setShowWarehouseFormArea,
    selectedWarehouse, setSelectedWarehouse,
    handleClickNewWarehouse
  } = useContext(WarehousesPageContext)

  const stylesUsers = {
    display: {
      xxs: showWarehouseFormArea ? 'none' : 'initial',
      lg: 'inline-block'
    },
  }

  const handleShowWarehouseForm = (show) => {
    setSelectedWarehouse({});
    setShowWarehouseFormArea(show);
    setShowWarehouseForm(show);
  }

  return (
    <>
      <Layout>
        <Grid container wrap="nowrap" sx={{ px: { xxs: 2, lg: 4 }, maxHeight: { xxs: '94vh' }, overflowY: 'hidden' }}>
          {/* Contenido de Almacenes */}
          <Grid container item rowSpacing={3} xxs={12} lg={showWarehouseFormArea ? 5 : 12} sx={stylesUsers} >

            <Grid container item xxs={12} >
              <Grid item>
                <Typography variant="h4" color="grey.900" sx={{ mb: { xxs: 1, lg: 3 } }}>Almacenes</Typography>
              </Grid>

              <Grid container item columnSpacing={2} justifyContent="space-between" wrap="nowrap">
                <Grid item xxs={showWarehouseFormArea ? 12 : 10} lg={showWarehouseFormArea ? 12 : 9}>
                  <SearchBar id="warehouses-searchbar" onSearch={handleSearch} />
                </Grid>

                <Grid item sx={{ display: showWarehouseFormArea ? 'none' : 'initial' }}>
                  <AddButton icon={<CustomAddWarehouseIcon />} onClick={handleClickNewWarehouse}>Nuevo almacén</AddButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xxs={12}>
              <TableData columns={getWarehousesColumns(showWarehouseFormArea)} rows={getWarehousesRows(warehouses)} onSelectedRow={handleSelectedRow} />
            </Grid>

          </Grid>

          {/* Formulario */}
          {
            showWarehouseFormArea && <Grid item xxs={12} lg={showWarehouseFormArea ? 7 : 12} sx={{ maxHeight: '89%', display: showWarehouseFormArea ? 'initial' : 'none' }}>
              {
                showWarehouseForm && <WarehouseForm warehouse={selectedWarehouse} onShow={handleShowWarehouseForm} onSubmit={handleSearch} />
              }
            </Grid>
          }

        </Grid>
      </Layout>

      <ProgressIndicator open={isLoading} />
    </>
  );
}

export default WarehousesPage;
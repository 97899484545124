export const getCurrentDateString = () => new Date(Date.now()).toISOString();

export const dateToString = (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

export const stringToDate = (stringDate) => {

  const date = stringDate.split('/');

  return new Date(`${date[1]}/${date[0]}/${date[2]}`); // Mes/Dia/Año porque así lo pide la instancia de Date
};

export const stringToSQLDate = (stringDate) => {
  const date = stringDate.split('/');

  const day = date[0].length < 2 ? `0${date[0]}` : date[0];
  const month = date[1].length < 2 ? `0${date[1]}` : date[1];

  return `${date[2]}-${month}-${day}`;
}

export const formatStringDate = (strDate = '') => {

  if (strDate == '') return '';

  let time;
  let date;

  if (strDate.includes('T')) {
    const splitDate = strDate.split('T');
    date = splitDate[0];
    time = splitDate[1];
  }

  date = date.split('-');

  return `${date[2]}/${date[1]}/${date[0]} ${time}`;
}
import * as Yup from 'yup';

export const getSellerValidator = (seller, onSubmit) => {

  const isNewSeller = Object.entries(seller).length == 0 ? true : false;

  return {
    initialValues: {
      firstName: isNewSeller ? '' : seller.nombre,
      lastName: isNewSeller ? '' : seller.apellidos,
      companyName: isNewSeller ? '' : seller.razon_social, // Razón social y así
      rut: isNewSeller ? '' : seller.rfc,         // Equivalente a RFC
      street: isNewSeller ? '' : seller.direccion.calle,
      postalCode: isNewSeller ? '' : seller.direccion.cp,
      city: isNewSeller ?  '' : seller.direccion.ciudad,     // Ciudad | Localidad | Comuna
      county: isNewSeller ?  '' : seller.direccion.municipio,// Municipio | Provincia
      region: isNewSeller ?  '' : seller.direccion.estado,   // Estado | Región
      country: isNewSeller ?  '' : seller.direccion.pais,    // País
      email: isNewSeller ?  '' : seller.email,               // Y pues el correo :v
      phone: isNewSeller ?  '' : seller.telefonos,
      active: isNewSeller ? true : seller.estatus == 'A' ? true : false
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('El nombre es obligatorio'),
      lastName: Yup.string()
        .required('Los apellidos del usuario son obligatorios'),
      companyName: Yup.string()
        .required('Debe ingresar la razon social'),
      rut: Yup.string().required('Debe ingresar su RUT'),
      postalCode: Yup.string()
        .required('El Código Postal es requerido'),
      street: Yup.string()
        .required('La calle es requerida'),
      city: Yup.string()
        .required('La Ciudad es requerido'),
      county: Yup.string()
        .required('El Municipio es requerido'),
      region: Yup.string()
        .required('El Estado es requerido'),
      country: Yup.string()
        .required('El País es requerido'),
      email: Yup.string()
        .email('El email no es válido')
        .required('Debe ingresar un correo electrónico'),
      phone: Yup.string().required('Debe ingresar su número de teléfono'),
      active: Yup.bool()
    }),
    onSubmit: onSubmit
  };
}


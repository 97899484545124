import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { useTheme } from '@mui/material/styles';

// Material
import {
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
// Icons
import {
  Person as PersonIcon,
  Room as RoomIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  SummarizeOutlined as SummarizeOutlinedIcon,
  Inventory2Outlined as Inventory2OutlinedIcon
} from '@mui/icons-material';


// Components
import FormDivider from '@components/FormDivider';
import ProgressIndicator from '@components/ProgressIndicator';
import TextFieldRutMasked from './TextFieldRutMasked';
import TextFieldPhoneMasked from './TextFieldPhoneMasked';
import ContractDetails from './ContractDetails';

// Services
import { postSeller, putSeller } from '@services/seller.service';
import { getContractById } from '@services/contract.service';

// Validators
import { getSellerValidator } from '../validators/seller-validator';

// Utils
import SwalAlert from "@utils/alerts";
import jwtDecode from 'jwt-decode';
import AssignCubbies from './AssignCubbies';
import SellersPageContext from '../context/SellersContext';
import { useContext } from 'react';

const SellerForm = ({ seller = {}, onShow, onSubmit }) => {

  const isNewSeller = Object.entries(seller).length == 0 ? true : false;

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(isNewSeller ? false : true);

  const {
    contract, setContract,
    openContractDetails,
    handleOpenContractDetails,handleCloseContractDetails,
    openAssignCubbiesModal,
    handleOpenAssignCubbiesModal, handleCloseAssignCubbiesModal
  } = useContext(SellersPageContext);

  const resetFormSeller = () => {
    formik.resetForm();
    onShow(false);
  }

  const handleSubmit = async (values) => {

    setIsLoading(true);

    const userDecoded = jwtDecode(JSON.parse(localStorage.token).jwtToken);

    let sellerData = {
      nombre: values.firstName,
      apellidos: values.lastName,
      razon_social: values.companyName,
      rfc: values.rut,
      telefonos: values.phone,
      email: values.email,
      estatus: values.active ? 'A' : 'C',
      usuario: userDecoded.name,
      direccion: {
        cp: values.postalCode,
        calle: values.street,
        ciudad: values.city,
        municipio: values.county,
        estado: values.region,
        pais: values.country
      }
    }

    try {

      isNewSeller
        ? await postSeller(sellerData)
        : await putSeller({ ...sellerData, id_vendedor: seller.id_vendedor })

      SwalAlert.success(`Vendedor ${isNewSeller ? 'registrado' : 'actualizado'} correctamente`);
      resetFormSeller();
      onSubmit(); // Esto hace una busqueda de todos los sellers de nuevo
    } catch (error) {

      let errorsString = error;

      if (error.hasOwnProperty('response')) {

        const { data } = error.response;

        if (typeof data !== 'object') {
          errorsString = data;
        } else {
          errorsString = [];
          for (let err in data.errors) {
            errorsString.push(data.errors[err].map((errArray) => `${err}: ${errArray}`).toString());
          }
        }

      }

      SwalAlert.error(`Error: \n${errorsString}`);
    }

    setIsLoading(false);
  }

  const getContract = async () => {

    try {

      if (isNewSeller) return;

      let contractResp = await getContractById(seller.id_vendedor);

      // console.log(contractResp);
      // Verifico si es un formato válido
      contractResp = contractResp?.data?.formato?.includes('pdf;base64')
      ? contractResp.data
      : { id_vendedor: seller.id_vendedor }
      
      // console.log(contractResp);
      setContract(contractResp);

    } catch (error) {
      console.log(error);

      let errorsString = error;

      if (error.hasOwnProperty('response')) {

        // Si no tiene contrato se enviará solo el id del vendedor para asignarle el nuevo
        if (error.response.status == 404) return setContract({ id_vendedor: seller.id_vendedor });

        const { data } = error.response;

        if (typeof data !== 'object') {
          errorsString = data;
        } else {
          errorsString = [];
          for (let err in data.errors) {
            errorsString.push(data.errors[err].map((errArray) => `${err}: ${errArray}`).toString());
          }
        }

      }

      SwalAlert.error(`Error: \n${errorsString}`);
    }
  }


  useEffect(() => {
    getContract();
  }, [])

  const formik = useFormik(getSellerValidator(seller, handleSubmit));

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="h-full pt-5 pb-10 px-5 md:px-10 overflow-y-auto">

        {/* ------------- HEADER ------------- */}
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h4" color={theme.palette.bluepkt1.main} sx={{ fontSize: { xxs: '1.5rem', md: '1.8rem', lg: '2.1rem' } }}>{isNewSeller ? 'Nuevo vendedor' : isDisabled ? 'Detelle de vendedor' : 'Editar vendedor'}</Typography>
          </Grid>

          <Grid item>
            <IconButton
              color="bluepkt1"
              size="large"
              onClick={resetFormSeller}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider textAlign="right" sx={{ my: 4 }} >
          {
            !isNewSeller && <IconButton
              aria-label="edit"
              size="large"
              className="bg-orangepkt1 text-white"
              onClick={() => setIsDisabled(false)}
            >
              <EditIcon />
            </IconButton>
          }

        </Divider>

        {/* ------------- BODY FORM ------------- */}
        {/* ------------------------- Información personal ------------------------- */}
        <Grid container spacing={3}>
          <Grid item xxs={12}>
            <FormDivider title='Información personal' icon={<PersonIcon />} />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="firstName"
              label="Nombre"
              placeholder="Ingresa tu nombre"
              variant="outlined"
              type="text"
              fullWidth
              autoFocus={true}
              disabled={isDisabled}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.firstName && formik.errors.firstName}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="lastName"
              label="Apellido"
              placeholder="Ingresa tus apellidos"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.lastName && formik.errors.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </Grid>

          <Grid item xxs={12} md={8}>
            <TextField
              id="companyName"
              label="Razón social"
              placeholder="Razón social"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.companyName && formik.errors.companyName}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            />
          </Grid>

          <Grid item xxs={12} md={4}>
            <TextField
              name="rut"
              label="RUT"
              placeholder="66.666.666-6"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.rut}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.rut && formik.errors.rut}
              error={formik.touched.rut && Boolean(formik.errors.rut)}
              InputProps={{ inputComponent: TextFieldRutMasked }}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              name="phone"
              label="Teléfono"
              placeholder="56 9 3210 6626"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.phone && formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              InputProps={{ inputComponent: TextFieldPhoneMasked }}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="email"
              label="Correo electrónico"
              placeholder="ejemplo@mail.com"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </Grid>

          {/* ------------------------- Domicilio ------------------------- */}
          <Grid item xxs={12}>
            <FormDivider title='Domicilio' icon={<RoomIcon />} />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="street"
              label="Calle"
              placeholder="Ingresa tu calle"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.street && formik.errors.street}
              error={formik.touched.street && Boolean(formik.errors.street)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="postalCode"
              label="Código Postal"
              placeholder="Ingresa tu código postal"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="city"
              label="Comuna"
              placeholder="Ingresa tu comuna"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.city && formik.errors.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="county"
              label="Provincia"
              placeholder="Ingresa tu provincia"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.county}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.county && formik.errors.county}
              error={formik.touched.county && Boolean(formik.errors.county)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="region"
              label="Región"
              placeholder="Ingresa tu región"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.region}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.region && formik.errors.region}
              error={formik.touched.region && Boolean(formik.errors.region)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="country"
              label="País"
              placeholder="Ingresa tu país"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.country && formik.errors.country}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </Grid>

          {/* Si es un usuario nuevo no se muestran botones Ver contrato y Cubbie */}
          {
            !isNewSeller && <>
              <Grid item xxs={12} md={6}>
                <Button
                  variant="contained"
                  startIcon={<SummarizeOutlinedIcon />}
                  className="bg-bluepkt1 w-full disabled:text-gray-600 disabled:bg-grey-500"
                  onClick={handleOpenContractDetails}
                >
                  {contract.hasOwnProperty('id_contrato') ? 'Ver contrato' : 'Agregar contrato'}
                </Button>
              </Grid>
              {
                contract.hasOwnProperty('id_contrato') && <Grid item xxs={12} md={6}>
                  <Button
                    variant="contained"
                    startIcon={<Inventory2OutlinedIcon />}
                    className="bg-bluepkt1 w-full disabled:text-gray-600 disabled:bg-grey-500"
                    onClick={handleOpenAssignCubbiesModal}
                  >
                    Cubbie
                  </Button>
                </Grid>
              }

            </>
          }

        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* ------------- FOOTER ------------- */}
        <Grid container spacing={5} justifyContent="right">
          <Grid item>
            <Button
              variant="text"
              className="text-gray-800 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
              onClick={resetFormSeller}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              className="bg-orangepkt1 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
            >
              {isNewSeller ? 'Guardar' : 'Actualizar'}
            </Button>
          </Grid>
        </Grid>

      </form>
      {
        openContractDetails && <ContractDetails open={openContractDetails} onClose={handleCloseContractDetails} contract={contract} />
      }

      <AssignCubbies open={openAssignCubbiesModal} onClose={handleCloseAssignCubbiesModal} seller={seller} />

      <ProgressIndicator open={isLoading} />
    </>
  )
}

export default SellerForm
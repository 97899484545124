import api from './api';
import TokenService from "./token.service";


export const getContracts = () => {
  return api.get("api/Contrato", {
    headers: {
      Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
    }
  });
};

export const getContractById = (contractId) => {
  return api.get("api/Contrato/contratoId/" + contractId, {
    headers: {
      Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
    }
  });
};

export const getContractSearch = (seller) => {
  return api.get("api/Contrato/" + seller, {
    headers: {
      Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
    }
  });
};

export const postContract = (contractData) => {
  const res = api.post('api/Contrato', contractData, {
    headers: {
      Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
    }
  });
  return res;
};
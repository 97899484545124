export const ProductsSellerInterpret = (products) => products.map(prod => {

  const prodDetails = prod.productos;

  return {
    warehouseId: prod.id_almacen,
    sectionId: prod.id_seccion,
    sellerId: prod.id_vendedor,
    
    productId: prodDetails.id_producto,
    name: prodDetails.nombre,
    quantity: prodDetails.cantidad,
    minStock: prodDetails.stock_minimo,
    length: prodDetails.dim_largo,
    width: prodDetails.dim_ancho,
    height: prodDetails.dim_alto,
    weight: prodDetails.peso,
    volume: prodDetails.volumen,
    productImg: prodDetails.imagen_producto,
    sku: prodDetails.sku,
    description: prodDetails.descripcion,
    isSkuGenerated: prodDetails.generado
  }
})
// React and Hooks
import { createContext, useEffect, useState } from "react";

// Services
import { getCubbieSearch } from "@services/cubbie.service";
import { getSectionSearch } from "@services/section.service";
import { getWarehouses } from "@services/warehouse.service";

// Utils
import SwalAlert from "@utils/alerts";
import { postAssignCubbie, postAssignCubbieMultiple } from "../../../services/assigncubbie.service";


const AssignCubbiesContext = createContext();

export const AssignCubbiesProvider = ({ children, open, onClose, seller }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [sections, setSections] = useState([]);
  const [cubbies, setCubbies] = useState([]);

  const [selectedWareId, setSelectedWareId] = useState(0);
  const [selectedSectId, setSelectedSectId] = useState(0);
  const [selectedCubbies, setSelectedCubbies] = useState([]);
  const [selectedCubbieDetails, setSelectedCubbiesDetails] = useState({});
  const [openCubbieDetails, setOpenCubbieDetails] = useState(false);

  const handleChangeWarehouse = (e) => {
    setSelectedWareId(Number(e.target.value));
    setSelectedSectId(0);
  }

  const handleChangeSection = (e) => {
    setSelectedSectId(Number(e.target.value));
  }

  const handleSelectCubbie = (cubbie) => () => {
    if (isCubbieSelected(cubbie)) {
      const updateSelectedCubbies = selectedCubbies.filter(cubb => cubb.id_cubbie !== cubbie.id_cubbie);

      return setSelectedCubbies([...updateSelectedCubbies]);
    }

    setSelectedCubbies([...selectedCubbies, cubbie]);
  }

  const handleSelectedCubbieDetails = (cubbie) => {
    setSelectedCubbiesDetails((cubb) => cubb = cubbie);
  }

  const handleSaveAssignCubbies = async () => {

    if (selectedCubbies.length == 0) return;

    setIsLoading(true);

    try {

      const cubbieToPostObj = selectedCubbies.map(scubb => ({
        id_vendedor: seller.id_vendedor,
        id_almacen: scubb.id_almacen,
        id_seccion: scubb.id_seccion,
        id_cubbie: scubb.id_cubbie
      }))

      await postAssignCubbieMultiple(cubbieToPostObj);
      

      setIsLoading(false);
      SwalAlert.success('Cubbie asignado correctamente');
      handleOnCloseAssignCubbieModal();

    } catch (e) {
      setIsLoading(false);
      SwalAlert.errorList(e)
    }
  }

  const handleOpenAssignCubbiesDetails = () => setOpenCubbieDetails(true);
  const handleCloseAssignCubbiesDetails = () => setOpenCubbieDetails(false);

  const handleOnCloseAssignCubbieModal = () => {
    setSelectedWareId(0);
    setSelectedSectId(0);
    setSelectedCubbies([]);
    setCubbies([])
    onClose();
  }

  const initGetWarehouses = async () => {
    try {

      const warehouseResp = await getWarehouses();

      setWarehouses(warehouseResp.data);

      if (warehouseResp.data.length == 0) return;
      setSelectedWareId( (wareId) => wareId = warehouseResp.data[0].id_almacen );

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }


  const getSectionsByWareId = async () => {

    if (selectedWareId == 0) return;

    try {

      const sectionsResp = await getSectionSearch({ sectionId: 0, warehouseId: selectedWareId });

      setSections(sectionsResp.data);

      if (sectionsResp.data.length == 0) return;
      setSelectedSectId( (sectId) =>  sectId = sectionsResp.data[0].id_seccion);

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const getCubbiesBySectionId = async () => {

    if (selectedWareId == 0) return;

    try {

      const cubbieSearchObj = {
        id_almacen: selectedWareId,
        id_seccion: selectedSectId,
        id_cubbie: 0,
        estatus: ""
      }

      const cubbiesResp = await getCubbieSearch(cubbieSearchObj);

      setCubbies(cubbiesResp.data);

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const isCubbieSelected = (cubbie) => {

    if (selectedCubbies.length == 0) return false;

    const findCubbie = selectedCubbies.find(cubb => (cubb.id_cubbie === cubbie.id_cubbie)
      && (cubb.id_seccion === cubbie.id_seccion)
      && (cubb.id_almacen === cubbie.id_almacen));

    return Boolean(findCubbie);

  }


  useEffect(() => {

    if (!open) return;

    initGetWarehouses();
  }, [open])

  useEffect(() => {
    getSectionsByWareId();
  }, [selectedWareId])

  useEffect(() => {
    getCubbiesBySectionId();
  }, [selectedSectId])

  return (
    <AssignCubbiesContext.Provider value={{
      isLoading, setIsLoading,
      seller,
      open, onClose,
      warehouses, sections, cubbies,
      selectedWareId, handleChangeWarehouse,
      selectedSectId, handleChangeSection,
      selectedCubbies,
      selectedCubbieDetails,
      handleSelectedCubbieDetails,
      handleSelectCubbie,
      isCubbieSelected,
      handleSaveAssignCubbies,
      openCubbieDetails,
      handleOpenAssignCubbiesDetails,
      handleCloseAssignCubbiesDetails,
      handleOnCloseAssignCubbieModal
    }}>
      {children}
    </AssignCubbiesContext.Provider>
  )
}

export default AssignCubbiesContext;
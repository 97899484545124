class TokenService {

    getLocalRefreshToken() {
        const token = JSON.parse(localStorage.getItem("token"));
        return token?.refreshToken;
    }

    getLocalAccessToken() {
        const token = JSON.parse(localStorage.getItem("token"));
        return token?.JwtToken;
    }

    updateLocalAccessToken(tokenus) {
        let token = JSON.parse(localStorage.getItem("token"));
        token.JwtToken = tokenus;
        localStorage.setItem("token", JSON.stringify(token));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("token"));
    }

    setUser(token) {
        localStorage.setItem("token", JSON.stringify(token));
    }
    
    removeUser() {
        localStorage.removeItem("token");
    }
}
export default new TokenService();
// React and Hooks
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// iMask React
import { IMaskInput } from 'react-imask';

const TextFieldPhoneMasked = forwardRef( (props, ref) => {

  const { onChange, ...other } = props;
  
  return (
    <IMaskInput
      {...other}
      mask="#0 0 0000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextFieldPhoneMasked.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextFieldPhoneMasked;
import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
    return (
        <div className="flex min-h-screen">
            <Sidebar />
            <main className="w-full flex flex-col">
                <Header />
                <div className="h-[calc(100vh_-_48px)] py-2 px-3 sm:py-8 box-border overflow-auto">
                    {children}
                </div>
            </main>
        </div>
    );
}

export default Layout;

// React and Hooks
import React, { useRef } from 'react';

// Material
import {
  Button,
  Modal,
  Typography,
  Box
} from '@mui/material';
// Icons
import {
  DeleteOutline as DeleteOutlineIcon,
  SaveAs as SaveAsIcon,
} from '@mui/icons-material';

import SignatureCanvas from "react-signature-canvas";

// Componente de la Firma
const UserSignature = ({ open, onClose, onSave }) => {

  const signatureModalStyle = {
    width: 600,
    px: 4,
    py: 2,
    bgcolor: 'background.paper',
    boxShadow: 24,
    textAlign: 'center',
    borderRadius: 7,
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  };

  const signCanvas = useRef({});

  const handleClickClear = () => signCanvas.current.clear();

  const handleClickSave = () => onSave(signCanvas);


  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={signatureModalStyle}>
        <Typography variant="h6">Introduzca su firma</Typography>
        <SignatureCanvas
          penColor="black"
          backgroundColor="#f3f4f6"
          canvasProps={{ width: 500, height: 200 }}
          ref={signCanvas}
        />
        <Box sx={{mt:2, display: 'flex', justifyContent: 'center', gap:2}}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={handleClickClear}
          >
            Borrar
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveAsIcon />}
            onClick={ handleClickSave }
            style={{ backgroundColor: '#1b5e20' }}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UserSignature
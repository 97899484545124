import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const IconLabel = ({ icon, label, boldLabel, variant, href, target = '_blank', component, spacing = 1, sx, wrap = false, children }) => {
  return (
    <Box>
      <Stack direction="row" spacing={spacing} alignItems="center" sx={sx}>
        {icon}
        {
          boldLabel && <Typography component={component} variant={variant ?? 'body2'} fontWeight={500}>{boldLabel}</Typography>
        }
        {
          !wrap && <Typography component={href ? 'a' : component} variant={variant ?? 'body2'} href={href} target={href ?? target} >{label}</Typography>
        }

        {!wrap && children}
      </Stack>
      <Box sx={{ pl: 2 }}>
        {
          wrap && <Typography component={href ? 'a' : component} variant={variant ?? 'body2'} href={href} target={href ?? target}>{label}</Typography>
        }
        {
          wrap && children
        }
      </Box>

    </Box>
  )
}

export default IconLabel
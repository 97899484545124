import * as Yup from 'yup';

export const getWarehouseValidator = (warehouse, onSubmit) => {

  const isNewWarehouse = Object.entries(warehouse).length == 0 ? true : false;
  const { direccion } = warehouse;

  return {
    initialValues: {
      warehouseName: isNewWarehouse ? '' : warehouse.nombre_almacen,
      street: isNewWarehouse ? '' : direccion.calle,
      city: isNewWarehouse ? '' : direccion.ciudad,     // Ciudad | Localidad | Comuna
      county: isNewWarehouse ? '' : direccion.municipio,// Municipio | Provincia
      region: isNewWarehouse ? '' : direccion.estado,   // Estado | Región
      country: isNewWarehouse ? '' : direccion.pais,    // País
      postalCode: isNewWarehouse ? '' : direccion.cp,
      phone: isNewWarehouse ? '' : warehouse.telefonos,
    },
    validationSchema: Yup.object({
      warehouseName: Yup.string()
        .required('El nombre es obligatorio'),
      street: Yup.string()
        .required('La calle es requerida'),
      city: Yup.string()
        .required('La Ciudad es requerido'),
      county: Yup.string()
        .required('El Municipio es requerido'),
      region: Yup.string()
        .required('El Estado es requerido'),
      country: Yup.string()
        .required('El País es requerido'),
      postalCode: Yup.string()
        .required('El Código Postal es requerido'),
      phone: Yup.string().required('Debe ingresar su número de teléfono')
    }),
    onSubmit: onSubmit
  };
}


import React from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'

const FormDivider = ({title, icon}) => {
  return (
    <Divider textAlign="left">
      <Stack direction="row" justifyContent="space-evenly" spacing={1}>
        <Box color="bluepkt1.main">{icon}</Box>
        <Typography color="bluepkt1.main" variant="body1" fontWeight={500}> {title} </Typography>
      </Stack>
    </Divider>
  )
}

export default FormDivider
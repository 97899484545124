// Hooks y React
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';

// Material
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Link,
  Button,
  Box,
  Typography,
  Stack
} from '@mui/material';

// Assets
import LuuniLogo from '@assets/img/Logo_luuniCat.png'

// Providers
import LoginContext from "../context/LoginContext";

// Otros
import * as Yup from 'yup';
import Image from "../../../components/Image";
import FormikField from "../../../components/FormikField";

const SignUp = () => {

  const { loginForm, handleToggleLoginForm } = useContext(LoginContext)

  const navigate = useNavigate();
  const signUpFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Debe ingresar un nombre'),
      email: Yup.string()
        .email('El email no es válido')
        .required('Debe ingresar un correo electrónico'),
      password: Yup.string()
        .required('Por favor, introduzca su contraseña')
        .min(8, 'La contraseña debe ser de 8 caracteres')
        .max(8, 'El password debe ser de 8 caracteres')
        .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
        .matches(/[a-z]/, 'La contraseña debe tener al menos una minúscula')
        .matches(/[A-Z]/, 'La contraseña debe tener al menos una mayúscula')
        .matches(/[^\w]/, 'La contraseña debe tener al menos un símbolo'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        .required('Es obligatorio confirmar la contraseña'),
    }),
    onSubmit: () => {
      navigate('/analytic');
    }
  });

  return (
    <Box className="w-full max-w-xs px-10">

      <Image src={LuuniLogo} alt="luuni-logo" width="100%" height="90px" boxFit="contain" />

      <Typography variant="h5" color="bluepkt1.main" fontWeight={500} >Crear cuenta</Typography>

      <Stack direction="row" spacing={1} pb={3} pt={1}>
        <Typography variant="body1">¿Ya tienes una cuenta?</Typography>
        <Link href="#" underline="always" onClick={handleToggleLoginForm} >Inicia Sesión</Link>
      </Stack>

      <Stack spacing={2} component="form" noValidate autoComplete="off" onSubmit={signUpFormik.handleSubmit}>
        
        <FormikField
          id="name"
          label="Nombre *"
          formikInstance={signUpFormik}
        />

        <FormikField
          id="email"
          label="Correo electrónico *"
          type="email"
          formikInstance={signUpFormik}
        />

        <FormikField
          id="password"
          label="Contraseña *"
          type="password"
          formikInstance={signUpFormik}
        />

        <FormikField
          id="passwordConfirm"
          label="Contraseña (Confirmar) *"
          type="password"
          formikInstance={signUpFormik}
        />

        <FormControlLabel
          label={<Typography variant="body2">Acepto los Términos de Servicio y la Política de Privacidad</Typography>}
          control={<Checkbox color="success" />}
        />

        <Button
          type="submit"
          color="orangepkt1"
          className="rounded-full"
        >
          Crear cuenta
        </Button>
      </Stack>
    </Box>
  );
}

export default SignUp;
// React and Hooks
import React, { useContext } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

// Material
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Link,
  Button,
  Backdrop,
  Typography,
  Stack,
  Box
} from '@mui/material';

// Components
import ProgressIndicator from "@components/ProgressIndicator";

// Services
import AuthService from '@services/auth.service';
import { getUserByName } from "@services/user.service";
import TokenService from "../../../services/token.service";

// Assets
import LuuniLogo from '@assets/img/Logo_luuniCat.png'

// Context / Providers
import LoginContext from "../context/LoginContext";

// Otros
import * as Yup from 'yup';
import jwt from 'jwt-decode';
import Swal from 'sweetalert2';
import Image from "../../../components/Image";
import FormikField from "../../../components/FormikField";



const SignIn = () => {

  const { open, setOpen, handleToggleLoginForm, handleClose } = useContext(LoginContext);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validationSchema: Yup.object({
      user: Yup.string()
        .required('Debe ingresar un usuario existente'),
      password: Yup.string()
        .required('Por favor, introduzca su contraseña')
    }),
    onSubmit: async (valores) => {

      setOpen(!open);

      const { user, password } = valores;

      try {
        debugger
        const respLogin = await AuthService.login(user, password);

        TokenService.setUser(respLogin.data);

        const userDecode = jwt(JSON.parse(localStorage.token).JwtToken);

        const { data: userData } = await getUserByName(userDecode.name);

        localStorage.setItem('user ', userData.usuario_detalle.nombre + ' ' + userData.usuario_detalle.apellidos);
        localStorage.setItem('rol', userDecode.role);

        navigate('/analytic');
        handleClose();

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error?.response?.data,
        });
        handleClose();
      }
    }
  });

  return (
    <Box className="w-full max-w-xs px-10">

      <Image src={LuuniLogo} alt="luuni-logo" width="100%" height="90px" boxFit="contain" />

      <Typography variant="h5" color="bluepkt1.main" fontWeight={500} >Inicia sesión</Typography>

      <Stack direction="row" spacing={1} pb={3} pt={1}>
        <Typography variant="body1">¿No tienes una cuenta?</Typography>
        <Link href="#" underline="always" onClick={handleToggleLoginForm} >Únete</Link>
      </Stack>

      <Stack spacing={3} component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>

        <FormikField
          id="user"
          label="Usuario *"
          formikInstance={formik}
        />

        <FormikField
          id="password"
          label="Contraseña *"
          type="password"
          formikInstance={formik}
        />

        <Stack direction="row" alignItems="center">
          <FormControlLabel
            label="Recuérdame"
            control={
              <Checkbox color="success" />
            }
          />
          <Link
            href="#"
            underline="always"
            className="text-right"
          >
            ¿Olvidó su contraseña?
          </Link>

        </Stack>

        <Button
          type="submit"
          color="orangepkt1"
          className="rounded-full"
        >
          Iniciar Sesión
        </Button>

      </Stack>
      <ProgressIndicator open={open} />
    </Box>
  );
}

export default SignIn
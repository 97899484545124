export const sectionsMuestra = [
    {
        "id_almacen": 1,
        "descripcion": "Sección 1",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 2",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 3",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 4",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 5",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 6",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 7",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 8",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 9",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 10",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 11",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 12",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 13",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 14",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 15",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 16",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 17",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 18",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 19",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    },
    {
        "id_almacen": 1,
        "descripcion": "Sección 20",
        "alto": 10,
        "largo": 27,
        "ancho": 8,
        "volumen": 2160,
        "total_cubbies": 0,
        "cubbies_disponibles": 0,
    }
]

export const cubbiesMuestra = [
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 1,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 2,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 3,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 4,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 5,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 6,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 7,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 8,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 9,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 10,
        "cubbie_largo": 3,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 48,
        "estatus": "A"
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "estatus": "A",
        "id_almacen": 1,
        "id_seccion": 11,
        "cubbie_alto": 2,
        "cubbie_ancho": 8,
        "cubbie_largo": 4.5,
        "cubbie_volumen": 72
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 12,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 13,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 14,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 15,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 16,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 17,
        "cubbie_largo": 4.5,
        "cubbie_ancho": 8,
        "cubbie_alto": 2,
        "cubbie_volumen": 72,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 18,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 19,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    },
    {
        "id_almacen": 1,
        "id_seccion": 20,
        "cubbie_largo": 6.75,
        "cubbie_ancho": 8,
        "cubbie_alto": 2.5,
        "cubbie_volumen": 135,
        "estatus": "A"
    }
]

export const warehouseMuestra = [
    {
        "nombre_almacen": "Almacén Sendero LM",
        "telefonos": "6687711834",
        "estatus": "A",
        "direccion": {
            "cp": "81491",
            "calle": "Congress Street",
            "ciudad": "Gloucester",
            "municipio": "Escuinapa",
            "estado": "Colorado",
            "pais": "Somalia"
        }
    },
    {
        "nombre_almacen": "Almacén Culiacán Pariente",
        "telefonos": "6689383356",
        "estatus": "A",
        "largo": 102,
        "ancho": 60,
        "alto": 10,
        "direccion": {
            "cp": "81341",
            "calle": "Durland Place",
            "ciudad": "Edmund",
            "municipio": "Choix",
            "estado": "Oklahoma",
            "pais": "Japan"
        }
    },
    {
        "nombre_almacen": "Almacén Mazatlán Venados",
        "telefonos": "6681621630",
        "estatus": "A",
        "largo": 92,
        "ancho": 70,
        "alto": 12,
        "direccion": {
            "cp": "81263",
            "calle": "Lynch Street",
            "ciudad": "Sharon",
            "municipio": "Navolato",
            "estado": "Oregon",
            "pais": "Lesotho"
        }
    },
    {
        "nombre_almacen": "Almacén Guadalajara",
        "telefonos": "6688980746",
        "estatus": "A",
        "direccion": {
            "cp": "81264",
            "calle": "Pacific Street",
            "ciudad": "Westerville",
            "municipio": "Navolato",
            "estado": "Tennessee",
            "pais": "Honduras"
        }
    },
    {
        "nombre_almacen": "Almacén Arremangala Arrempujala",
        "telefonos": "6682705569",
        "estatus": "A",
        "direccion": {
            "cp": "81318",
            "calle": "Strong Place",
            "ciudad": "Winfred",
            "municipio": "Culiacán",
            "estado": "New Hampshire",
            "pais": "Jordan"
        }
    },
    {
        "nombre_almacen": "Almacén Papas con Catsup",
        "telefonos": "6682036485",
        "estatus": "A",
        "direccion": {
            "cp": "81273",
            "calle": "Linden Boulevard",
            "ciudad": "Delco",
            "municipio": "San",
            "estado": "Guam",
            "pais": "S. Georgia and S. Sandwich Isls."
        }
    },
    {
        "nombre_almacen": "Almacén Guasave",
        "telefonos": "6683888929",
        "estatus": "A",
        "direccion": {
            "cp": "81305",
            "calle": "Cadman Plaza",
            "ciudad": "Maplewood",
            "municipio": "Choix",
            "estado": "Vermont",
            "pais": "Saint Kitts and Nevis"
        }
    },
    {
        "nombre_almacen": "Almacén La Gran Papa",
        "telefonos": "6683696234",
        "estatus": "A",
        "direccion": {
            "cp": "81416",
            "calle": "Croton Loop",
            "ciudad": "Cowiche",
            "municipio": "Mazatlán",
            "estado": "American Samoa",
            "pais": "Philippines"
        }
    },
    {
        "nombre_almacen": "Almacén Ramen Ñenguele",
        "telefonos": "6685654093",
        "estatus": "A",
        "direccion": {
            "cp": "81477",
            "calle": "Powell Street",
            "ciudad": "Manchester",
            "municipio": "Badiraguato",
            "estado": "Florida",
            "pais": "Belize"
        }
    },
    {
        "nombre_almacen": "Almacén Returbio Bro",
        "telefonos": "6688795706",
        "estatus": "A",
        "direccion": {
            "cp": "81386",
            "calle": "Manhattan Avenue",
            "ciudad": "Tetherow",
            "municipio": "El Fuerte",
            "estado": "Texas",
            "pais": "Montserrat"
        }
    }
]
import { SvgIcon } from '@mui/material';
import React from 'react'

const CustomCubbieIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M2.481,10.449c0.168,0.049,0.326,0.094,0.484,0.142
		c1.526,0.456,3.051,0.913,4.578,1.364c0.818,0.244,1.639-0.088,2.068-0.826c0.774-1.339,1.547-2.68,2.32-4.019
		c0.018-0.029,0.036-0.058,0.071-0.116c0.049,0.081,0.09,0.144,0.129,0.209c0.752,1.3,1.502,2.6,2.254,3.898
		c0.459,0.789,1.26,1.096,2.137,0.834c1.625-0.486,3.252-0.969,4.879-1.452c0.037-0.011,0.076-0.015,0.133-0.026
		c0.004,0.075,0.01,0.135,0.01,0.196c0.002,2.203,0.002,4.406,0,6.607c0,0.719-0.338,1.154-1.039,1.336
		c-2.629,0.689-5.26,1.373-7.889,2.061c-0.418,0.109-0.833,0.104-1.25-0.006c-2.641-0.691-5.283-1.377-7.924-2.066
		c-0.598-0.154-0.969-0.605-0.97-1.213c-0.005-2.266-0.002-4.531-0.002-6.798C2.469,10.541,2.475,10.507,2.481,10.449z"/>
      <path d="M12.003,4.596c-0.616,1.064-1.218,2.104-1.821,3.143
		c-0.518,0.894-1.034,1.789-1.553,2.683c-0.203,0.348-0.438,0.441-0.832,0.324c-2.411-0.717-4.822-1.434-7.233-2.153
		c-0.415-0.124-0.598-0.529-0.41-0.923C0.734,6.455,1.32,5.244,1.902,4.032c0.047-0.099,0.093-0.199,0.141-0.297
		c0.148-0.301,0.351-0.424,0.684-0.383c0.756,0.094,1.511,0.197,2.266,0.297C6.167,3.805,7.34,3.961,8.514,4.117
		c1.093,0.146,2.185,0.305,3.282,0.429c0.301,0.035,0.62-0.022,0.926-0.061c1.369-0.176,2.736-0.361,4.105-0.544
		c1.432-0.191,2.861-0.38,4.293-0.576c0.504-0.069,0.68,0.024,0.9,0.489c0.598,1.25,1.199,2.499,1.801,3.746
		c0.225,0.469,0.061,0.863-0.438,1.012c-2.404,0.717-4.813,1.433-7.217,2.148c-0.346,0.103-0.594,0.005-0.777-0.31
		c-1.084-1.874-2.166-3.749-3.252-5.623C12.099,4.757,12.058,4.688,12.003,4.596z"/>
    </SvgIcon>
  )
}

export default CustomCubbieIcon;
import { SvgIcon } from '@mui/material';
import React from 'react'

const CustomCubbiesIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M25.1056 11.25H21.6334V15L20.2445 14.168L18.8556 15V11.25H15.3834C15.0014 11.25 14.6889 11.5312 14.6889 11.875V19.375C14.6889 19.7188 15.0014 20 15.3834 20H25.1056C25.4875 20 25.8 19.7188 25.8 19.375V11.875C25.8 11.5312 25.4875 11.25 25.1056 11.25ZM8.43894 8.75H18.1612C18.5431 8.75 18.8556 8.46875 18.8556 8.125V0.625C18.8556 0.28125 18.5431 0 18.1612 0H14.6889V3.75L13.3 2.91797L11.9112 3.75V0H8.43894C8.05699 0 7.74449 0.28125 7.74449 0.625V8.125C7.74449 8.46875 8.05699 8.75 8.43894 8.75ZM11.2167 11.25H7.74449V15L6.3556 14.168L4.96672 15V11.25H1.49449C1.11255 11.25 0.800049 11.5312 0.800049 11.875V19.375C0.800049 19.7188 1.11255 20 1.49449 20H11.2167C11.5987 20 11.9112 19.7188 11.9112 19.375V11.875C11.9112 11.5312 11.5987 11.25 11.2167 11.25Z" />
    </SvgIcon>
  )
}

export default CustomCubbiesIcon;
// Material
import { Grid, Typography } from '@mui/material';

import {
  BusinessCenter as BusinessCenterIcon,
  Email as EmailIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
} from '@mui/icons-material';

// Components
import CustomAvatar from '@components/CustomAvatar'
import CustomArrowForwardIcon from '@components/CustomArrowForwardIcon';


export const getUsersColumns = (showUserFormArea) => {
  // showUserFormArea sirve para ocultar columnas si se esta mostrando el formulario
  const enabledCols = {
    display: showUserFormArea ? { lg: 'none' } : { xxs: 'none', sm: 'revert' }
  }

  return [
    { id: 'id', style: { display: 'none' } },
    { id: 'name', label: 'Nombre de usuario' },
    { id: 'email', label: 'Correo', style: enabledCols },
    { id: 'status', label: 'Estado', style: { minWidth: 60, textAlign: 'center', ...enabledCols } },
    { id: 'actions', style: { textAlign: 'center' } },
  ];

}

export const getUsersRows = (users) => users.map(user => {

  const fullName = `${user.usuario_detalle.nombre} ${user.usuario_detalle.apellidos}`

  // Cada renglon de la tabla debe tener su campo id (el cual estará oculto al usuario)
  // para buscar por id y llenar el formulario (OBLIGATORIO)
  return {
    id: user.usuario,
    name: <Grid container columnSpacing={3} wrap="nowrap">

      <Grid item><CustomAvatar name={fullName} /></Grid>

      <Grid container item direction="column" justifyContent="center" >
        <Grid item>
          <Typography variant="subtitle1">{fullName}</Typography>
        </Grid>
        <Grid container item wrap="nowrap">
          <BusinessCenterIcon fontSize="small" />
          <Typography variant="body2" ml={0.5}>{user.rol_descripcion}</Typography>
        </Grid>
      </Grid>

    </Grid>,
    email: <Grid container>
      <EmailIcon fontSize="small" />
      <Typography variant="body2" ml={0.5}>{user.email}</Typography>
    </Grid>,
    status: user.usuario_detalle.estatus == 'A'
      ? <CheckCircleOutlinedIcon color="success" />
      : <RemoveCircleOutlineOutlinedIcon color="error" />,
    actions: <CustomArrowForwardIcon />
  };
})
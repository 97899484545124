import { getDaysInMonth, getMonth, getYear } from "date-fns";

let shipments = [];
const currentDate = new Date();

const getRnd = (min = 0, max = 1) => {
  return Math.floor(Math.random() * max) + min;
}

const generateShipments = () => {

  let shipmentsTemp = [];
  let month = getMonth(currentDate) + 1;
  month = month < 10 ? `0${month}` : month;

  for (let i = 1; i <= 500; i++) {
    const rndDay = getRnd(1, 30);
    const day = rndDay < 10 ? `0${rndDay}` : `${rndDay}`;

    shipmentsTemp = [
      ...shipmentsTemp,
      {
        id: Number(`${i}${getRnd(0, 50)}`),
        state: getRnd(1, 3),
        date: `2023-${month}-${day}T00:00:00Z`
      }
    ]
  }

  shipments = shipmentsTemp;

}

generateShipments();

export const getAllShipments = () => {

  const month = getMonth(currentDate) + 1;
  const daysInMonth = getDaysInMonth(month);
  const monthName = currentDate.toLocaleString('es-ES', { month: 'short' })

  let formatedShipments = [];

  for (let day = 1; day <= daysInMonth; day++) {
    formatedShipments = [
      ...formatedShipments,
      {
        dia: `${day}-${monthName}`,
        envios: shipments.filter((shipment) => {

          const shipmentDate = new Date(shipment.date)

          if (shipmentDate.getDate() === day) {
            return shipment;
          }
        }).length,
      }
    ]

  }

  // console.log(formatedShipments);
  return formatedShipments;

}

export const getShipmentsByState = (state) => {

  let shipmentsByeState = [];

  shipmentsByeState = shipments.filter((shipment) => shipment.state === state);

  const month = getMonth(currentDate) + 1;
  const daysInMonth = getDaysInMonth(month);
  const monthName = currentDate.toLocaleString('es-ES', { month: 'short' })

  let formatedShipments = [];

  for (let day = 1; day <= daysInMonth; day++) {
    formatedShipments = [
      ...formatedShipments,
      {
        dia: `${day}-${monthName}`,
        envios: shipmentsByeState.filter((shipment) => {

          const shipmentDate = new Date(shipment.date)

          if (shipmentDate.getDate() === day) {
            return shipment;
          }
        }).length,
      }
    ]

  }

  return formatedShipments;
}
export const ReceiptsInterpret = (receipt) => receipt.map((rec) => {

  const receiptObj = {
    receiptId: rec.id_entrada,
    warehouseId: rec.id_almacen,
    sectionId: rec.id_seccion,
    cubbieId: rec.id_cubbie,
    sellerId: rec.id_vendedor,
    sellerName: rec.nombreCompleto,
    status: rec.estatus,
    createdAt: rec.fecha_creacion,
    updatedAt: rec.fecha_actualizacion,
    coments: rec.comentario,
    user: rec.usuario,
    signature: rec.firma,
    quantity: rec.cantidad,
    type: rec.tipo,
    sourceAddress: rec.direccion_origen,
    destinationAddress: rec.direccion_destino,
  }

  if (rec?.producto) {
    receiptObj.product = rec.producto;
  }

  return receiptObj;
});

// "id_entrada": 4,
// "id_almacen": 10,
// "id_seccion": 1,
// "id_cubbie": 1,
// "id_vendedor": 3,
// "estatus": "A",
// "fecha_creacion": "2023-02-25T00:00:00",
// "fecha_actualizacion": "2023-02-27T00:00:00",
// "comentario": "2023-02-25",
// "usuario": "roberto",
// "firma": "diosito",
// "cantidad": 2,
// "tipo": "tarima",
// "direccion_origen": 30,
// "direccion_destino": 39,
// "nombreCompleto": "luffy dmonkey"
import React, { useState, useContext, useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import CustomCubbieIcon from '../../../components/CustomCubbieIcon';

import CubbiesManagmentContext from '../context/CubbiesManagmentContext';
import { generateId } from '../../../utils/utils';
import { brown } from '@mui/material/colors';

const SectionGridItem = ({ section }) => {

  const { cubbies, dimensions } = useContext(CubbiesManagmentContext);

  const cubbiesOfSection = cubbies.filter(cubb => cubb.id_seccion === section.id_seccion)
  const cubbiesLength = cubbiesOfSection.length;
  const sectionDim = {
    length: section.largo / dimensions.scale,
    width: section.ancho / dimensions.scale,
    height: dimensions.height,
  }

  const initStatesObj = [
    {
      status: 'A',
      label: 'Disponibles',
      quantity: 0,
      color: 'success'
    },
    {
      status: 'O',
      label: 'Ocupados',
      quantity: 0,
      color: 'error'
    },
    {
      status: 'R',
      label: 'Reservados',
      quantity: 0,
      color: 'warning'
    },
    {
      status: 'T',
      label: 'Total',
      quantity: cubbiesLength,
      color: 'default'
    }
  ];

  const [cubbiesStates, setCubbiesStates] = useState([...initStatesObj]);


  let normalise = (value) => {
    if (value < 1) {
      value = 1;
    }

    return ((value - 1) * 100) / (cubbiesLength - 1);
  };

  const getProgressValue = () => normalise(cubbiesLength - getAvailablesCubbies());

  const getProgressColor = () => {

    if (getProgressValue() < 66) return 'success';
    if (getProgressValue() == 100) return 'error';

    return 'warning';

  }

  const getCubbiesStates = () => {

    let statesObj = [...initStatesObj];

    for (const stateKey in statesObj) {
      for (const cubb of cubbiesOfSection) {
        if (statesObj[stateKey].status == cubb.estatus) {

          let quantity = statesObj[stateKey].quantity + 1;

          statesObj[stateKey] = { ...statesObj[stateKey], quantity }
        }
      }
    }

    setCubbiesStates(statesObj);
  }

  const getAvailablesCubbies = () => {
    const availables = cubbiesStates.find(cubbState => cubbState.status == 'A') || { quantity: 0 };

    return availables.quantity;
  }

  const sectionStyle = {
    p: 0,
    minWidth: section.ancho,
    height: section.largo,
    fontSize: `${section.ancho * 0.4}px`,
    boxShadow: 2,
    borderRadius: 0,
    transition: '100ms ease-in-out all',
    '&:hover': {
      boxShadow: 3,
      transform: 'scale(1.06)',
      transition: '200ms ease-in-out all'
    }
  }

  const fontSize = (section.ancho * 0.25);

  const tooltipStyle = {
    sx: {
      "& .MuiTooltip-tooltip": {
        color: 'grey.600',
        bgcolor: 'common.white',
        border: 1,
        borderColor: 'bluepkt1.main',
        borderRadius: 2,
        fontSize: 10,
        boxShadow: 3
      }
    }

  }

  useEffect(() => {
    getCubbiesStates();
  }, [cubbies])


  return (
    <Tooltip placement='right' PopperProps={tooltipStyle} title={
      <>
        <Typography variant='body2' >{section.descripcion}</Typography>
        <Typography variant='body2' >{`${sectionDim.length} x ${sectionDim.width} x ${sectionDim.height}`}</Typography>
        {
          cubbiesStates.map(stateObj => <Stack key={generateId()} direction="row" spacing={1} alignItems='center'>
            <CustomCubbieIcon color={stateObj.color} sx={{ fontSize: '16px' }} />
            <Typography variant='body2' >{`${stateObj.quantity} - ${stateObj.label}`}</Typography>
          </Stack>)
        }
      </>
    }>

      <Button
        color="bluepkt1"
        variant="outlined"
        disableFocusRipple
        sx={sectionStyle}
      >
        <Stack justifyContent="center" alignContent="space-evenly" alignItems="center" gap={`${section.largo * 0.15}px`} >
          <CustomCubbieIcon sx={{ color: brown[500], fontSize: fontSize + (fontSize * 0.5) }} />
          <Typography variant="body2" fontSize={fontSize}>{`${getAvailablesCubbies()} / ${cubbiesLength}`}</Typography>

          <Stack justifyContent="center" alignItems="center">
            <CircularProgress
              variant="determinate"
              value={100}
              thickness={5}
              size={fontSize + (fontSize * 1.1)}
              sx={{ color: 'grey.300', position: 'absolute'}}
            />
            <CircularProgress
              variant="determinate"
              color={getProgressColor()}
              value={getProgressValue()}
              thickness={5}
              size={fontSize + (fontSize * 1.1)}
              sx={{ position: 'absolute' }}
            >
            </CircularProgress>
          </Stack>

        </Stack>
      </Button>
    </Tooltip>
  )
}

export default SectionGridItem;
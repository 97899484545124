// React and Hooks
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useContext, useState } from "react";
import ProgressIndicator from "../../../components/ProgressIndicator";
import { putCubbie, postCubbie, postCubbieMultiple } from "../../../services/cubbie.service";
import SwalAlert from "../../../utils/alerts";
import CubbiesManagmentContext from "../context/CubbiesManagmentContext";
import { getCubbiesVolume } from "../utils/dimensions.utils";
import CustomDimensionField from "./CustomDimensionField";

const NewCubbie = ({ open, cubbie = {} }) => {

  const isNewCubbie = !cubbie.hasOwnProperty('id_cubbie');

  const [currentCubbie, setCurrentCubbie] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [respectiveSection, setRespectiveSection] = useState({ alto: 1, ancho: 1, largo: 1 })

  const {
    warehouse,
    setOpenNewCubbieModal,
    sections, cubbies,
    setSnackbarProps,
    initGetCubbies
  } = useContext(CubbiesManagmentContext);

  const handleClose = () => {
    cubbie = {};
    setOpenNewCubbieModal(false);
  }

  const handleSave = async () => {

    setIsLoading(true);

    try {

      if (!isNewCubbie) {

        const cubbiesOfSection = cubbies.filter(cubb => cubb.id_seccion === respectiveSection.id_seccion);
        // Le quitamos el cubbie actual para ver si cabe en la sección con las nuevas dimensiones
        const cubbiesOfSectionWithoutCurrent = cubbiesOfSection.filter(cubbSect => cubbSect.id_cubbie !== cubbie.id_cubbie);

        const cubbiesVolume = getCubbiesVolume(cubbiesOfSectionWithoutCurrent);
        const currCubbieVolume = currentCubbie.length * currentCubbie.width * currentCubbie.height;
        const sectionVolume = respectiveSection.alto * respectiveSection.ancho * respectiveSection.largo;

        if ((cubbiesVolume + currCubbieVolume) > sectionVolume) throw 'No hay espacio suficiente en la sección para este cubbie';

        const cubbieData = {
          id_cubbie: cubbie.id_cubbie,
          id_seccion: cubbie.id_seccion,
          id_almacen: warehouse.id_almacen,
          descripcion: currentCubbie.description,
          cubbie_largo: currentCubbie.length,
          cubbie_ancho: currentCubbie.width,
          cubbie_alto: currentCubbie.height,
          estatus: cubbie.estatus
        }

        await putCubbie(cubbieData);

      } else {

        // Extraemos la cantidad de Cubbies
        const { quantity, description } = currentCubbie;

        let generatedCubbies = [];

        // Se genera un arreglo de secciones en base a la cantidad del input
        for (let i = 1; i <= quantity; i++) {

          let cubbieData = {
            id_seccion: cubbie.id_seccion,
            id_almacen: warehouse.id_almacen,
            cubbie_largo: currentCubbie.length,
            cubbie_ancho: currentCubbie.width,
            cubbie_alto: currentCubbie.height,
            estatus: 'A'
          }

          cubbieData.descripcion = description.length == 0
            ? `Cubbie ${cubbies.length + i}`
            : description

          generatedCubbies.push({ ...cubbieData });
        }

        // Aquí va el post de Cubbies
        generatedCubbies.length == 1
          ? await postCubbie(generatedCubbies[0])
          : await postCubbieMultiple(generatedCubbies)

      }

      handleClose();
      setSnackbarProps({ open: true, message: `Cubbie ${isNewCubbie ? 'agregada' : 'modificado'} correctamente`, type: 'success' });
      initGetCubbies(); // Se hace un get de Secciones a la api para actualizar la lista

    } catch (error) {
      SwalAlert.errorList(error);
    }

    setIsLoading(false);
  }

  const handleChangeCubbie = (e) => {
    const id = e.target.id;
    let value = e.target.type == 'text' ? e.target.value : Number(e.target.value);

    setCurrentCubbie({
      ...currentCubbie,
      [id]: value
    })
  }

  const setInitialValues = () => {

    const findRespectiveSection = sections.find(sect => sect.id_seccion === cubbie.id_seccion);

    const initValues = {
      description: isNewCubbie ? '' : cubbie.descripcion,
      quantity: 1,
      length: cubbie.cubbie_largo,
      height: isNewCubbie ? 1 : cubbie.cubbie_alto,
      width: cubbie.cubbie_ancho
    };

    setCurrentCubbie(initValues);

    if (findRespectiveSection == undefined) return;

    setRespectiveSection(findRespectiveSection);
  }

  // Actualizar los valores iniciales en cada re-render porque se mantiene el estado
  useEffect(() => {
    setInitialValues();
  }, [cubbie])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <DialogTitle fontSize={18} textAlign="center">
        {isNewCubbie ? 'Nuevo Cubbie' : 'Editar cubbie'}
      </DialogTitle>

      <DialogContent sx={{ mt: 2, mx: 3, px: 0 }}>
        <Stack spacing={2} pt={1}>

          <TextField
            id="description"
            label="Descripción"
            size="small"
            value={currentCubbie.description}
            onChange={handleChangeCubbie}
          />

          {
            isNewCubbie && <CustomDimensionField
              id="quantity"
              label="Cantidad"
              adornments={{ inputProps: { min: 1 } }}
              value={currentCubbie.quantity}
              onChange={handleChangeCubbie}
            />
          }

          <CustomDimensionField
            id="length"
            label="Largo"
            value={currentCubbie.length}
            onChange={handleChangeCubbie}
          />

          <CustomDimensionField
            id="width"
            label="Ancho"
            value={currentCubbie.width}
            onChange={handleChangeCubbie}
          />

          <CustomDimensionField
            id="height"
            label="Alto"
            value={currentCubbie.height}
            onChange={handleChangeCubbie}
          />
          
          <Typography variant="body2" color="warning.main">Las dimensiones del cubbie las limita la sección</Typography>

        </Stack>

      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="error" onClick={handleClose}>Calcelar</Button>
        <Button color="success" onClick={handleSave}>Aceptar</Button>
      </DialogActions>

      <ProgressIndicator open={isLoading} />

    </Dialog>
  );
}

export default NewCubbie;
export const getSectionsArea = (sections, aisles) => {
  if (sections.length == 0) return 0;
  
  let area = sections.map(section => (section.largo + aisles )* (section.ancho + aisles) ).reduce((acc, curr) => acc + curr);

  return area;
}

export const getCubbiesVolume = (cubbies) => {
  if (cubbies.length == 0) return 0;

  let volume = 0;

  volume = cubbies.reduce((acc, curr) => acc + (curr.cubbie_largo * curr.cubbie_ancho * curr.cubbie_alto ), 0);

  return volume;
}
import api from './api';
import TokenService from "./token.service";

export const getUsers = () => {
    return api.get("api/Usuario/obtenerUsuarios", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
};

export const getUserByName = async (nomUser) => {
    // try {
    //     const resp = api.get("api/Usuario/usuario/" + nomUser, {
    //         headers: {
    //             Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
    //         }
    //     });
    //     console.log(resp);
    // } catch (error) {
    //     console.log(error);
    // }
    return api.get("api/Usuario/usuario/" + nomUser, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
};

export const postUser = (dataUser) => {
    const res = api.post('api/Usuario', dataUser, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putUser = (dataUser) => {
    const res = api.put('api/Usuario/' + dataUser.usuario, dataUser, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

// ------------------- Contraseña y así -------------------

export const postVerifyPassword = (currentPass) => {
    const res = api.post('api/Usuario/verificarContrasena', currentPass, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
}

export const patchChangePassword = (newPass) => {
    const res = api.patch('api/Usuario/cambio', newPass, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
}

export const getUsersRoles = () => {
    return api.get("api/Rol", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
};
// Material
import { Box, Grid, Typography } from '@mui/material';

import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';
// Components
import CustomAvatar from '@components/CustomAvatar'
import CustomArrowForwardIcon from '@components/CustomArrowForwardIcon';
import { getCubbiesByWarehouse } from '../../../services/cubbie.service';


export const getWarehousesColumns = (showWarehouseFormArea) => {
  // showWarehouseFormArea sirve para ocultar columnas si se esta mostrando el formulario
  const enabledCols = {
    display: showWarehouseFormArea ? { lg: 'none' } : { xxs: 'none', sm: 'revert' }
  }

  return [
    { id: 'id', style: { display: 'none' } },
    { id: 'idShow', label: 'ID', style: { textAlign: 'center' } },
    { id: 'name', label: 'Nombre' },
    { id: 'phone', label: 'Teléfono', style: enabledCols },
    { id: 'status', label: 'Cubbie (disponible)', style: { minWidth: 60, textAlign: 'center', ...enabledCols } },
    { id: 'actions', style: { textAlign: 'center' } },
  ];

}

export const getWarehousesRows = (warehouses) => warehouses.map(warehouse => {

  const { direccion: address } = warehouse;

  // Cada renglon de la tabla debe tener su campo id (el cual estará oculto al usuario)
  // para buscar por id y llenar el formulario (OBLIGATORIO)
  return {
    id: warehouse.id_almacen,
    idShow: <Box sx={{display: 'flex', justifyContent: 'center'}} >
      <Typography variant="h6">{warehouse.id_almacen}</Typography>
    </Box>,
    name: <Grid container columnSpacing={3} wrap="nowrap">

      {/* <Grid item><CustomAvatar name={`${warehouse.id_almacen}`} /></Grid> */}

      <Grid container item direction="column" justifyContent="center" >
        <Grid item>
          <Typography variant="subtitle1" sx={{fontWeight: 500}} >{warehouse.nombre_almacen}</Typography>
        </Grid>
        <Grid item >
          <Typography variant="body2" noWrap >{`${address.ciudad}, ${address.estado}, ${address.pais}`}</Typography>
        </Grid>
      </Grid>

    </Grid>,
    phone: <Grid container wrap="nowrap">
      <PhoneIcon fontSize="small" />
      <Typography variant="body2" ml={0.5}>{warehouse.telefonos}</Typography>
    </Grid>,
    status: warehouse.cubbies_disponibles
      ? <CheckCircleOutlinedIcon color="success" />
      : <CheckCircleOutlinedIcon color="success" />, //<RemoveCircleOutlineOutlinedIcon color="success" />,
    actions: <CustomArrowForwardIcon />
  };
})
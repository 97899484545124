// React and Hooks
import { useEffect, useState } from "react";

// Material
import {
  Box, Button, Collapse, createTheme, CssBaseline, Divider, Drawer, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, ThemeProvider, Tooltip, Typography
} from "@mui/material";
// Icons
import {
  Add,
  AddCircleOutline,
  AddOutlined,
  AddRounded,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  HighlightAlt,
  Menu as MenuIcon,
  MoreVert,
  VerticalAlignBottom
} from "@mui/icons-material";

// Styles
import {
  drawerStyle, sidebarHeaderStyle
} from '../styles/sidebar-styles';

// Assets
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import CubbiesManagmentContext from "../context/CubbiesManagmentContext";
import NewSection from "./NewSection";
import CustomDimensionField from "./CustomDimensionField";
import CustomCubbieIcon from "../../../components/CustomCubbieIcon";
import NewCubbie from "./NewCubbie";
import { deleteSection } from "../../../services/section.service";
import { deleteCubbie } from "../../../services/cubbie.service";
import { useRef } from "react";
import { putWarehouse } from "../../../services/warehouse.service";
import { getSectionsArea } from "../utils/dimensions.utils";
import SwalAlert from "../../../utils/alerts";


const SettingsWarehouseSidebar = () => {

  const theme = useTheme();
  const queryMatch = useMediaQuery(theme.breakpoints.up('sm'));

  const darkTheme = createTheme({
    palette: {
      mode: "dark"
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.palette.bluebar.main
          }
        }
      }
    }
  });

  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const [sectionCollapseList, setSectionCollapseList] = useState({});
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedCubbie, setSelectedCubbie] = useState({})
  const debounceRef = useRef();

  const {
    warehouse,
    dimensions, setDimensions,
    openNewSectionModal, setOpenNewSectionModal,
    openNewCubbieModal, setOpenNewCubbieModal,
    sections, cubbies, aisles,
    setSnackbarProps,
    initGetSections, initGetCubbies
  } = useContext(CubbiesManagmentContext);

  const toggleDrawer = () => setIsOpenSidebar(!isOpenSidebar);

  const handleOnChangeDimensions = (e) => {
    const id = e.target.id;
    const value = Number(e.target.value);

    let updatedDimensions = {
      ...dimensions,
      [id]: value <= 0 ? 1 : value
    }

    setDimensions(updatedDimensions);

    if (debounceRef.current)
      clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(async () => {

      if (!isThereAvailableArea()) return;

      try {

        const warehouseObj = {
          ...warehouse,
          largo: updatedDimensions.length,
          ancho: updatedDimensions.width,
          alto: updatedDimensions.height
        }

        await putWarehouse(warehouseObj);

      } catch (error) {
        return;
      }

    }, 1000);
  }

  const handleClickOpenCollapse = (collapseID) => () => {
    setSectionCollapseList({ ...sectionCollapseList, [collapseID]: !sectionCollapseList[collapseID] })
  }

  const handleClickOpenNewSectionModal = (section) => () => {
    setSelectedSection((sect) => sect = section || {});
    setOpenNewSectionModal(true);
  };

  const handleClickOpenNewCubbieModal = (cubbie) => () => {
    if (!cubbie.hasOwnProperty('id_cubbie')) {
      cubbie = {
        id_seccion: cubbie.id_seccion,
        cubbie_largo: cubbie.largo,
        cubbie_ancho: cubbie.ancho,
      }
    }

    setSelectedCubbie((cubb) => cubb = cubbie);
    setOpenNewCubbieModal(true);
  };

  const handleClickDeleteSection = (section) => async () => {
    console.log(section);

    try {

      await deleteSection(section);

      setSnackbarProps((props) => props = { open: true, message: 'Sección eliminado correctamente', type: 'error' });
      initGetSections(); // Se hace un get de Secciones a la api para actualizar la lista
    } catch (error) {
      console.log(error);
      SwalAlert.errorList(error);
    }
  }

  const handleClickDeleteCubbie = (cubbie) => async () => {
    try {
      await deleteCubbie(cubbie);

      setSnackbarProps({ open: true, message: 'Cubbie eliminada correctamente', type: 'error' });
      initGetCubbies(); // Se hace un get de Secciones a la api para actualizar la lista
    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const updateCollapseList = () => {

    let collapseList = {};
    sections.forEach(section => {
      const collapseID = `${section.id_almacen}-${section.id_seccion}`;

      collapseList[collapseID] = false;
    });

    setSectionCollapseList({ ...sectionCollapseList, ...collapseList });
  }

  const isThereAvailableArea = () => {
    const wareArea = (dimensions.length - aisles) * (dimensions.width - aisles); // Le resto 2 pasillos
    const allSectionsAreas = getSectionsArea(sections, aisles);

    return allSectionsAreas <= wareArea;

  }

  const scaleAdornments = { inputProps: { min: 0.5, max: 10, step: 0.5 } };

  useEffect(() => {
    updateCollapseList();
  }, [sections])

  return (

    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <NewSection open={openNewSectionModal} section={selectedSection} />
      <NewCubbie open={openNewCubbieModal} cubbie={selectedCubbie} />

      <Drawer
        variant="permanent"
        open={isOpenSidebar}
        onClose={toggleDrawer}
        sx={drawerStyle(isOpenSidebar)}
      >
        <Box sx={sidebarHeaderStyle(isOpenSidebar)}>
          <IconButton onClick={toggleDrawer} color="inherit">
            <MenuIcon sx={{ color: 'common.white' }} />
          </IconButton>
        </Box>

        <Box sx={{ px: 2 }}>
          <Typography variant="subtitle1" >Dimensiones del almacén</Typography>
          <Stack spacing={1} sx={{ mt: 1, pl: 2 }}>
            <CustomDimensionField
              id="scale"
              label="Escala"
              value={dimensions.scale}
              adornments={scaleAdornments}
              onChange={handleOnChangeDimensions}
            />
            <CustomDimensionField
              id="length"
              label="Largo"
              value={dimensions.length}
              onChange={handleOnChangeDimensions}
            />
            <CustomDimensionField
              id="width"
              label="Ancho"
              value={dimensions.width}
              onChange={handleOnChangeDimensions}
            />
            <CustomDimensionField
              id="height"
              label="Alto"
              value={dimensions.height}
              onChange={handleOnChangeDimensions}
            />

            {
              !isThereAvailableArea() && <Typography variant="body2" color="warning.main">Una o más secciones exceden las dimensiones del almacén</Typography>
            }
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Typography variant="subtitle1" >Secciones</Typography>
          <Stack>
            <Button variant="text" color="primary" size="small" endIcon={<AddCircleOutline />} onClick={handleClickOpenNewSectionModal()} >Nueva sección</Button>
            <List dense>
              {
                sections.map((section) => {

                  const { id_seccion: sectionID, descripcion: description, id_almacen } = section;
                  const collapseID = `${id_almacen}-${sectionID}`;

                  return (
                    <>
                      <ListItem key={`section-${sectionID}`} disablePadding secondaryAction={
                        <MenuActions
                          onEdit={handleClickOpenNewSectionModal(section)}
                          onDelete={handleClickDeleteSection(section)}
                        />
                      }>
                        <Tooltip title={description}>
                          <ListItemButton sx={{ borderRadius: 2 }} onClick={handleClickOpenCollapse(collapseID)}  >
                            <ListItemIcon sx={{ minWidth: '42px' }}>
                              <HighlightAlt />
                            </ListItemIcon>

                            <ListItemText primary={description} primaryTypographyProps={{ noWrap: true }} />
                            {sectionCollapseList[collapseID] ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>

                      <Collapse in={sectionCollapseList[collapseID]} unmountOnExit sx={{ ml: 2, border: 1, borderColor: 'grey.700', borderRadius: 1 }} >

                        <Stack py={0.5} >
                          <Button variant="text" color="error" size="small" endIcon={<AddCircleOutline />} onClick={handleClickOpenNewCubbieModal(section)} >Nuevo Cubbie</Button>
                          <List disablePadding dense>

                            {
                              cubbies.map(cubbie => cubbie.id_seccion === section.id_seccion &&
                                <ListItem key={`${id_almacen}-${sectionID}-${cubbie.id_cubbie}`} disablePadding secondaryAction={
                                  <MenuActions
                                    onEdit={handleClickOpenNewCubbieModal(cubbie)}
                                    onDelete={handleClickDeleteCubbie(cubbie)}
                                  />
                                }>
                                  <Tooltip title={`${cubbie.cubbie_largo} x ${cubbie.cubbie_ancho} x ${cubbie.cubbie_alto}`}>
                                    <ListItemButton disableRipple sx={{ borderRadius: 2 }}>
                                      <ListItemIcon sx={{ minWidth: '42px' }}>
                                        <CustomCubbieIcon fontSize="small" />
                                      </ListItemIcon>

                                      <ListItemText primary={cubbie.descripcion} primaryTypographyProps={{ noWrap: true }} />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                              )
                            }

                          </List>
                        </Stack>
                      </Collapse>
                    </>
                  )
                })
              }
            </List>
          </Stack>
        </Box>
      </Drawer>

    </ThemeProvider>
  )
}


const MenuActions = ({ onEdit, onDelete }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpen = (e) => setAnchorEl(e.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);


  const handleClickEdit = () => {
    onEdit();
    handleCloseMenu(null);
  }

  const handleClickDelete = () => {
    onDelete();
    handleCloseMenu(null);
  }

  return (
    <div>
      <IconButton size="small" onClick={handleClickOpen} >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <MenuItem dense onClick={handleClickEdit} sx={{ mx: 0.5, borderRadius: 2 }} >
          <ListItemIcon> <Edit color="warning" fontSize="small" /> </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>

        <MenuItem dense onClick={handleClickDelete} sx={{ mx: 0.5, borderRadius: 2 }} >
          <ListItemIcon> <Delete color="error" fontSize="small" /> </ListItemIcon>
          <ListItemText primary="Eliminar" />
        </MenuItem>
      </Menu>
    </div>
  );
}



export default SettingsWarehouseSidebar;
export const cubbiesGridStyle = {
  pb: 1,
  ml: 0,
  border: 1,
  borderColor: 'grey.500',
  borderRadius: 2
}

export const cubbieMenu = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  bgcolor: 'rgba(224, 224, 224, 0.75)',
  borderRadius: 2
}

export const cubbieGridItemStyle = (cubbie, isSelected) =>  ({
  width: 70,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  bgcolor: cubbie.estatus != 'A' ? 'grey.500' : isSelected ? 'success.light' : 'common.white',
  border: 1,
  borderColor: isSelected ? 'success.main' : 'grey.500',
  borderRadius: 2,

})
export const AssignCubbieInterpret = (assCubbie) => assCubbie.map((assCubb) => {

  assCubb = assCubb[0]?.asignacion;

  if (!assCubb) return;
  
  const warehouse = assCubb.almacen;
  const section = assCubb.seccion;
  const cubbie = assCubb.cubbie;
  const assign = assCubb.asignacion;

  const assCubbieObj = {
    seller: {
      sellerId: assign.id_vendedor,
      name: assign.nombre,
      lastName: assign.apellidos,
      completeName: `${assign.nombre} ${assign.apellidos}`
    },
    warehouse: {
      warehouseId: assign.id_almacen,
      uniqueKey: `${assign.id_vendedor}${assign.id_almacen}00`,
      name: warehouse.nombre_almacen,
      phone: warehouse.telefonos,
      length: warehouse.largo,
      width: warehouse.ancho,
      height: warehouse.alto,
      status: warehouse.estatus
    },
    section: {
      sectionId: assign.id_seccion,
      warehouseId: assign.id_almacen,
      uniqueKey: `${assign.id_vendedor}${assign.id_almacen}${assign.id_seccion}0`,
      description: section.descripcion,
      length: section.largo,
      width: section.ancho,
      height: section.alto,
      volume: section.volumen,
      cubbiesTotal: section.total_cubbies,
      availableCubbies: section.cubbies_disponibles,
    },
    cubbie: {
      cubbieId: assign.id_cubbie,
      sectionId: assign.id_seccion,
      warehouseId: assign.id_almacen,
      uniqueKey: `${assign.id_vendedor}${assign.id_almacen}${assign.id_seccion}${assign.id_cubbie}`,
      length: cubbie.cubbie_largo,
      width: cubbie.cubbie_ancho,
      height: cubbie.cubbie_alto,
      totalVolume: cubbie.cubbie_volumen,
      availableVolume: cubbie.cubbie_volumen_disponible,
      status: cubbie.estatus,
      description: cubbie.descripcion
    }
  }

  return assCubbieObj;

}).filter((item) => !!item);




const resp = [
  [
    {
      "asignacion": {
        "asignacion": {
          "id_almacen": 20,
          "id_seccion": 1,
          "id_cubbie": 2,
          "id_vendedor": 14,
          "nombre": "Robert",
          "apellidos": "El Caracol"
        },
        "almacen": {
          "nombre_almacen": "Almacén Culiacán Pariente",
          "telefonos": "6689383356",
          "largo": 102,
          "ancho": 60,
          "alto": 10,
          "estatus": "A"
        },
        "seccion": {
          "descripcion": "Sección 1",
          "alto": 10,
          "largo": 27,
          "ancho": 8,
          "volumen": 2160,
          "total_cubbies": 45,
          "cubbies_disponibles": 42
        },
        "cubbie": {
          "cubbie_largo": 3,
          "cubbie_ancho": 8,
          "cubbie_alto": 2,
          "cubbie_volumen": 48,
          "estatus": "O",
          "descripcion": "CB-2"
        }
      }
    }
  ],
  [
    {
      "asignacion": {
        "asignacion": {
          "id_almacen": 20,
          "id_seccion": 1,
          "id_cubbie": 4,
          "id_vendedor": 14,
          "nombre": "Robert",
          "apellidos": "El Caracol"
        },
        "almacen": {
          "nombre_almacen": "Almacén Culiacán Pariente",
          "telefonos": "6689383356",
          "largo": 102,
          "ancho": 60,
          "alto": 10,
          "estatus": "A"
        },
        "seccion": {
          "descripcion": "Sección 1",
          "alto": 10,
          "largo": 27,
          "ancho": 8,
          "volumen": 2160,
          "total_cubbies": 45,
          "cubbies_disponibles": 42
        },
        "cubbie": {
          "cubbie_largo": 3,
          "cubbie_ancho": 8,
          "cubbie_alto": 2,
          "cubbie_volumen": 48,
          "estatus": "O",
          "descripcion": "CB-4"
        }
      }
    }
  ],
  [
    {
      "asignacion": {
        "asignacion": {
          "id_almacen": 20,
          "id_seccion": 1,
          "id_cubbie": 5,
          "id_vendedor": 14,
          "nombre": "Robert",
          "apellidos": "El Caracol"
        },
        "almacen": {
          "nombre_almacen": "Almacén Culiacán Pariente",
          "telefonos": "6689383356",
          "largo": 102,
          "ancho": 60,
          "alto": 10,
          "estatus": "A"
        },
        "seccion": {
          "descripcion": "Sección 1",
          "alto": 10,
          "largo": 27,
          "ancho": 8,
          "volumen": 2160,
          "total_cubbies": 45,
          "cubbies_disponibles": 42
        },
        "cubbie": {
          "cubbie_largo": 3,
          "cubbie_ancho": 8,
          "cubbie_alto": 2,
          "cubbie_volumen": 48,
          "estatus": "O",
          "descripcion": "CB-5"
        }
      }
    }
  ]
]
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchBar = ({ id, onSearch }) => {

  const debounceRef = useRef();

  const onQueryChanged = (e) => {

    const currentValue = e.target.value;

    if (debounceRef.current)
      clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {

      onSearch(currentValue);

    }, 500);
  }

  return (
    <>
      <TextField
        hiddenLabel
        fullWidth
        id={id}
        variant="outlined"
        placeholder="Buscar"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onQueryChanged}
        sx={{'& fieldset': { borderRadius: 10 } }}
      />
    </>
  )
}

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default SearchBar;
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SellIcon from '@mui/icons-material/Sell';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import imgPerfil from '../assets/img/avatar.jpeg'
import CustomWarehouseIcon from './CustomWarehouseIcon';
import { Inventory2Outlined } from '@mui/icons-material';

import LuuniLogoWhite from '../assets/img/Logo_luuni-blanco.png';

const Sidebar = () => {

    let location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const [anchorEl, setAnchorEl] = React.useState(true);
    const show = Boolean(anchorEl);
    const [setAnchorElMovil] = React.useState(null);

    const showMenu = () => {
        setAnchorEl(!show);
    };

    const handleClickMenuMovil = (event) => {
        setAnchorElMovil(event.currentTarget);
    };

    function handleClose(nav) {
        navigate(nav);
    }

    return (
        <aside
            className={`bg-bluebar h-screen hidden md:block ${show ? 'lg:w-1/5 md:w-2/5' : 'sm:w-20'} overflow-y-scroll overflow-x-hidden transition-all duration-100 ease-in-out`}
        >
            <div className="h-20 flex items-center justify-between md:p-5 sm:px-3">
                <img src={LuuniLogoWhite} className={`h-12 ${!show ? 'hidden' : 'sm:block hidden'}`} alt="logo" />
                <IconButton
                    aria-label="menutoggle"
                    className="text-white hover:bg-gray-800"
                    onClick={window.innerWidth > 640 ? showMenu : handleClickMenuMovil}
                >
                    <MenuIcon />
                </IconButton>
            </div>
            <div className="p-5 hidden sm:block">
                <div className="flex flex-col justify-center items-center">
                    <Avatar
                        alt={localStorage.user}
                        src={imgPerfil}
                        className={`${!show ? 'w-12 h-12' : 'lg:w-40 lg:h-40 sm:w-20 sm:h-20'}`}
                    />
                    <div className={`text-white py-3 text-center ${!show ? 'hidden' : 'visible'}`}>
                        <p className="font-600 text-base">{localStorage.user}</p>
                        <p className="text-sm font-light">{localStorage.rol}</p>
                    </div>
                </div>
                {/* sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }} 
                sx={{ width: '100%', maxWidth: 360 }}*/}
                <div className="py-10">
                    <List
                        className="bg-transparent text-white font-sans "
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                                className={`bg-transparent text-orangepkt1 font-sans text-base uppercase ${!show ? 'px-1' : 'px-4'} `}
                            >
                                <ListItemIcon className={`text-orangepkt1 ${show ? 'hidden' : 'visible'}`}>
                                    <AdminPanelSettingsOutlinedIcon sx={{ fontSize: 34 }} />
                                </ListItemIcon>
                                <ListItemText primary="DASHBOARDS" disableTypography className={`${!show ? 'hidden' : 'visible'}`} />
                            </ListSubheader>
                        }
                    >
                        <ListItemButton className="btnNav" onClick={() => { handleClose("/analytic") }} selected={location.pathname === "/analytic"}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <DataUsageOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inicio" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`} />
                        </ListItemButton>
                    </List>
                    <List
                        className="bg-transparent text-white font-sans "
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                                className={`bg-transparent text-orangepkt1 font-sans text-base uppercase ${!show ? 'px-1' : 'px-4'} `}
                            >
                                <ListItemIcon className={`text-orangepkt1 ${show ? 'hidden' : 'visible'}`}>
                                    <AdminPanelSettingsOutlinedIcon sx={{ fontSize: 34 }} />
                                </ListItemIcon>
                                <ListItemText primary="CATÁLOGOS" disableTypography className={`${!show ? 'hidden' : 'visible'}`} />
                            </ListSubheader>
                        }
                    >
                        <ListItemButton className="btnNav" onClick={() => { handleClose("/users") }} selected={location.pathname === "/users"} sx={{ pl: 4 }}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <GroupOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`} />
                        </ListItemButton>
                        <ListItemButton className="btnNav" onClick={() => { handleClose("/sellers") }} selected={location.pathname === "/sellers"}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <SellIcon />
                            </ListItemIcon>
                            <ListItemText primary="Vendedores" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`} />
                        </ListItemButton >

                        <ListItemButton className="btnNav" onClick={() => { handleClose("/receipts") }} selected={location.pathname === "/receipts"}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <Inventory2Outlined />
                            </ListItemIcon>
                            <ListItemText primary="Entradas" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`} />
                        </ListItemButton>

                        <ListItemButton className="btnNav" onClick={() => { handleClose("/warehouse") }} selected={location.pathname === "/warehouse"}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <CustomWarehouseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Almacenes" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`} />
                        </ListItemButton>

                        {/*
                        <ListItemButton className="btnNav" onClick={() => {handleClose("/")}} selected={location.pathname === "/cat_entradas"}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <MoveToInboxOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Entradas" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`}/>
                        </ListItemButton>
                        <ListItemButton className="btnNav" onClick={handleClick}>
                            <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}>
                                <GroupOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`}/>
                            {open ? <KeyboardArrowDownOutlinedIcon className={`${!show ? 'hidden' : 'visible'}`} /> : <KeyboardArrowRightOutlinedIcon className={`${!show ? 'hidden' : 'visible'}`} />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton className="btnNav" onClick={() => {handleClose("/cat_usuarios")}} selected={location.pathname === "/cat_usuarios"} sx={{ pl: 4 }}>
                                    <ListItemIcon className={`text-white ${!show ? 'justify-content px-2' : 'justify-center'}`}></ListItemIcon>
                                <ListItemText primary="Catálogo" disableTypography className={`text-sm ${!show ? 'hidden' : 'visible'}`}/>
                                </ListItemButton>
                            </List>
                        </Collapse> */}
                    </List>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
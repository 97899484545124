import { Alert, Grid, Typography } from '@mui/material'
import React from 'react'
import IconLabel from '../../../components/IconLabel';

const CubbieInfo = ({title= '', warehouseName = '', sectionName = '', cubbieName = '', available = '', color = 'info'}) => {

  return (
    <Alert
      severity={color}
      sx={{ borderRadius: 3 }}
    >
      <Grid container justifyContent="space-between" alignItems="center" columnSpacing={3} rowSpacing={1} >

        <Grid item xxs={12} >
          <Typography variant="body1" fontWeight={500}>{title}</Typography>
        </Grid>

        <Grid item xxs={12} sm={4}>
          <IconLabel boldLabel="Almacén:" label={warehouseName} />
        </Grid>

        <Grid item xxs={6} sm={3}>
          <IconLabel boldLabel="Sección:" label={sectionName} />
        </Grid>

        <Grid item xxs={6} sm={2}>
          <IconLabel boldLabel="Cubbie:" label={cubbieName} />
        </Grid>

        <Grid item xxs={12} sm={3}>
          <IconLabel boldLabel="Disponible:" label={available} />
        </Grid>
      </Grid>
    </Alert>
  )
}

export default CubbieInfo;

export const generateId = () => {
  const date = Date.now().toString(30);
  const random = Math.random().toString(20).substring(2);

  return date + random;
}

export const stringAvatar = (name) => {

  let newName;

  if (name.includes(' ')) {
    newName = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  } else {
    newName = `${name.substring(0, 2)}`;
  }

  return newName.toUpperCase();
}

export const encodeBase64 = (file) => {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

}

export const decodeBase64 = (data) => {
  const bin = atob(data.substring('data:application/pdf;base64,'.length));
  let created = bin.match(/CreationDate \(D:([0-9]+-[0-9]+'[0-9]+)\)/);

  if (created) {
    created = created[1].split('-')[0];
    created = `${created.substring(0, 4)}-${created.substring(4, 6)}-${created.substring(6, 8)}T${created.substring(8, 10)}:${created.substring(10, 12)}:${created.substring(12, 14)}Z`
  }


  return {
    size: `${Math.round(bin.length / 1024)} KB`,
    createdDate: created ? new Date(created) : ''
  }
  // console.log('File Size:', Math.round(bin.length / 1024), 'KB'); 20221007112451
  // console.log('PDF Version:', bin.match(/^.PDF-([0-9.]+)/)[1]);
  // console.log('Create Date:', bin.match(/CreationDate \(D:([0-9]+-[0-9]+'[0-9]+)\)/)[1]);
  // return {
  //   size: `${Math.round(bin.length / 1024)} KB`,
  //   createdDate: new Date(created.split('-')[0])
  // }
}

export const randomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgba(${r},${g},${b}, 1)`;
}


export const getUniqueObjectsFromArr = (arr) => {

  const keys = arr.map(item => JSON.stringify(item));
  const set = new Set(keys);
  const results = [...set].map(item => JSON.parse(item));

  // console.log(results);
  return results;
}

export const isEmpty = (value) => {

  if (!value) return !value;

  if (typeof value === 'string') return !value;

  if (typeof value === 'number') return value <= 0;

  if (typeof value === 'boolean') return value;

  if (typeof value === 'object' && !!value.length) return value.length == 0;

  if (typeof value === 'object' && !value.length) return Object.keys(value).length == 0;

}
// React and Hooks
import { createContext, useEffect, useState } from "react";

// Services
import { getSellerByName, getSellers } from "@services/seller.service"

// Utils
import { getSellersColumns, getSellersRows } from "../utils/table.utils";

const SellersPageContext = createContext();

export const SellersPageProvider = ({children}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [showSellerForm, setShowSellerForm] = useState(false);
  const [showSellerFormArea, setShowSellerFormArea] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState({});
  const [contract, setContract] = useState({});

  const [openContractDetails, setOpenContractDetails] = useState(false);
  const [openAssignCubbiesModal, setOpenAssignCubbiesModal] = useState(false);
  

  const handleSearch = async (seller = '') => {
    setIsLoading(true);

    let sellerResp;

    try {
      
      if(seller == '') {
        sellerResp = await getSellers();
        sellerResp = sellerResp.data;
      } else {
        sellerResp = await getSellerByName(seller);

        //Lo convierto a array de objetos para que tenga el mismo trato
        sellerResp = [ sellerResp.data ];
      }
    
      setSellers(sellerResp);

    } catch (error) {
      setSellers([]);
    }

    setIsLoading(false);
    
  }

  const handleSelectedRow = (idRowSeller) => {
    const selectedSellerRow = sellers.filter( seller => seller.id_vendedor === idRowSeller )[0];
    setSelectedSeller(selectedSellerRow);
    if(!showSellerFormArea){
      setShowSellerFormArea(true);
    }
    setShowSellerForm(false);
    setTimeout(() => {
      setShowSellerForm(true);
    }, 100);
  }

  const handleClickNewSeller = () => {
    if(!showSellerFormArea){
      setShowSellerFormArea(true);
    }
    
    setShowSellerForm(false);
    setTimeout(() => {
      setShowSellerForm(true);
    }, 100);
  }

  const handleShowSellerForm = (show) => {
    setSelectedSeller({});
    setShowSellerFormArea(show);
    setShowSellerForm(show);
  }

  const handleOpenContractDetails = () => setOpenContractDetails(true);
  const handleCloseContractDetails = () => setOpenContractDetails(false);

  const handleOpenAssignCubbiesModal = () => setOpenAssignCubbiesModal(true);
  const handleCloseAssignCubbiesModal = () => setOpenAssignCubbiesModal(false);

  

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <SellersPageContext.Provider value={{
      isLoading,
      sellers,
      contract, setContract,
      handleSearch,
      getSellersColumns,
      getSellersRows,
      handleSelectedRow,
      showSellerForm,
      showSellerFormArea,
      selectedSeller,
      handleShowSellerForm,
      handleClickNewSeller,
      openContractDetails,
      handleOpenContractDetails,
      handleCloseContractDetails,
      openAssignCubbiesModal,
      handleOpenAssignCubbiesModal,
      handleCloseAssignCubbiesModal
    }}>
      {children}
    </SellersPageContext.Provider>
  )
}

export default SellersPageContext;

import { Close } from '@mui/icons-material';
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, Grid, IconButton, MenuItem, Stack, TextField, Typography, Zoom } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from '../../../utils/utils';
import ReceiptsPageContext from '../context/ReceiptsContext';

const AssignNewReceiptDialog = () => {

  const {
    openNewReceipt, handleToggleNewReceipt,
    showInputs, handleToggleShowInputs, setShowInputs,

    selectedSeller, handleChangeSelectedSeller,
    selectedWarehouse, handleChangeSelectedWarehouse,
    selectedSection, handleChangeSection,
    selectedCubbie, handleChangeSelectedCubbie,
    handleResetFields,
    handleClickContinue,
    handleClickAssignAuto,
    
    getAllWarehouses,
    sellers, warehouses, sections, cubbies
  } = useContext(ReceiptsPageContext)

  

  const handleClose = () => {
    handleToggleNewReceipt();
    setShowInputs(false);
    handleResetFields();
  }
  
  useEffect(() => {
    if (openNewReceipt)
      getAllWarehouses();
  }, [openNewReceipt])

  return (
    <Dialog
      open={openNewReceipt}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ sx: { minWidth: { sm: 600 } } }}
    >
      <DialogTitle sx={{ pt: 3, pl: 5, fontWeight: 500, fontSize: { xxs: 18, sm: 20 } }}>Asignación de la nueva entrada</DialogTitle>

      <DialogContent>
        <Grid container spacing={3} mt={0} alignItems="stretch" justifyContent="center">
          <Grid item xxs={12} sm={6}>
            <Button
              color="bluepkt1"
              variant={showInputs ? 'contained' : 'outlined'}
              fullWidth
              onClick={handleToggleShowInputs}
            >
              Seleccionar Cubbie
            </Button>
          </Grid>

          <Grid item xxs={12} sm={6}>
            <Button
              color="bluepkt1"
              fullWidth
              disabled={showInputs || !selectedSeller}
              onClick={handleClickAssignAuto}
            >
              Asignar automáticamente
            </Button>
          </Grid>

          <Grid item xxs={12} sm={6}>
            <TextField
              id="seller_id"
              label="Vendedor"
              select
              fullWidth
              value={selectedSeller?.id_vendedor || ''}
              onChange={handleChangeSelectedSeller}
            >
              {
                sellers.map(sell => <MenuItem key={sell.id_vendedor} value={sell.id_vendedor}>
                  {`${sell.nombre} ${sell.apellidos}`}
                </MenuItem>)
              }
            </TextField>
          </Grid>

          <Grid item xxs={12} sm={6}>
            <TextField
              id="warehouse"
              label="Almacén"
              select
              fullWidth
              disabled={isEmpty(selectedSeller) || warehouses?.length == 1}
              value={selectedWarehouse?.warehouseId || ''}
              onChange={handleChangeSelectedWarehouse}
            >
              {
                warehouses.map(ware => <MenuItem key={ware.uniqueKey} value={ware.warehouseId}>
                  {ware.name}
                </MenuItem>)
              }
            </TextField>
          </Grid>

          <Zoom in={showInputs} style={{ transitionDelay: showInputs ? '150ms' : '0ms' }} unmountOnExit >
            <Grid item xxs={12} sm={6}>
              <TextField
                id="section"
                label="Sección"
                select
                fullWidth
                disabled={sections?.length <= 1}
                value={selectedSection?.sectionId || ''}
                onChange={handleChangeSection}
              >
                {
                  sections.map(sect => <MenuItem key={sect.uniqueKey} value={sect.sectionId}>
                    {sect.description}
                  </MenuItem>)
                }
              </TextField>
            </Grid>
          </Zoom>

          <Zoom in={showInputs} style={{ transitionDelay: showInputs ? '150ms' : '0ms' }} unmountOnExit >
            <Grid item xxs={12} sm={6}>
              <TextField
                id="cubbie"
                label="Cubbie"
                select
                fullWidth
                disabled={cubbies?.length <= 1}
                value={selectedCubbie?.cubbieId || ''}
                onChange={handleChangeSelectedCubbie}
              >
                {
                  cubbies.map(cubb => <MenuItem key={cubb.uniqueKey} value={cubb.cubbieId}>
                    {cubb.description}
                  </MenuItem>)
                }
              </TextField>
            </Grid>
          </Zoom>
        </Grid>

        <Collapse in={cubbies?.length == 0 && !!selectedSeller} unmountOnExit>
          <Alert severity="warning" sx={{ mt: 2, borderRadius: 3, borderLeft: 4, borderColor: 'warning.light' }}>El vendedor no tiene cubbies reservados, se asignará uno automáticamente</Alert>
        </Collapse>

      </DialogContent>

      <Divider />

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          color="customGrey"
          sx={{ borderRadius: 10 }}
          onClick={handleClose}
        >
          Cancelar
        </Button>

        <Zoom in={showInputs} style={{ transitionDelay: showInputs ? '150ms' : '0ms' }} unmountOnExit >
          <Button
            variant="contained"
            color="orangepkt1"
            disabled={!selectedSeller || !selectedWarehouse || !selectedSection || !selectedCubbie}
            sx={{ borderRadius: 10 }}
            onClick={handleClickContinue}
          >
            Continuar
          </Button>
        </Zoom>

      </DialogActions>
    </Dialog>
  )
}

export default AssignNewReceiptDialog;
// React and Hooks
import React, { useContext, useState } from 'react'

// Material and Icons
import { Box, Button, Fade, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CheckCircleOutline, HighlightOff, Search } from '@mui/icons-material'

// Styles
import { cubbieGridItemStyle, cubbieMenu, cubbiesGridStyle } from '../styles/cubbies-grid-styles'
import AssignCubbiesContext from '../context/AssignCubbiesContext'

const CubbiesGrid = ({ cubbies }) => {

  return (
    <Grid container item justifyContent="center" spacing={1} sx={cubbiesGridStyle}>
      {
        cubbies.map(cubb => <Grid key={cubb.id_cubbie} item>
          <CubbieGridItem cubbie={cubb} />
        </Grid>
        )
      }
    </Grid>
  )
}


const CubbieGridItem = ({ cubbie }) => {

  const [showMenu, setShowMenu] = useState(false);

  const {
    handleSelectCubbie,
    isCubbieSelected,
    handleOpenAssignCubbiesDetails,
    handleSelectedCubbieDetails
  } = useContext(AssignCubbiesContext)

  const handleMouseEnter = () => setShowMenu(true);
  const handleMouseLeave = () => setShowMenu(false);

  const onOpenCubbieDetails = () => {
    handleSelectedCubbieDetails(cubbie);
    handleOpenAssignCubbiesDetails();
  }

  return (
    <Box
      sx={cubbieGridItemStyle(cubbie, isCubbieSelected(cubbie))}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

      <Typography variant="body1" color="bluepkt1.main">{`Cb-${cubbie.id_cubbie}`}</Typography>

      {
        cubbie?.estatus == 'A' && <Fade in={showMenu} >
          <Stack direction="row" alignItems="center" justifyContent="space-evenly" sx={cubbieMenu}>

            <IconButton
              size="small"
              onClick={onOpenCubbieDetails}
            > <Search color="bluepkt1" />
            </IconButton>

            <IconButton
              size="small"
              onClick={handleSelectCubbie(cubbie)}
            >
              {
                isCubbieSelected(cubbie)
                  ? <HighlightOff color="error" />
                  : <CheckCircleOutline color="success" />
              }
            </IconButton>

          </Stack>
        </Fade>
      }

    </Box>
  )
}

export default CubbiesGrid;
import api from './api';
import TokenService from "./token.service";

export const getWarehouses = () => {
    return api.get("api/Almacen", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            pagina: 1,
            PaginaTam: 100,
        }
    });
};

export const getWarehouseByName = (nameWarehouse) => {
    return api.get("api/Almacen/almacen/"+nameWarehouse, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
};

export const postWarehouse = (warehouseData) => {
    const res = api.post('api/Almacen', warehouseData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putWarehouse = (warehouseData) => {
    const res = api.put('api/Almacen/'+warehouseData.id_almacen, warehouseData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const deleteWarehouse = (warehouseData) => {
    const res = api.delete('api/Almacen', {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            id: warehouseData.id_almacen
        }
    });
    return res;
};
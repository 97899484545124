import { Add } from '@mui/icons-material'
import { Box, Button, Stack, TextField } from '@mui/material'
import React, { useRef, useState } from 'react'

const MassiveLoad = () => {

  const inpFileRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState();

  const handleChangeFile = (e) => {
    const file = e.target.files[0];

    const fileObj = {
      name: file.name,
      type: file.type,
      size: (file.size / 1024).toFixed(2),
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate.toLocaleString(),
    }

    setSelectedFile(fileObj);
  }

  const handleClickInput = () => {
    inpFileRef.current.click();
  }

  return (
    <Box>
      <Stack direction="row">
        <Button
          color="bluepkt1"
          startIcon={<Add />}
          component="label"
          className="rounded-r-none"
          ref={inpFileRef}
        >
          Cargar archivo
          <input type="file" accept=".xlsx, .xls, .csv"  onChange={handleChangeFile} hidden />
        </Button>
        <TextField
          value={selectedFile ? `${selectedFile.name}, ${selectedFile.size} KB.` : undefined}
          size="small"
          placeholder="Seleccionar archivo (.xls ó .csv)"
          InputProps={{ className: 'rounded-l-none', readOnly: true }}
          sx={{ minWidth: 350 }}
          onClick={handleClickInput}
        />
      </Stack>
    </Box>
  )
}

export default MassiveLoad
// React and Hooks
import React from 'react';

// Components
import Layout from "@components/Layout";
import { CardPackagesState, CardGeneralPackages } from './components';

// Material
import { Typography } from '@mui/material';

// Provider / Context
import { AnalyticProvider } from './context/AnalyticContext';


const AnalyticPage = () => {

  return (
    <AnalyticProvider>
      <AnalyticWithContext />
    </AnalyticProvider>
  );
}

const AnalyticWithContext = () => {
  
  return (
    <Layout>
      <div className="px-5 md:px-14 h-screen overflow-auto">
        <div className="grid grid-cols-1">
          <Typography variant="h4" className="text-gray-800 font-medium" >Panel de análisis</Typography>
          <Typography variant="h6" className="text-gray-800 font-normal" >Supervise las métricas, compruebe los informes y revise el rendimiento</Typography>
        </div>

        <div className="grid grid-cols-1">
          <CardGeneralPackages />
        </div>

        <div className="mb-10 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">

          <CardPackagesState title="En sitio" chartColor="#ffc658" state={1} />

          <CardPackagesState title="En transito" chartColor="#8884d8" state={2} />

          <CardPackagesState title="Entregado" chartColor="#82ca9d" state={3} />

        </div>
      </div>
    </Layout>
  );
}

export default AnalyticPage;
import React, { useContext, useState } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
// Icons
import {
  FileUploadOutlined as FileUploadOutlinedIcon
} from '@mui/icons-material';

// Components
import ProgressIndicator from '@components/ProgressIndicator';

// DatePickers
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Services
import { postContract } from '@services/contract.service';

// Utils
import { encodeBase64, decodeBase64 } from '@utils/utils';
import SwalAlert from '@utils/alerts';
import pdfDownload from '@assets/img/pdf-download.png';
import Base64Downloader from 'common-base64-downloader-react';
import SellersPageContext from '../context/SellersContext';
import Image from '../../../components/Image';


const ContractDetails = ({ open = true, onClose, contract = {} }) => {

  const currentDate = new Date();
  const isNewContract = contract.hasOwnProperty('id_contrato') ? false : true;

  const [isLoading, setIsLoading] = useState(false);
  const [contractStatus, setContractStatus] = useState(isNewContract ? 'A' : contract.estatus);
  const [description, setDescription] = useState(isNewContract ? '' : contract.descripcion);
  const [startDate, setStartDate] = useState(isNewContract ? currentDate : contract.fecha_aplicacion);
  const [endDate, setEndDate] = useState(isNewContract ? currentDate : contract.fecha_finalizacion);
  const [contractFile, setContractFile] = useState(isNewContract ? {} : decodeBase64(contract.formato));
  const [errorMessage, setErrorMessage] = useState('');

  const { handleShowSellerForm } = useContext(SellersPageContext);


  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleChangeContractStatus = (e) => {
    setContractStatus(e.target.value);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const handleChangeEndDate = (value) => {
    setEndDate(value);
  }

  const handleFileChange = async (e) => {

    const selectedFile = e.target.files[0];

    try {

      const fileBase64 = await encodeBase64(selectedFile);

      const fileC = {
        name: selectedFile.name,
        type: selectedFile.type,
        size: parseInt(selectedFile.size / 1024),
        lastModified: selectedFile.lastModified,
        lastModifiedDate: selectedFile.lastModifiedDate.toLocaleString(),
        urlFile: fileBase64,
      }

      setContractFile(fileC);

    } catch (error) {
      setErrorMessage(`Error: ${error}`)
    }
  }

  const handleReset = () => {
    setContractStatus('A');
    setDescription('');
    setStartDate(currentDate);
    setEndDate(currentDate);
    setContractFile({});
    onClose();
  }

  const handleSubmit = async () => {

    if (Date.parse(endDate) <= Date.parse(startDate)) {
      setErrorMessage('La fecha de finalización debe ser mayor a la de inicio');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
      return;
    }

    if (Object.keys(contractFile).length == 0) {
      setErrorMessage('Aún no se ha cargado el contrato');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
      return;
    }

    setIsLoading(true);

    const contractData = {
      id_vendedor: contract.id_vendedor,
      descripcion: description,
      formato: contractFile.urlFile,
      fecha_aplicacion: startDate.toISOString(),
      fecha_finalizacion: endDate.toISOString(),
      estatus: contractStatus
    }
    // console.log(contractFile.urlFile);

    try {

      await postContract(contractData);

      SwalAlert.success('Contrato guardado correctamente');
      handleReset();

    } catch (error) {
      SwalAlert.errorList(error);
    }

    setIsLoading(false);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: 20 } }}
    >
      <DialogTitle>Detalle del contrato</DialogTitle>

      <DialogContent>
        {
          !isNewContract
            ? contract.estatus == 'PV' && <Alert severity="warning">El contrato esta por vencer</Alert>
            : contract.estatus == 'F' && <Alert severity="success">El contrato ha finalizador</Alert>
        }
        <Grid container spacing={3} alignContent="right">

          <Grid item xxs={12} sx={{ textAlign: 'left' }}>

            <FormControl sx={{ mt: 1, minWidth: 200 }} size="small">
              <InputLabel >Estatus</InputLabel>
              <Select
                value={contractStatus}
                label="Estatus"
                onChange={handleChangeContractStatus}
              >
                <MenuItem value="A">Aprobado</MenuItem>
                {!isNewContract && <MenuItem value="C">Cancelado</MenuItem>}

              </Select>
            </FormControl>
          </Grid>

          <Grid item xxs={12}>
            <TextField
              label="Descripción"
              placeholder="Comentarios adicionales al contrato (opcional)"
              variant="outlined"
              type="text"
              fullWidth
              autoFocus={true}
              value={description}
              onChange={handleChangeDescription}
              InputProps={{ readOnly: !isNewContract }}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Fecha de Inicio"
                openTo="year"
                inputFormat="dd/MM/yyyy"
                views={['year', 'month', 'day']}
                disabled={!isNewContract}
                value={startDate}
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xxs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Fecha de Finalización"
                openTo="year"
                inputFormat="dd/MM/yyyy"
                views={['year', 'month', 'day']}
                disabled={!isNewContract}
                minDate={currentDate}
                value={endDate}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xxs={12}>
            <Card sx={{ width: '100%', boxShadow: 4, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="body1" sx={{ color: 'grey.600' }}>Información</Typography>

                {
                  isNewContract && <Grid container justifyContent="right" sx={{ my: { xxs: 2, sm: 0 } }}>
                    <Grid item xxs={12} sm={3}>
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadOutlinedIcon />}
                        color="bluepkt1"
                        fullWidth
                      >
                        Contrato
                        <input hidden accept="application/pdf" type="file" onChange={handleFileChange} />
                      </Button>
                    </Grid>
                  </Grid>
                }
                {
                  isNewContract
                    ? <>
                      <Typography variant="body1" sx={{ color: 'grey.800' }}>{contractFile.name}</Typography>
                      <Typography variant="body1" sx={{ color: 'grey.500' }}>Tamaño: {Object.keys(contractFile).length == 0 ? '' : `${contractFile.size} KB`}</Typography>
                      <Typography variant="body1" sx={{ color: 'grey.500' }}>Fecha de última modificación: {Object.keys(contractFile).length == 0 ? '' : contractFile.lastModifiedDate}</Typography>
                    </>
                    : <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                      <Stack>
                        <Typography variant="body1" sx={{ color: 'grey.500' }}>Tamaño: {contractFile.size}</Typography>
                        <Typography variant="body1" sx={{ color: 'grey.500' }}>Fecha de creación: {contractFile.createdDate.toLocaleString()}</Typography>
                      </Stack>
                      <Base64Downloader base64={contract.formato} downloadName="pdfContrato">
                      <Tooltip title="Descargar" arrow>
                        <Box>
                          <Image src={pdfDownload} alt="pdf download" width={50} height={50} />
                        </Box>
                      </Tooltip>
                      </Base64Downloader>

                    </Box>
                }


              </CardContent>
            </Card>
          </Grid>

        </Grid>

        <Collapse in={errorMessage != '' ? true : false} unmountOnExit><Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert></Collapse>

      </DialogContent>

      <DialogActions>
        {
          !isNewContract
            ? <Button onClick={handleReset} color="orangepkt1" >Cerrar</Button>
            : <>
              <Button onClick={handleReset} color="bluepkt1" >Cancelar</Button>
              <Button onClick={handleSubmit} color="orangepkt1">Guardar</Button>
            </>
        }
      </DialogActions>

      <ProgressIndicator open={isLoading} />
    </Dialog>
  )
}

export default ContractDetails
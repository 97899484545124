import { AddTask, ArrowBackIosNew, LibraryBooks } from '@mui/icons-material'
import { Box, Button, ButtonBase, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout'
import ReceiptsPageContext from '../context/ReceiptsContext'
import CancelConfirmDialog from './CancelConfirmDialog'
import MassiveLoad from './MassiveLoad'
import ModerateLoad from './ModerateLoad'
import { isEmpty } from '../../../utils/utils'

const NewReceiptsPage = () => {

  const navigate = useNavigate();

  const {
    handleToggleCancel,
    selectedSeller,
    indexTab, setIndexTab
  } = useContext(ReceiptsPageContext)

  const handleChangeIndexTab = (event, newValue) => {
    setIndexTab(newValue);
  };

  useEffect(() => {

    if (isEmpty(selectedSeller)) 
      navigate('/receipts', {replace: true});
    
  }, [])
  

  return (
    <Layout>
      <Box p={3} sx={{ bgcolor: blueGrey[50]}} className="h-screen rounded-t-xl rounded-b-md">

        <Grid container spacing={1} alignItems="center">

          <Grid item xxs={12} sm={6} >
            <ReceiptInfo title="Nueva entrada" sellerId={selectedSeller?.id_vendedor} sellerName={`${selectedSeller?.nombre} ${selectedSeller?.apellidos}`}/>
          </Grid>

          <Grid item xxs={12} sm={6}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button startIcon={<AddTask />} color="orangepkt1" className="rounded-full" >Guardar</Button>
              <Button color="customGrey" className="rounded-full" onClick={handleToggleCancel} >Cancelar</Button>
            </Stack>
          </Grid>

          <Grid item xxs={12}>
            <ProductTabs value={indexTab} onChange={handleChangeIndexTab}>

              <TabPanel value={indexTab} index={0}>
                <ModerateLoad />
              </TabPanel>

              <TabPanel value={indexTab} index={1}>
                <MassiveLoad />
              </TabPanel>

            </ProductTabs>
          </Grid>
        </Grid>
        <CancelConfirmDialog />
      </Box>
    </Layout>
  )
}


const ReceiptInfo = ({ title = '', sellerId = '', sellerName = '' }) => {

  return (
    <Box className="flex justify-center">
      <Stack direction="row" spacing={1}>
        <LibraryBooks sx={{ fontSize: 60, color: 'grey.700' }} />
        <Stack>
          <Typography variant="h6" fontWeight={500} >{title}</Typography>
          <Stack direction="row" spacing={2}>
            <Typography variant="body2" >{`ID: ${sellerId}`}</Typography>
            <Typography variant="body2" >{`VENDEDOR: ${sellerName}`}</Typography>
          </Stack>
        </Stack>
      </Stack>

    </Box>

  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ProductTabs = ({ value, onChange, children }) => {

  return (
    <Box className="bg-white rounded-t-[30px]" >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={onChange} centered >
          <Tab label="Carga moderada" id="tab-moderate-load" />
          <Tab label="Carga masiva" id="tab-masive-load" />
        </Tabs>
      </Box>
      {children}
    </Box>
  )
}

export default NewReceiptsPage
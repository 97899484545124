// React
import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";

// Material
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Pages
import LoginPage from './pages/Login/LoginPage';
import AnalyticPage from './pages/Analytic/AnalyticPage';
import UsersPage from './pages/Users/UsersPage';
import SellersPage from './pages/Sellers/SellersPage';
import WarehousesPage from './pages/Warehouse/WarehousesPage';

import Tests from './pages/Tests/Tests';
import ReceiptsPage from './pages/Receipts/ReceiptsPage';
import { grey, teal } from '@mui/material/colors';
import NewReceiptsPage from './pages/Receipts/components/NewReceiptsPage';
import { Zoom } from '@mui/material';
import { ReceiptsPageProvider } from './pages/Receipts/context/ReceiptsContext';

const ZoomTransition = React.forwardRef(function Transition(props, ref) {
  return <Zoom
    ref={ref}
    {...props}
    style={{
      transitionTimingFunction: 'cubic-bezier(.47,1.64,.41,.8)',
    }} />;
});

let theme = createTheme({
  typography: {
    "fontFamily": `"Poppins", sans-serif`,
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 475,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  palette: {

    orangepkt1: {
      main: '#ff3900',
      light: '#ff5f00',
      dark: '#d62e00',
      contrastText: '#fff',
    },
    bluepkt1: {
      main: '#151B4E',
      light: '#1d256e',
      dark: '#0f1336',
      contrastText: '#e6e6e6',
    },
    greenpkt1: {
      main: '#16A34A',
      light: '#16b44a',
      dark: '#169b4a',
      contrastText: '#000',
    },
    lightBluepkt1: {
      main: '#0284C7',
      light: '#0284d7',
      dark: '#0081c2',
      contrastText: '#000',
    },

    white: {
      main: '#fff',
      light: grey[100],
      dark: grey[200],
      contrastText: grey[900],
    },
    customGrey: {
      main: grey[300],
      light: grey[200],
      dark: grey[400],
      contrastText: grey[900],
    },
    bluedash: {
      main: '#1e293b',
      contrastText: '#fff',
    },
    bluebar: {
      main: '#111827'
    },

  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          borderRadius: 10,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          minWidth: 'max-content'
        }
      }
    },
    MuiFab: {
      variants: [
        {

          props: { variant: 'circular', size: 'smallest' },
          style: {
            width: '30px',
            height: '30px',
            minHeight: '26px'
          }
        }
      ],
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({
          boxShadow: 3,
          "&:active, &:hover": {
            boxShadow: 5
          }
        }),


      }
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: ZoomTransition
      },
      styleOverrides: {
        paper: { borderRadius: 20, transition: 'all .2s ease-in-out' }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: theme.transitions.create('all', { easing: theme.transitions.easing.easeInOut, duration: theme.transitions.duration.shorter })
        })
      }
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter >
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/analytic" element={<AnalyticPage />} />
          <Route exact path="/users" element={<UsersPage />} />
          <Route exact path="/sellers" element={<SellersPage />} />
          <Route exact path="/warehouse" element={<WarehousesPage />} />
          <Route exact path="/receipts" element={<ReceiptsPageProvider> <ReceiptsPage /> </ReceiptsPageProvider>} />
          <Route exact path="/receipts/new" element={<ReceiptsPageProvider> <NewReceiptsPage /> </ReceiptsPageProvider>} />

          <Route exact path="/tests" element={<Tests />} />
        </Routes>
      </HashRouter >
    </ThemeProvider>
  );
}

export default App;

import { Box } from "@mui/material"

const Image = ({ src, alt, boxFit = 'cover', width = 50, height = 50, sx = {} }) => {

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: boxFit,
    ...sx
  };

  const boxImageStyle = {
    width,
    height
  }

  return (
    <Box sx={boxImageStyle}>
      <img src={src} alt={alt} style={imgStyle} />
    </Box>
  )
}
export default Image
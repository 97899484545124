import { Add, PlusOne } from '@mui/icons-material';
import { Box, Button, Divider, Fab, IconButton, Stack } from '@mui/material';
import React from 'react'
import { useContext } from 'react';
import ReceiptsPageContext from '../context/ReceiptsContext';
import NewProductDialog from './NewProductDialog';
import NewProductRow from './NewProductRow';
import CubbieInfo from './CubbieInfo';

const ModerateLoad = () => {

  const { handleToggleNewProduct, productList } = useContext(ReceiptsPageContext);


  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            color="customGrey"
            startIcon={<Add color="bluepkt1" />}
            className="rounded-full"
            onClick={handleToggleNewProduct}
          >
            Nuevo producto
          </Button>
          <Fab color="bluepkt1" size="small" ><PlusOne /></Fab>
        </Stack>

        <Stack spacing={2} divider={<Divider flexItem />} >
          {
            productList?.map(product => <Stack spacing={2.5} key={product.id}>
              <CubbieInfo
                title={product.title}
                warehouseName={product.warehouse.name}
                sectionName={product.section.name}
                cubbieName={product.cubbie.name}
                available={product.available}
                color={product.color}
              />
              <Stack spacing={2} divider={<Divider flexItem />} >
                {
                  product.products.map(prod =>
                    <NewProductRow key={prod.id} id={prod.id} />
                  )
                }
              </Stack>
            </Stack>)
            // productList.map(prod => {
            //   return prod.isProduct
            //     ? <NewProductRow key={prod.id} id={prod.id} />
            //     : <CubbieInfo
            //       key={prod.id}
            //       title={prod.title}
            //       warehouseName={prod.warehouse}
            //       sectionName={prod.section}
            //       cubbieName={prod.cubbie}
            //       available={prod.available}
            //       color={prod.color}
            //     />
            // })
          }
        </Stack>
      </Stack>
      <NewProductDialog />
    </>

  );
}

export default ModerateLoad;


import api from './api';
import TokenService from "./token.service";

const register = (username, email, password) => {
    return api.post("/auth/signup", {
        username,
        email,
        password
    });
};

const login = (usuario, contrasena) => {


    return api.post("User/autenticar", {
        usuario,
        contrasena
    })

    // return api
    //     .post("User/autenticar", {
    //         usuario,
    //         contrasena
    //     })
    //     .then((response) => {
    //         if (response.data) {
    //             TokenService.setUser(response.data);
    //         }
    //         return response.data;
    //     });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
};

export default AuthService;
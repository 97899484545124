import api from './api';
import TokenService from "./token.service";
import BDTest from './bd-test';

export const getCubbies = () => {
    // TODO: Hacer el get a la bd y todo lo demás
    // return BDTest.getCubbies;

    return api.get("api/Cubbie", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            pagina: 1,
            PaginaTam: 1000
        }
    });
};

export const getCubbiesByWarehouse = (warehouseId) => {
    // TODO: Hacer el get a la bd y todo lo demás
    // return BDTest.getCubbies;

    return api.get("api/Cubbie/cubbies", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            id_almacen: warehouseId
        }
    });
};


export const getCubbieSearch = (cubbieData) => {
    // TODO: Hacer el get a la bd y todo lo demás
    // return BDTest.getCubbies;

    return api.post("api/Cubbie/search", cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            pagina: 1,
            PaginaTam: 200
        }
    });
};

export const postCubbie = (cubbieData) => {

    const res = api.post('api/Cubbie', cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const postCubbieMultiple = (cubbieData) => {

    const res = api.post('api/Cubbie/multiple', cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putCubbie = (cubbieData) => {

    const res = api.put('api/Cubbie/', cubbieData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const deleteCubbie = (cubbieData) => {

    const res = api.delete('api/Cubbie/', {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            id_almacen: cubbieData.id_almacen,
            id_seccion: cubbieData.id_seccion,
            id_cubbie: cubbieData.id_cubbie
        }
    });

    return res;

}
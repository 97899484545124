import { ReceiptsInterpret } from '../models/receipts.model';
import api from './api';
import TokenService from "./token.service";

// QUERIES
export const getReceipts = () => {

    return api.get("api/Entrada", {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            pagina: 1,
            PaginaTam: 100,
        }
    });

};

export const getReceiptSearch = async (rSearch) => {
    try {

        const receiptResp = await api.get("api/Entrada/entrada/" + rSearch, {
            headers: {
                Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
            }
        });

        return ReceiptsInterpret(receiptResp.data);

    } catch (error) {
        return error;
    }

};

export const getReceiptAndProductsById = (receiptId) => {

    try {
        const receiptResp = api.get("api/Entrada/full/" + receiptId, {
            headers: {
                Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
            }
        });

        return ReceiptsInterpret(receiptResp.data);
    } catch (error) {
        return error;
    }
};

// MUTATIONS

export const postReceipt = (receiptData) => {
    const res = api.post('api/Entrada', receiptData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const putReceipt = (receiptData) => {
    const res = api.put('api/Entrada/' + receiptData.receiptId, receiptData, {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        }
    });
    return res;
};

export const deleteWarehouse = (receiptData) => {
    const res = api.delete('api/Entrada', {
        headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
        params: {
            id: receiptData.receiptId
        }
    });
    return res;
};
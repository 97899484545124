import React, { createContext, useEffect, useState } from 'react'
import { getAllShipments, getShipmentsByState } from '@services/shipments.service';

const AnalyticContext = createContext();

export const AnalyticProvider = ({children}) => {
  
  const [shipments, setShipments] = useState([]);

  useEffect(() => {

    setShipments(getAllShipments());

  }, [])
  

  return (
    <AnalyticContext.Provider value={{shipments, getShipmentsByState }}>
      {children}
    </AnalyticContext.Provider>
  )
}

export default AnalyticContext;
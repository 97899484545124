// React and Hooks
import { React, useContext } from 'react';

// Material
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, MenuItem, Paper, Stack, TextField, Typography, IconButton } from '@mui/material';


// Components
import ProgressIndicator from '@components/ProgressIndicator';
import CubbiesGrid from './CubbiesGrid';

// Context and Provider
import AssignCubbiesContext, { AssignCubbiesProvider } from '../context/AssignCubbiesContext';
import AssignCubbieDetails from './AssignCubbieDetails';

const AssignCubbies = (props) => {
  return (
    <AssignCubbiesProvider {...props}>
      <AssignCubbiesWithContext />
    </AssignCubbiesProvider>
  )
}

const AssignCubbiesWithContext = () => {

  const {
    isLoading,
    open,
    warehouses, sections, cubbies,
    selectedWareId, handleChangeWarehouse,
    selectedSectId, handleChangeSection,
    handleSaveAssignCubbies,
    openCubbieDetails, handleCloseAssignCubbiesDetails,
    selectedCubbies,
    selectedCubbieDetails,
    handleOnCloseAssignCubbieModal
  } = useContext(AssignCubbiesContext)


  return (
    <>
      <Dialog
        open={open}
        onClose={handleOnCloseAssignCubbieModal}
        PaperProps={{ style: { minWidth: '60vw' } }}
      >
        <DialogTitle>Asignación de Cubbies</DialogTitle>

        <DialogContent dividers>
          <Grid container justifyContent="space-evenly" alignItems="center" spacing={3}>
            <Grid item xxs={12} md={6} >
              <TextField
                id="select-warehouse"
                label="Almacén"
                size="small"
                select
                fullWidth
                value={selectedWareId}
                onChange={handleChangeWarehouse}
              >
                <MenuItem value={0}>
                  <em>Selecciona un almacén</em>
                </MenuItem>
                {
                  warehouses.map(ware => <MenuItem key={ware.id_almacen} value={ware.id_almacen}>
                    {`${ware.id_almacen} - ${ware.nombre_almacen}`}
                  </MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xxs={12} md={6}>
              <TextField
                id="select-section"
                label="Sección"
                size="small"
                select
                fullWidth
                value={selectedSectId}
                onChange={handleChangeSection}
              >
                <MenuItem value={0}>
                  <em>Selecciona una sección</em>
                </MenuItem>
                {
                  sections.map(sect => <MenuItem key={sect.id_seccion} value={sect.id_seccion}>
                    {sect.descripcion}
                  </MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid container item xxs={12}>
              <CubbiesGrid cubbies={cubbies} />
            </Grid>

            <Grid item xxs={12}>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <StateIndicator label="Disponible" />
                <StateIndicator label="Ocupado" color="grey.600" />
              </Stack>
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleOnCloseAssignCubbieModal} color="bluepkt1" >Cancelar</Button>
          <Button
            color="orangepkt1"
            disabled={selectedCubbies.length == 0}
            onClick={handleSaveAssignCubbies}
          >
            Guardar
          </Button>
        </DialogActions>

        <ProgressIndicator open={isLoading} />
        <AssignCubbieDetails open={openCubbieDetails} onClose={handleCloseAssignCubbiesDetails} cubbie={selectedCubbieDetails} />
      </Dialog>
    </>
  )
}


const StateIndicator = ({ label = '', color = 'common.white' }) => {

  const stateIndicatorStyle = {
    width: 15,
    height: 15,
    bgcolor: color,
    border: 1,
    borderColor: 'grey.500',
    borderRadius: 1
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box sx={stateIndicatorStyle} />
      <Typography variant="body2">{label}</Typography>
    </Stack>
  )
}

export default AssignCubbies


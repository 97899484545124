// React and Hooks
import React, { useContext } from "react";

// Components
import { SignIn, SignUp } from "./components";

// Providers / Context
import LoginContext, { LoginProvider } from "./context/LoginContext";

// Assets
import backgroundRoot from '@assets/img/endless-constellation.svg'
import LuuniCatOutlined from '@assets/img/LogoLuni_outline.png'
import Image from "../../components/Image";
import { Box, Stack } from "@mui/material";

const LoginPage = () => {
  // Se creó un Login anidado para no usar el LoginProvider en App.js y 
  // mantener el código más limpio
  return (
    <LoginProvider>
      <LoginWithContext />
    </LoginProvider>
  )
}

// LoginContexted porque este ya tiene el contexto
const LoginWithContext = () => {

  const { loginForm } = useContext(LoginContext);

  return (
    <>
      <Stack direction="row" spacing={1} height="100vh">
        <Box className="py-5 md:w-1/2 flex justify-end items-center overflow-y-auto" >
          {
            loginForm 
              ? <SignIn />
              : <SignUp />
          }
        </Box>
        <Box className="w-1/2 bg-bluepkt1 hidden md:grid place-items-center" sx={{ backgroundImage: `url(${backgroundRoot})` }}>
          <Image src={LuuniCatOutlined} alt="luuni-logo-outlined" boxFit="contain" width="100%" height="80vh" />
        </Box>
      </Stack>
    </>
  );
}


export default LoginPage;
// Material
import { Chip, Grid, Typography } from '@mui/material';

import {
  Email as EmailIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
  AdminPanelSettingsOutlined,
  ManageSearch,
} from '@mui/icons-material';

// Components
import CustomAvatar from '@components/CustomAvatar'
import CustomArrowForwardIcon from '@components/CustomArrowForwardIcon';
import { formatStringDate } from '../../../utils/dates';


export const getReceiptsColumns = (showSellerFormArea) => {
  // showSellerFormArea sirve para ocultar columnas si se esta mostrando el formulario
  const enabledCols = {
    textAlign: 'center',
    // display: showSellerFormArea ? { lg: 'none' } : { xxs: 'none', sm: 'revert' }
  }

  return [
    { id: 'id', label: 'ID',  style: { textAlign: 'center' } },
    { id: 'seller', label: 'Vendedor' },
    { id: 'date', label: 'Fecha - Hora', style: enabledCols },
    { id: 'status', label: 'Estado', style: { minWidth: 60, textAlign: 'center', ...enabledCols } },
    { id: 'actions', style: { textAlign: 'center' } },
  ];

}

export const getReceiptsRows = (receipts, onClick) => receipts.map(receipt => {

  // Cada renglon de la tabla debe tener su campo id (el cual estará oculto al usuario)
  // para buscar por id y llenar el formulario (OBLIGATORIO)

  return {
    id: receipt.receiptId,
    seller: <Typography variant="body2" ml={0.5}>{receipt.sellerName}</Typography>,
    date: <Typography variant="body2" ml={0.5}>{formatStringDate(receipt.createdAt)}</Typography>,
    status: receipt.status == 'A'
      ? <CheckCircleOutlinedIcon color="success" />
      : <RemoveCircleOutlineOutlinedIcon color="error" />,
    actions: <Chip icon={<ManageSearch />} label="Detalles" color="info" onClick={()=> console.log(receipt.receiptId)} />
  };
})
import React from "react";
import { InputAdornment, TextField } from "@mui/material"

const CustomDimensionField = ({ id, label, value, adornments, onChange, ...props }) => {

  const dimensionsAdornments = {
    inputProps: { min: 1, step: 0.1 },
    endAdornment: <InputAdornment position="end">mts.</InputAdornment>,
  }

  return (
    <TextField
      id={id}
      label={label}
      size="small"
      type="number"
      InputProps={ adornments != null ? adornments : dimensionsAdornments }
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}

export default CustomDimensionField;
// React and Hooks
import React from 'react';
import PropTypes from 'prop-types';

// Material
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const TableData = ({ columns, rows, onSelectedRow }) => {

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: { xxs: '70vh', lg: '63vh' } }}>
        <Table stickyHeader aria-label="users table">

          <TableHead>
            <TableRow>
              {
                columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align && ''}
                    size="small"
                    sx={{
                      fontWeight: 600,
                      fontSize: 16,
                      bgcolor: blueGrey[100],
                      ...column.style
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {
              rows.length == 0
                ? <TableRow >
                  <TableCell colSpan={columns.length}>
                    <Typography variant="body1" sx={{ fontWeight: 'medium', textAlign: 'center' }}>No hay resultados</Typography>
                  </TableCell>
                </TableRow>

                : rows.map((row) => (
                  <TableRow key={row.id} hover role="checkbox" sx={{ cursor: 'pointer' }} onClick={() => { onSelectedRow(row.id) }}>
                    {
                      columns.map((column) => {
                        return (
                          <TableCell key={`${column.id}${row.id}`} sx={{ ...column.style }}>
                            {row[column.id]}
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                ))
            }
          </TableBody>

        </Table>
      </TableContainer>

    </Paper>
  );
}

TableData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
}


export default TableData;
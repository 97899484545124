import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { useTheme } from '@mui/material/styles';

// Material
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Divider,
  Typography,
  IconButton,
  Alert,
  FormHelperText,
  Fade,
} from '@mui/material';
// Icons
import {
  Badge as BadgeIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Person as PersonIcon,
  Room as RoomIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Key as KeyIcon
} from '@mui/icons-material';

// DatePicker
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Services
import { postUser, putUser } from '@services/user.service';
import { getUsersRoles } from '@services/user.service';

// Components
import FormDivider from '@components/FormDivider';
import ProgressIndicator from '@components/ProgressIndicator';
import UserSignature from './UserSignature';
import ChangePassword from './ChangePassword';
import { getEditUserValidator, getNewUserValidator } from '../validators/user-validator';

// Utils
import SwalAlert from "@utils/alerts";

const UserForm = ({ user = {}, onShow, onSubmit }) => {

  const isNewUser = Object.entries(user).length == 0 ? true : false;

  const theme = useTheme();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(isNewUser ? false : true);
  const [signature, setSignature] = useState(isNewUser ? '' : user.usuario_detalle.firma);
  const [openSigatureModal, setOpenSigatureModal] = useState(false);
  const [msgNoSignature, setMsgNoSignature] = useState(false);
  const [openChangePass, setOpenChangePass] = useState(false);

  // Se le restan 18 años a la fecha actual, el usuario debe ser mayor de edad, al menos eso creo
  let validDate = new Date();
  validDate.setFullYear(validDate.getFullYear() - 18);

  const getRoles = async () => {
    try {
      let rolesResp = await getUsersRoles();

      setRoles(rolesResp.data);
    } catch (error) {
      SwalAlert.error(`Error: ${error}`)
    }
  }

  // Modal de cambiar contraseña
  const handleOpenChangePass = () => setOpenChangePass(true);
  const handleCloseChangePass = () => setOpenChangePass(false);

  // Esto es del modal de Firma
  const handleOpenSignature = () => setOpenSigatureModal(true);
  const handleCloseSignature = () => setOpenSigatureModal(false);
  const handleSaveSignature = (signCanvas) => {
    setSignature(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    handleCloseSignature();
  }

  const resetFormUser = () => {
    formik.resetForm();
    setSignature('');
    onShow(false);
  }

  const handleSubmit = async (values) => {

    if (signature == '') {
      setMsgNoSignature(true);
      setTimeout(() => {
        setMsgNoSignature(false);
      }, 4000);
      return;
    }

    setIsLoading(true);

    let userData = {
      usuario: values.userName,
      contrasena: isNewUser ? values.password : '',
      email: values.email,
      id_rol: values.rol,
      usuario_detalle: {
        usuario: values.userName,
        nombre: values.firstName,
        apellidos: values.lastName,
        fecha_nacimiento: values.birthDate.toISOString(),
        identificacion: values.identification,
        firma: signature,
        alta: isNewUser ? new Date().toISOString() : user.usuario_detalle.alta,
        estatus: values.active ? 'A' : 'C', // Abrá mas estados después
        direccion: {
          cp: values.postalCode,
          calle: values.street,
          ciudad: values.city,
          municipio: values.county,
          estado: values.region,
          pais: values.country,
        }
      }
    }

    try {

      isNewUser
        ? await postUser(userData)
        : await putUser(userData)

      SwalAlert.success('Usuario guardado correctamente');
      resetFormUser();
      onSubmit(); // Esto hace una busqueda de todos los usuarios de nuevo
    } catch (error) {

      let errorsString = error;

      if (error.hasOwnProperty('response')) {

        const { data } = error.response;

        if (typeof data !== 'object') {
          errorsString = data;
        } else {
          errorsString = [];
          for (let err in data.errors) {
            errorsString.push(data.errors[err].map((errArray) => `${err}: ${errArray}`).toString());
          }
        }

      }

      SwalAlert.error(`Error: \n${errorsString}`);
    }

    setIsLoading(false);
  }

  let formik = useFormik(isNewUser ? getNewUserValidator(validDate, handleSubmit) : getEditUserValidator(user, handleSubmit));

  useEffect(() => {
    getRoles();
  }, [])

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="h-full pt-5 pb-10 px-5 md:px-10 overflow-y-auto">

        {/* ------------- HEADER ------------- */}
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h4" color={theme.palette.bluepkt1.main} sx={{ fontSize: { xxs: '1.5rem', md: '1.8rem', lg: '2.1rem' } }}>{isDisabled ? 'Detelle de usuario' : 'Editar usuario'}</Typography>
          </Grid>

          <Grid item>
            <IconButton
              color="bluepkt1"
              size="large"
              onClick={resetFormUser}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider textAlign="right" sx={{ my: 4 }} >
          {
            !isNewUser && <IconButton
              aria-label="edit"
              size="large"
              className="bg-orangepkt1 text-white"
              onClick={() => setIsDisabled(false)}
            >
              <EditIcon />
            </IconButton>
          }

        </Divider>

        {/* ------------- BODY FORM ------------- */}
        {/* ------------------------- Información personal ------------------------- */}
        <Grid container spacing={3}>
          <Grid item xxs={12}>
            <FormDivider title='Información personal' icon={<PersonIcon />} />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="firstName"
              label="Nombre"
              placeholder="Ingresa tu nombre"
              variant="outlined"
              type="text"
              fullWidth
              autoFocus={true}
              disabled={isDisabled}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.firstName && formik.errors.firstName}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="lastName"
              label="Apellido"
              placeholder="Ingresa tu apellido"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.lastName && formik.errors.lastName}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                name="birthDate"
                label="Fecha de Nacimiento"
                openTo="day"
                inputFormat="dd/MM/yyyy"
                views={['year', 'month', 'day']}
                maxDate={validDate}
                disabled={isDisabled}
                value={formik.values.birthDate}
                onChange={(val) => formik.setFieldValue('birthDate', val)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xxs={12} md={6}>
            <FormControl dis fullWidth error={formik.touched.identification && Boolean(formik.errors.identification)}>
              <InputLabel id="select-id-label">Identificación</InputLabel>
              <Select
                name="identification"
                label="Identificación"
                labelId="select-id-label"
                disabled={isDisabled}
                value={formik.values.identification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="Pasaporte">Licencia de conducir</MenuItem>
                <MenuItem value="Cédula de identidad">Cédula de identidad</MenuItem>
                <MenuItem value="Tarjeta estudiantil">Tarjeta estudiantil</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.identification && formik.errors.identification}</FormHelperText>
            </FormControl>
          </Grid>

          {/* ------------------------- Domicilio ------------------------- */}
          <Grid item xxs={12}>
            <FormDivider title='Domicilio' icon={<RoomIcon />} />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="street"
              label="Calle"
              placeholder="Ingresa tu calle"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.street && formik.errors.street}
              error={formik.touched.street && Boolean(formik.errors.street)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="postalCode"
              label="Código Postal"
              placeholder="Ingresa tu código postal"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="city"
              label="Comuna"
              placeholder="Ingresa tu comuna"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.city && formik.errors.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="county"
              label="Provincia"
              placeholder="Ingresa tu provincia"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.county}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.county && formik.errors.county}
              error={formik.touched.county && Boolean(formik.errors.county)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="region"
              label="Región"
              placeholder="Ingresa tu región"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.region}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.region && formik.errors.region}
              error={formik.touched.region && Boolean(formik.errors.region)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="country"
              label="País"
              placeholder="Ingresa tu país"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.country && formik.errors.country}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </Grid>

          {/* ------------------------- Información de la cuenta ------------------------- */}
          <Grid item xxs={12}>
            <FormDivider title='Información de la cuenta' icon={<BadgeIcon />} />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="userName"
              label="Usuario"
              placeholder="Ingresa tu usuario"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.userName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.userName && formik.errors.userName}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="email"
              label="Correo electrónico"
              placeholder="ejemplo@mail.com"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </Grid>

          {/* Si es un usuario nuevo no se muestran los inputs de la contraseña */}
          {
            isNewUser
              ? <>
                <Grid item xxs={12} md={6}>
                  <TextField
                    id="password"
                    label="Contraseña"
                    placeholder="Pass-123"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={formik.values?.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched?.password && formik.errors?.password}
                    error={formik.touched?.password && Boolean(formik.errors?.password)}
                  />
                </Grid>

                <Grid item xxs={12} md={6}>
                  <TextField
                    id="passwordConfirm"
                    label="Confirmar contraseña"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                    error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                  />
                </Grid>
              </>
              : <Grid item xxs={12} md={6}>
                <Button
                  variant="contained"
                  startIcon={<KeyIcon />}
                  className="bg-bluepkt1 w-full disabled:text-gray-600 disabled:bg-grey-500"
                  disabled={isDisabled}
                  onClick={handleOpenChangePass}
                >
                  Cambiar contraseña
                </Button>
              </Grid>
          }

          <Grid item xxs={12} md={6}>
            <FormControl fullWidth error={formik.touched.rol && Boolean(formik.errors.rol)}>
              <InputLabel id="select-rol-label">Rol</InputLabel>
              <Select
                name="rol"
                label="Rol"
                labelId="select-rol-label"
                disabled={isDisabled}
                value={formik.values.rol}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {
                  roles.map(rolResp => {
                    if (rolResp.estatus !== 'A') return;

                    return <MenuItem key={rolResp.id_rol} value={rolResp.id_rol}>{rolResp.descripcion}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>{formik.touched.rol && formik.errors.rol}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xxs={12} md={6}>
            <FormControlLabel
              control={<Checkbox
                id='active'
                color="bluepkt1"
                disabled={isDisabled}
                checked={formik.values.active}
                onChange={formik.handleChange}
              />}
              label="Activo"
            />
          </Grid>

          <Grid container item direction="column" xxs={12} md={6}>

            {
              signature && <Grid item>
                <img src={signature} alt="imagen firma" className="w-full" />
              </Grid>
            }

            <Grid item>
              <Button
                variant="contained"
                startIcon={<DriveFileRenameOutlineIcon />}
                className="bg-bluepkt1 w-full disabled:text-gray-600 disabled:bg-grey-500"
                disabled={isDisabled}
                onClick={handleOpenSignature}
              >
                Firmar
              </Button>
            </Grid>
            {/* Fade de Material UI */}
            <Fade in={msgNoSignature}><Alert severity="error" sx={{ mt: 0.5 }}>Registre su firma</Alert></Fade>
          </Grid>

        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* ------------- FOOTER ------------- */}
        <Grid container spacing={5} justifyContent="right">
          <Grid item>
            <Button
              variant="text"
              className="text-gray-800 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
              onClick={resetFormUser}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              className="bg-orangepkt1 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
            >
              {isNewUser ? 'Guardar' : 'Actualizar'}
            </Button>
          </Grid>
        </Grid>

        {/* Modal de Firma */}
        <UserSignature open={openSigatureModal} onClose={handleCloseSignature} onSave={handleSaveSignature} />

        {/* Modal Cambiar Contraseña */}
        <ChangePassword open={openChangePass} onClose={handleCloseChangePass} user={user} />

      </form>
      <ProgressIndicator open={isLoading} />
    </>
  )
}

export default UserForm
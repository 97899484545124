// React and Hooks
import { Typography } from '@mui/material';
import React, { useContext } from 'react'

// Charts
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Context / Providers
import AnalyticContext from '../context/AnalyticContext';

const CardGeneralPackages = () => {

  const {shipments} = useContext(AnalyticContext);

  return (
    <div className="my-5 px-5 pt-3 md:px-10 md:pt-7 w-auto bg-bluedash rounded-2xl shadow-lg shadow-blue-grey-300">
      
      <Typography variant="h5" className="text-gray-100 font-semibold" >Visión general de envíos</Typography>
      <Typography variant="h6" className="text-gray-400 font-light" >Envios por día</Typography>
      
      <ResponsiveContainer width="100%" height="70%" className="py-2 sm:py-5 md:py-10 bg-bluedash">
        <AreaChart
          data={shipments}
          
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis dataKey="dia" />
          <Tooltip />
          <Area type="monotone" dataKey="envios" stroke="#8884d8" strokeWidth="3" fill="#8884d8ab" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default CardGeneralPackages
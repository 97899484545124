import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

// Material
import {
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
// Icons
import {
  Room as RoomIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

// Components
import FormDivider from '@components/FormDivider';
import ProgressIndicator from '@components/ProgressIndicator';
import TextFieldPhoneMasked from './TextFieldPhoneMasked';
import CustomCubbiesIcon from '@components/CustomCubbiesIcon';
import CustomCubbieIcon from '@components/CustomCubbieIcon';

// Validators
import { getWarehouseValidator } from '../validators/warehouse-validator';

// Utils
import SwalAlert from "@utils/alerts";

// Services
import { postWarehouse, putWarehouse } from '@services/warehouse.service';
import CubbiesManagment from './CubbiesManagment';

const WarehouseForm = ({ warehouse = {}, onShow, onSubmit }) => {

  const isNewWarehouse = Object.keys(warehouse).length == 0;

  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(isNewWarehouse ? false : true);
  const [openCubbiesMan, setOpenCubbiesMan] = useState(false);

  const resetFormWarehouse = () => {
    formik.resetForm();
    onShow(false);
  }

  const handleSubmit = async (values) => {

    setIsLoading(true);

    let warehouseData = {
      nombre_almacen: values.warehouseName,
      telefonos: values.phone,
      estatus: 'A',
      direccion: {
        cp: values.postalCode,
        calle: values.street,
        ciudad: values.city,
        municipio: values.county,
        estado: values.region,
        pais: values.country
      }
    }

    try {

      isNewWarehouse
        ? await postWarehouse(warehouseData)
        : await putWarehouse({ ...warehouseData, id_almacen: warehouse.id_almacen })

      SwalAlert.success(`Almacén ${isNewWarehouse ? 'registrado' : 'actualizado'} correctamente`);
      resetFormWarehouse();
      onSubmit(); // Esto hace una busqueda de todos los sellers de nuevo
    } catch (error) {

      SwalAlert.errorList(error);
    }

    setIsLoading(false);
  }

  const handleOpenCubbiesMan = () => {
    setOpenCubbiesMan(true);
  };

  const handleCloseCubbiesMan = () => {
    setOpenCubbiesMan(false);
  };

  const formik = useFormik(getWarehouseValidator(warehouse, handleSubmit));

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="h-full pt-5 pb-10 px-5 md:px-10 overflow-y-auto">

        {/* ------------- HEADER ------------- */}
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h4" color={'bluepkt1.main'} sx={{ fontWeight: 500, fontSize: { xxs: '1.5rem', md: '1.8rem', lg: '2.1rem' } }}>{isNewWarehouse ? 'Nuevo almacén' : warehouse.nombre_almacen}</Typography>
            {!isNewWarehouse && <Typography variant="h6" sx={{ fontWeight: 'light' }} >ID: {warehouse.id_almacen}</Typography>}

          </Grid>

          <Grid item>
            <IconButton
              color="bluepkt1"
              size="large"
              onClick={resetFormWarehouse}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider textAlign="right" sx={{ my: 4 }} >
          {
            !isNewWarehouse && <IconButton
              aria-label="edit"
              size="large"
              className="bg-orangepkt1 text-white"
              onClick={() => setIsDisabled(false)}
            >
              <EditIcon />
            </IconButton>
          }

        </Divider>

        {/* ------------- BODY FORM ------------- */}
        <Grid container spacing={3}>

          <Grid item xxs={12}>
            <TextField
              id="warehouseName"
              label="Nombre"
              placeholder="Ingresa el nombre del almacén"
              variant="outlined"
              type="text"
              fullWidth
              autoFocus={true}
              disabled={isDisabled}
              value={formik.values.warehouseName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.warehouseName && formik.errors.warehouseName}
              error={formik.touched.warehouseName && Boolean(formik.errors.warehouseName)}
            />
          </Grid>

          {/* ------------------------- Domicilio ------------------------- */}
          <Grid item xxs={12}>
            <FormDivider title='Domicilio' icon={<RoomIcon />} />
          </Grid>

          <Grid item xxs={12}>
            <TextField
              id="street"
              label="Calle"
              placeholder="Ingresa tu calle"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.street && formik.errors.street}
              error={formik.touched.street && Boolean(formik.errors.street)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="city"
              label="Comuna"
              placeholder="Ingresa tu comuna"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.city && formik.errors.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="county"
              label="Provincia"
              placeholder="Ingresa tu provincia"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.county}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.county && formik.errors.county}
              error={formik.touched.county && Boolean(formik.errors.county)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="region"
              label="Región"
              placeholder="Ingresa tu región"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.region}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.region && formik.errors.region}
              error={formik.touched.region && Boolean(formik.errors.region)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="country"
              label="País"
              placeholder="Ingresa tu país"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.country && formik.errors.country}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              id="postalCode"
              label="Código Postal"
              placeholder="Ingresa tu código postal"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
            />
          </Grid>

          <Grid item xxs={12} md={6}>
            <TextField
              name="phone"
              label="Teléfono"
              placeholder="56 9 3210-6626"
              variant="outlined"
              type="text"
              fullWidth
              disabled={isDisabled}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.phone && formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              InputProps={{ inputComponent: TextFieldPhoneMasked }}
            />
          </Grid>

          {/* ------------------------- Info de Cubbies ------------------------- */}

          {
            !isNewWarehouse && <>
              <Grid item xxs={12}>
                <FormDivider title='Información de cubbies' icon={<CustomCubbiesIcon />} />
              </Grid>

              <Grid item xxs={12} md={6}>
                <TextField
                  id="totalCubbies"
                  label="Capacidad total"
                  variant="outlined"
                  type="number"
                  fullWidth
                  disabled={!isNewWarehouse}
                  value={formik.values.totalCubbies}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.totalCubbies && formik.errors.totalCubbies}
                  error={formik.touched.totalCubbies && Boolean(formik.errors.totalCubbies)}
                />
              </Grid>

              <Grid item xxs={12} md={6}>
                <TextField
                  id="availableCubbies"
                  label="Cubbies disponibles"
                  variant="outlined"
                  type="text"
                  fullWidth
                  disabled
                  value={warehouse.cubbie_disponibles}
                />
              </Grid>

              <Grid item xxs={12} md={6}>
                <Button
                  variant="contained"
                  startIcon={<CustomCubbieIcon />}
                  className="bg-bluepkt1 w-full disabled:text-gray-600 disabled:bg-grey-500"
                  onClick={ handleOpenCubbiesMan }
                >
                  Administrador de Cubbies
                </Button>
              </Grid>
            </>
          }

        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* ------------- FOOTER ------------- */}
        <Grid container spacing={5} justifyContent="right">
          <Grid item>
            <Button
              variant="text"
              className="text-gray-800 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
              onClick={resetFormWarehouse}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              className="bg-orangepkt1 rounded-full disabled:text-gray-600 disabled:bg-grey-500"
              disabled={isDisabled}
            >
              {isNewWarehouse ? 'Guardar' : 'Actualizar'}
            </Button>
          </Grid>
        </Grid>

      </form>
      <CubbiesManagment warehouse={warehouse} open={openCubbiesMan} onClose={handleCloseCubbiesMan} />
      <ProgressIndicator open={isLoading} />
    </>
  )
}

export default WarehouseForm
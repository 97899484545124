// React and Hooks
import { useEffect, useState } from "react";

// Material
import { Alert, Slide, Snackbar } from "@mui/material"


const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
}

const CustomSnackbar = ({ open = false, message = '', type = "info", onClose }) => {

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClose = () => {
    setOpenSnackbar(false);
    setTimeout(() => {
      onClose()
    }, 500);
  }

  useEffect(() => {
    if (open) return setOpenSnackbar(open);
  }, [open])
  
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={1500}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Alert variant="filled" severity={type}>{message}</Alert>
    </Snackbar>
  )
}
export default CustomSnackbar;
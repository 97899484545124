import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@mui/material';

import { stringAvatar } from '@utils/utils';

const CustomAvatar = ({ name }) => {
  return (
    <Avatar alt={`${name}`}>
      { stringAvatar(name) }
    </Avatar>
  )
}

CustomAvatar.propTypes = {
  name: PropTypes.string.isRequired
}

export default CustomAvatar;
import axios from 'axios';
import TokenService from "./token.service";
import Swal from 'sweetalert2';

const instance = axios.create({
    baseURL: 'https://api.qapkt1i.cf/',
    headers: {
        "Content-Type": "application/json",
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["x-access-token"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.response.status === 401 && originalConfig.url !== "User/autenticar") {
            Swal.fire({
                icon: 'warning',
                title: 'Caducó la sesión',
                text: 'Inicia nuevamente la sesión'
            });
            window.location.href = '/';
            return Promise.reject(err);
        }
        if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            return instance.post("User/actualizarToken", {
                    jwtToken: TokenService.getLocalAccessToken(),
                    refreshToken: TokenService.getLocalRefreshToken(),
            })
            .then (res => {
                if(res.status === 201){
                    TokenService.updateLocalAccessToken(res.data);
                    instance.defaults.headers.common['Authorization'] = 'Bearer ' + TokenService.getLocalAccessToken();
                    return instance(originalConfig);
                }
            })
        }
        return Promise.reject(err);
    }
);

export default instance;
import { ProductsCubbieInterpret } from '../models/productsCubbie.model';
import { ProductInventoryInterpret } from '../models/productsInventory.model';
import { ProductsSellerInterpret } from '../models/productsSeller.model';
import api from './api';
import TokenService from "./token.service";

// GET
export const getProductsInventory = async (sellerId, warehouseId = 0) => {

  try {

    const productsResp = await api.get('api/InventarioAlmacen/productoInventario', {
      headers: {
        Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      },
      params: {
        id_almacen: warehouseId,
        id_vendedor: sellerId
      }
    });

    return ProductInventoryInterpret(productsResp.data);

  } catch (error) {
    return error;
  }
};

export const getProductsSeller = async (sellerId, warehouseId = 0) => {

  try {

    const productsResp = await api.get('api/InventarioAlmacen/productosVendedor', {
      headers: {
        Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      },
      params: {
        id_almacen: warehouseId,
        id_vendedor: sellerId
      }
    });

    return ProductsSellerInterpret(productsResp.data);

  } catch (error) {
    return error;
  }
};

export const getProductsCubbies = async (sellerId, warehouseId = 0) => {

  try {

    const productsResp = await api.get('api/InventarioAlmacen/porductoCubbie', {
      headers: {
        Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
      },
      params: {
        id_almacen: warehouseId,
        id_vendedor: sellerId
      }
    });

    return ProductsCubbieInterpret(productsResp.data);

  } catch (error) {
    return error;
  }
};
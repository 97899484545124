import { AddPhotoAlternate } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import FormikField from '../../../components/FormikField'
import Image from '../../../components/Image'
import ProgressIndicator from '../../../components/ProgressIndicator'
import ReceiptsPageContext from '../context/ReceiptsContext'
import { getNewProductValidator } from '../validators/new-product-validator'
import ImageField from './ImageField'

import NoImage from "@assets/img/no-image.PNG";
import NumberField from '../../../components/NumberField'

const NewProductDialog = () => {

  const {
    openNewProduct,
    handleToggleNewProduct,
    isLoading, setIsLoading,
    selectedSeller
  } = useContext(ReceiptsPageContext);

  const [product, setProduct] = useState({});

  const handleSubmit = (values) => {
    setIsLoading(true);
    console.log(values);

    try {

      const volume = values.length * values.width * values.height;

      const productObj = {
        id_vendedor: selectedSeller.id_vendedor,
        nombre: values.productName,
        descripcion: values.description,
        dim_largo: values.length,
        dim_ancho: values.width,
        dim_alto: values.height,
        peso: values.weight,
        cantidad: values.description,
        volumen: volume,
        imagen_producto: 'base64 image',
        sku: values.sku,
        stock_minimo: values.minStock,
        generado: 0
      }


    } catch (error) {

    }
    setIsLoading(false);
  }

  const handleClose = () => {
    formik.resetForm();
    handleToggleNewProduct();
  }

  const formik = useFormik(getNewProductValidator(handleSubmit));

  return (
    <>
      <Dialog
        open={openNewProduct}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{ sx: { minWidth: { sm: 600 } } }}
      >
        <DialogTitle sx={{ pt: 3, pl: 5, fontWeight: 500, fontSize: { xxs: 18, sm: 20 } }}>Nuevo producto</DialogTitle>

        <DialogContent >

          <Grid container spacing={3} mt={0} justifyContent="center">

            <Grid item xxs={12} sm={6} >
              <FormikField
                id="productName"
                label="Nombre del producto"
                type="text"
                size="small"
                formikInstance={formik}
              />
            </Grid>

            <Grid item xxs={12} sm={6} >
              <FormikField
                id="description"
                label="Descripción"
                type="text"
                size="small"
                formikInstance={formik}
              />
            </Grid>

            {
              [
                { id: 'length', label: 'Largo' },
                { id: 'width', label: 'Ancho' },
                { id: 'height', label: 'Alto' },
                { id: 'weight', label: 'Peso' },
                { id: 'quantity', label: 'Cant.', isInteger: true },
              ].map(field => <Grid item xxs={2} key={field.id}>
                <NumberField
                  id={field.id}
                  label={field.label}
                  size="small"
                  type="number"
                  isInteger={field?.isInteger}
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                />
              </Grid>)
            }
            <Grid item xxs={2}>
              <TextField
                id="volume"
                label="Volumen"
                size="small"
                disabled
                value={(formik?.values?.length * formik?.values?.width * formik?.values?.height).toFixed(2)}
              />
            </Grid>

            <Grid item xxs={3}>
              <NumberField
                id="minStock"
                label="Stock mínimo"
                size="small"
                type="number"
                isInteger
                value={formik.values.minStock}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.errors.minStock}
                error={!!formik.errors.minStock}
              />
            </Grid>

            <Grid item xxs={9} >
              <FormikField
                id="sku"
                label="SKU"
                type="text"
                size="small"
                formikInstance={formik}
              />
            </Grid>

            <Grid item xxs={12} >
              <ImageField
                id="image"
                value={formik.values.image}
                icon={<AddPhotoAlternate />}
                onChange={(name, value) => formik.setFieldValue(name, value)}
                onBlur={formik.handleBlur}
                error={formik.touched.image && !!formik.errors.image}
                helperText={formik.touched.image && formik.errors.image}
              />
            </Grid>

            <Grid item>
              <Box sx={{ p: 1, border: 1, borderColor: 'grey.500', borderRadius: 3, minWidth: 300, minHeight: 100 }}>
                <Image src={formik.values?.image?.urlFile || NoImage} alt="product-image" boxFit='contain' width={300} height={150} />
              </Box>
            </Grid>

          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button
            color="customGrey"
            sx={{ borderRadius: 10 }}
            onClick={handleClose}
          >
            CANCELAR
          </Button>

          <Button
            color="orangepkt1"
            sx={{ borderRadius: 10 }}
            onClick={formik.handleSubmit}
          >
            AGREGAR
          </Button>


        </DialogActions>

      </Dialog>
      <ProgressIndicator open={isLoading} />

    </>
  )
}

export default NewProductDialog
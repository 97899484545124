import React from 'react'

// Material and Icons
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, IconButton } from '@mui/material'

// Assets
import cubbieDetailsImg from '../../../assets/img/cubbie_assign_details.png';
import { Close } from '@mui/icons-material';

const AssignCubbieDetails = ({ open, onClose, cubbie }) => {

  const ocupiedVolume = (cubbie.cubbie_largo * cubbie.cubbie_alto * cubbie.cubbie_ancho) - cubbie.cubbie_volumen;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Stack direction="row" justifyContent="right" pt={1.5} pr={1.5}>
        <IconButton onClick={onClose} > <Close /> </IconButton>
      </Stack>

      <DialogTitle sx={{pt:0}}>{`Detalles de Cubbie CB-${cubbie.id_cubbie}`}</DialogTitle>

      <DialogContent>
        <Box sx={{my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={cubbieDetailsImg} alt="cubbie" width={200} height={200} />
          <Typography variant="h6" component="div" sx={{ bgcolor: 'common.white', color: 'grey.700', position: 'absolute'}}>{`${cubbie.cubbie_volumen}m³`}</Typography>
        </Box>
        <Stack direction="row" justifyContent="space-evenly" spacing={1}>
          <TextField
            id="occupied-volume"
            label="Volumen ocupado"
            size="small"
            fullWidth
            disabled
            value={ocupiedVolume}
          />
          <TextField
            id="available-volume"
            label="Volumen Disponible"
            size="small"
            fullWidth
            disabled
            value={cubbie.cubbie_volumen}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default AssignCubbieDetails
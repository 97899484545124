// React and Hooks
import { createContext, useEffect, useState } from "react";
import { getCubbies, getCubbiesByWarehouse } from "../../../services/cubbie.service";
import { getSections } from "../../../services/section.service";
import SwalAlert from "../../../utils/alerts";

const CubbiesManagmentContext = createContext();

export const CubbiesManagmentProvider = ({ children, warehouse, open, onClose }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [dimensions, setDimensions] = useState({})
  const [aisles, setAisles] = useState(2)
  const [sections, setSections] = useState([]);
  const [cubbies, setCubbies] = useState([]);
  const [openNewSectionModal, setOpenNewSectionModal] = useState(false);
  const [openNewCubbieModal, setOpenNewCubbieModal] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});

  const handleClose = () => {
    onClose();
  }

  const handleCloseSnackBar = () => setSnackbarProps({ open: false });

  const initGetSections = async () => {

    try {

      const sectionsResp = await getSections();
      const sectionsByWarehouseId = sectionsResp.data.filter(sectResp => sectResp.id_almacen === warehouse.id_almacen);

      setSections(sectionsByWarehouseId);

    } catch (error) {
      SwalAlert.errorList(error);
    }
  }

  const initGetCubbies = async () => {

    try {

      const cubbiesResp = await getCubbiesByWarehouse(warehouse.id_almacen);
    
      setCubbies(cubbiesResp.data);

    } catch (error) {
      SwalAlert.errorList(error);
    }

  }

  const initDimensions = () => {
    setDimensions({
      scale: 5,
      length: warehouse.largo || 100,
      height: warehouse.alto || 10,
      width: warehouse.ancho || 100
    })
  }

  useEffect(() => {
    initGetSections();
    initGetCubbies();
  }, [])

  useEffect(() => {
    initDimensions();
  }, [])


  return (
    <CubbiesManagmentContext.Provider value={{
      warehouse,
      isLoading, setIsLoading,
      open, handleClose,
      dimensions, setDimensions,
      aisles, setAisles,
      sections, setSections,
      cubbies, setCubbies,
      openNewSectionModal, setOpenNewSectionModal,
      openNewCubbieModal, setOpenNewCubbieModal,
      snackbarProps, setSnackbarProps, handleCloseSnackBar,
      initGetSections, initGetCubbies
    }}>
      {children}
    </CubbiesManagmentContext.Provider>
  )
}

export default CubbiesManagmentContext;
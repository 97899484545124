import { Check, Edit, Remove } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Fab, InputAdornment, MenuItem, Stack, TextField } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import ReceiptsPageContext from '../context/ReceiptsContext'
import { generateId } from '../../../utils/utils'

const NewProductRow = ({id}) => {

  const { getProductById, updateProduct } = useContext(ReceiptsPageContext);
  
  const product = getProductById(id);
  console.log(product);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newProduct = { ...product, [name]: value }
    
    updateProduct(product.id, newProduct )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <TextField
        name='productName'
        label="Producto"
        size="small"
        value={product.productName}
        defaultValue={product.selectValues[0]}
        // select={product?.selectValues.length > 0}
        select
        sx={{ minWidth: 250 }}
        onChange={handleChange}
      >
        {
          product.selectValues.map(prod => <MenuItem key={generateId()} value={prod} >{prod}</MenuItem> )
        }
      </TextField>

      <NumberField
        name='length'
        label="Largo"
        size="small"
        type="number"
        onChange={handleChange}
      />

      <NumberField
        name='width'
        label="Ancho"
        size="small"
        type="number"
        onChange={handleChange}
      />

      <NumberField
        name='height'
        label="Alto"
        size="small"
        type="number"
        onChange={handleChange}
      />

      <NumberField
        name='weight'
        label="Peso"
        size="small"
        type="number"
        onChange={handleChange}
      />

      <NumberField
        name='quantity'
        label="Cant."
        size="small"
        type="number"
        onChange={handleChange}
      />

      <Button
        startIcon={<Check />}
        color="bluepkt1"
      >SKU</Button>

      <Chip label={`Stock: ${product.minStock}`} color="customGrey" className="rounded-md" />

      <Box> <Fab size="smallest" color="error"  > <Remove fontSize="small" /> </Fab> </Box>
      <Box> <Fab size="smallest" color="bluepkt1" > <Edit fontSize="small" /> </Fab> </Box>

    </Stack>
  )
}


const NumberField = ({name, label, value, defaultValue = 30, onChange }) => {

  const adornments = {
    inputProps: { min: 0.5, step: 0.1 }
  }


  return (
    <TextField
      name={name}
      label={label}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      type="number"
      size="small"
      InputProps={ adornments }
    />
  )
}

export default NewProductRow
import React, { useContext, useEffect } from 'react'

import { Alert, Box, Button, CircularProgress, Fade, FormControl, FormControlLabel, FormHelperText, Grid, Input, InputLabel, LinearProgress, OutlinedInput, Slide, Switch, TextField, ToggleButton, Tooltip, Typography } from '@mui/material'
// Material Icons
import {
  BusinessCenter as BusinessCenterIcon,
  Email as EmailIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
} from '@mui/icons-material';



import CustomAvatar from '@components/CustomAvatar'
import SearchBar from '@components/SearchBar'
import UserService from '@services/user.service'
import TableData from '@components/TableData'
import TestsContext, { TestsProvider } from './TestsContext'
import CustomDataTable from '../../components/CustomDataTable';
import UserFormTest from './UserFormTest';
import LayoutTest from './LayoutTest';
import TextFieldMasked from './TextFieldMasked';
import ContractDetails from '../Sellers/components/ContractDetails';
import DialogTest from './DialogTest';
import MenuOptions from './MenuOptions';
import SnackbarTest from './SnackBarTest';
import CustomSnackbar from '../../components/CustomSnackbar';
import SetSectionsCubbies from './SetSectionsCubbies';
import AssignCubbiesTests from './AssignCubbiesTests';
import AssignNewReceiptDialog from '../Receipts/components/AssignNewReceiptDialog';
import { ReceiptsPageProvider } from '../Receipts/context/ReceiptsContext';
import { getUserByName } from '../../services/user.service';

const Tests = () => {
  return (
    <TestsProvider>
      <TestsWithContext />
    </TestsProvider>
  )
}

const TestsWithContext = () => {

  const { handleSearch, users } = useContext(TestsContext);
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const columns = [
    { id: 'userName', label: 'Nombre de usuario' },
    { id: 'email', label: 'Correo', style: { display: { xs: 'none', sm: 'revert' } } },
    { id: 'status', label: 'Estado', style: { minWidth: 60, textAlign: 'center' } },
  ]

  const rows = users.map(user => {
    return {
      userName: <Grid container columnSpacing={3} wrap="nowrap">

        <Grid item><CustomAvatar name={user.usuario_detalle.nombre} /></Grid>

        <Grid container item direction="column" justifyContent="center" rowSpacing={0.5}>
          <Grid item >
            <Typography variant="subtitle1">{`${user.usuario_detalle.nombre} ${user.usuario_detalle.apellidos}`}</Typography>
          </Grid>
          <Grid container item wrap="nowrap">
            <BusinessCenterIcon fontSize="small" />
            <Typography variant="body2" ml={0.5} >{"Administrador"}</Typography>
          </Grid>
        </Grid>

      </Grid>,
      email: <Grid container >
        <EmailIcon fontSize="small" />
        <Typography variant="body2" ml={0.5} >{user.email}</Typography>
      </Grid>,
      status: user.usuario_detalle.estatus == 'A' ? <CheckCircleOutlinedIcon color="success" /> : <RemoveCircleOutlineOutlinedIcon color="error" />
    }
  });

  const containerRef = React.useRef(null);
  const scale = 0.7;
  const scaleDimensions = {
    length: 1000 * scale,
    height: 200 * scale,
    width: 200 * scale
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tooltipStyle = {
    sx: {
      "& .MuiTooltip-tooltip": {
        bgcolor: 'common.white',
        border: 1,
        borderRadius: 2,
        color: 'grey.600',
        fontSize: 20
      }
    }
  }

  return (
    <div className="flex justify-center items-center h-screen">
      {/* <Button variant="contained" onClick={ ()=> getUserByName() } className="my-10">Obtener usuarios</Button> */}

      {/* <SearchBar id="searchbar-nombre" onSearch={handleSearch} />
      <TableData columns={columns} rows={rows}/> */}

      {/* <CustomDataTable /> */}
      {/* <UserFormTest/> */}
      {/* <LayoutTest /> */}
      {/* <TextFieldMasked /> */}
      {/* <ContractDetails open={true} onClose={() => {  }} contract={{}} /> */}

      {/* <MenuOptions /> */}
      {/* <SnackbarTest /> */}
      {/* <CustomSnackbar message="Hola we jaja" /> */}
      {/* <SetSectionsCubbies /> */}


      {/* <AssignCubbiesTests /> */}

      {/* <Tooltip PopperProps={tooltipStyle} title={
        <Typography variant='subtitle2'>Disponibles</Typography>
      }>

        <Box sx={{ width: 400, height: 500, bgcolor: 'grey.200', boxShadow: 5, borderRadius: 3}}>

        </Box>
      </Tooltip> */}

      {/* <Button variant="outlined">Botón de prueba</Button> */}
      {/* <ReceiptsPageProvider>
        <AssignNewReceiptDialog open={true} onClose={() => { }} />
      </ReceiptsPageProvider> */}

      <Button
        color='success'
      >Botón de prueba</Button>

    </div>
  )
}

export default Tests;
import { TextField } from '@mui/material'
import React from 'react'

const FormikField = ({ id, label, type = 'text', formikInstance, ...rest }) => {
  return (
    <TextField
      id={id}
      label={label}
      type={type}
      fullWidth
      helperText={formikInstance.touched[id] && formikInstance.errors[id]}
      error={formikInstance.touched[id] && Boolean(formikInstance.errors[id])}
      onChange={formikInstance.handleChange}
      onBlur={formikInstance.handleBlur}
      value={formikInstance.values[id]}

      {...rest}
    />
  )
}

export default FormikField
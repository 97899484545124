import { createContext, useState } from "react";

const LoginContext = createContext();

export const LoginProvider = ({children}) => {

  const [loginForm, setLoginForm] = useState(true);
  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleLoginForm = () => setLoginForm(!loginForm);

  return (
    <LoginContext.Provider value={{
      loginForm, handleToggleLoginForm,
      open, setOpen,
      handleClose
    }}>
      {children}
    </LoginContext.Provider>
  )
}

export default LoginContext
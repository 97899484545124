// React and Hooks
import { createContext, useEffect, useState } from "react";

// Services
import { getUserByName, getUsers, getUsersRoles } from '@services/user.service';

// Utils
import { getUsersColumns, getUsersRows } from "../utils/table.utils";

const UsersPageContext = createContext();

export const UsersPageProvider = ({children}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showUserFormArea, setShowUserFormArea] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});


  const handleSearch = async (user = '') => {
    setIsLoading(true);

    let usersResp;

    try {
      
      const rolesResp = await getUsersRoles();

      if(user == '') {
        usersResp = await getUsers();
        usersResp = usersResp.data;
      } else {
        usersResp = await getUserByName(user);

        //Lo convierto a array de objetos para que tenga el mismo trato
        usersResp = [ usersResp.data ]; 
      }

      
          
      // Añadimos a cada usuario la descripción del rol además del id rol que ya tiene
      usersResp = usersResp.map( userResp => {

        let role = rolesResp.data.find( (rolResp) => rolResp.id_rol === userResp.id_rol );
        
        return {
          ...userResp,
          rol_descripcion: role.descripcion
        }
      });
    
      setUsers(usersResp);

    } catch (error) {
      setUsers([]);
    }

    setIsLoading(false);
    
  }

  const handleSelectedRow = (idRowUser) => {
    const selectedUserRow = users.filter( user => user.usuario === idRowUser )[0];
    setSelectedUser(selectedUserRow);
    if(!showUserFormArea){
      setShowUserFormArea(true);
    }
    setShowUserForm(false);
    setTimeout(() => {
      setShowUserForm(true);
    }, 100);
  }

  const handleClickNewUser = () => {
    if(!showUserFormArea){
      setShowUserFormArea(true);
    }
    
    setShowUserForm(false);
    setTimeout(() => {
      setShowUserForm(true);
    }, 100);
  }

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <UsersPageContext.Provider value={{
      isLoading,
      users,
      handleSearch,
      getUsersColumns,
      getUsersRows,
      handleSelectedRow,
      showUserForm, setShowUserForm,
      showUserFormArea, setShowUserFormArea,
      selectedUser, setSelectedUser,
      handleClickNewUser
    }}>
      {children}
    </UsersPageContext.Provider>
  )
}

export default UsersPageContext;
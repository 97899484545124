// React and Hooks
import React, { useContext } from 'react';

// Material
import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

// Components
import Layout from "@components/Layout";
import ProgressIndicator from '@components/ProgressIndicator';
import SearchBar from '@components/SearchBar';
import TableData from '@components/TableData';
import AddButton from '@components/AddButton';
import UserForm from './components/UserForm';


// Providers / Context
import UsersPageContext, { UsersPageProvider } from './context/UserContext';

const UsersPage = () => {
  return (
    <UsersPageProvider>
      <UsersPageWithContext />
    </UsersPageProvider>
  );
}

const UsersPageWithContext = () => {

  const {
    isLoading,
    users,
    handleSearch,
    getUsersColumns,
    getUsersRows,
    handleSelectedRow,
    showUserForm, setShowUserForm,
    showUserFormArea, setShowUserFormArea,
    selectedUser, setSelectedUser,
    handleClickNewUser
  } = useContext(UsersPageContext)

  const stylesUsers = {
    display: {
      xxs: showUserFormArea ? 'none' : 'initial',
      lg: 'inline-block'
    },
  }

  const handleShowUserForm = (show) => {
    setSelectedUser({});
    setShowUserFormArea(show);
    setShowUserForm(show);
  }

  return (
    <>
      <Layout>
        <Grid container wrap="nowrap" sx={{ px: { xxs: 2, lg: 4 }, overflowY: 'hidden' }}>
          {/* Contenido de Usuarios */}
          <Grid container item rowSpacing={3} xxs={12} lg={showUserFormArea ? 5 : 12} sx={stylesUsers} >

            <Grid container item xxs={12} >
              <Grid item>
                <Typography variant="h4" color={grey[900]} sx={{ mb: { xxs: 1, lg: 3 } }}>Usuarios</Typography>
              </Grid>

              <Grid container item columnSpacing={2} justifyContent="space-between" wrap="nowrap">
                <Grid item xxs={showUserFormArea ? 12 : 10} lg={showUserFormArea ? 12 : 9}>
                  <SearchBar id="user-searchbar" onSearch={handleSearch} />
                </Grid>

                <Grid item sx={{ display: showUserFormArea ? 'none' : 'initial' }}>
                  <AddButton onClick={handleClickNewUser}>Nuevo usuario</AddButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xxs={12}>
              <TableData columns={getUsersColumns(showUserFormArea)} rows={getUsersRows(users)} onSelectedRow={handleSelectedRow} />
            </Grid>

          </Grid>

          {/* Formulario */}
          {
            showUserFormArea && <Grid item xxs={12} lg={showUserFormArea ? 7 : 12} sx={{ maxHeight: '89%', display: showUserFormArea ? 'initial' : 'none' }}>
              {
                showUserForm && <UserForm user={selectedUser} onShow={handleShowUserForm} onSubmit={handleSearch} />
              }
            </Grid>
          }

        </Grid>
      </Layout>

      <ProgressIndicator open={isLoading} />
    </>
  );
}

export default UsersPage;